import React, { useEffect, useState } from 'react';
import { Input, Select, Form, Spin } from "antd";
import { AddUpdateEmailTemplateApi, GetEmailTemplateByIdApi } from '../../../api/request/emailTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import Copy from '../../../components/copy/Copy';
import HTMLEditor from '../../../components/global/htmlEditor/HTMLEditor';
import toast from 'react-hot-toast';
import Support from '../../../assets/support/Support';
import LoadableButton from '../../../components/buttons/LoadableButton';
import ModalComponent from '../../../components/global/modal/ModalComponent';

const { TextArea } = Input;

export default function EditEmailTemplate() {

    const { id } = useParams();
    const navigate = useNavigate();
    const [templateForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditLoading, setIsEditLoading] = useState(false);
    const [template, setTemplate] = useState(null);
    const [templateInitialValues, setTemplateInitialValues] = useState({ EmailTemplateId: null, Subject: '', Body: '' });
    const [isOpenVariableDetailsModal, setIsOpenVariableDetailsModal] = useState(false);
    const [exportHtml, setExportHtml] = useState(null);
    const [changeExport, setChangeExport] = useState(5);
    const [value, setValue] = useState({});

    const getEmailTemplate = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetEmailTemplateByIdApi(id);
            if (data?.success) {
                setTemplate(data?.data);
                templateForm.setFieldValue('EmailTemplateId', data?.data?.templateName);
                templateForm.setFieldValue('Subject', data?.data?.subject);
                templateForm.setFieldValue('Body', data?.data?.body);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getEmailTemplate();
    }, []);

    const handlehtml = (value) => {
        setIsEditLoading(true);
        setChangeExport(changeExport + 5)
        setValue(value)
    }

    const handleSubmit = async (values) => {
        
        try {
            let params = {
                Id: template?.emailDetailsTemplateId,
                EmailTemplateId: template?.emailTemplateId,
                Subject: values?.Subject,
                Body: values?.Body,
                // ...values
            }
            let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
            const { data } = await AddUpdateEmailTemplateApi(params, apiHeader);
            if (data?.success) {
                toast.success(data?.message);
                templateForm.resetFields();
                setExportHtml(null);
                setValue({});
                navigate('/email_template');
            }
            setIsEditLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsEditLoading(false);
        }
    }

    // useEffect(() => {
    //     if (exportHtml) {
    //         handleSubmit();
    //     }
    // }, [exportHtml])

    return (
        <div className='border-2 border-light rounded-xl p-4'>
            {
                isLoading ? (
                    <div className="flex justify-center flex-col my-[12%]">
                        <Spin size="large" />
                        <p className="primary text-center mt-2">Loading...</p>
                    </div>
                ) : (
                    <Form className='w-full' autoComplete="off" form={templateForm} initialValues={templateInitialValues} onFinish={handleSubmit}>
                        <div className="grid sm:grid-cols-2 gap-5 ">
                            <div className='ant-select-selector-white'>
                                <label className='text-base text-[#2F2B3DCC] font-medium'>Email Template Type</label>
                                <Form.Item name="EmailTemplateId" rules={[{ required: true, message: 'Please select email template type.' }]}>
                                    <Select placeholder='Select email template type' className='w-full mt-1' disabled options={[]} />
                                </Form.Item>
                            </div>
                            <div className='input-white'>
                                <label className='text-base text-[#2F2B3DCC] font-medium'>Email Subject</label>
                                <Form.Item name="Subject" rules={[{ required: true, message: 'Please enter email subject.' }]}>
                                    <Input placeholder="Email subject" className="mt-1 ps-4 py-2" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='input-white w-full mt-4'>
                            <label className='text-base text-[#2F2B3DCC] font-medium mb-2'>Email Body</label>
                            <Form.Item name="Body" rules={[{ required: true, message: 'Please enter email Body.' }]}>
                            <TextArea placeholder="Email Body" className="mt-1 ps-4 py-2"  rows={6} />
                            </Form.Item>
                            
                            {/* <HTMLEditor data={templateForm.getFieldValue('Body')} setExportHtml={setExportHtml} changeExport={changeExport} /> */}
                        </div>
                        <div className="flex items-center gap-2 mt-2">
                            <p className="primary font-semibold">Variable Details</p>
                            <div className="cursor-pointer" onClick={() => setIsOpenVariableDetailsModal(true)}><Support /></div>
                        </div>
                        <div className='flex justify-end items-center gap-5 mt-6'>
                            <LoadableButton
                                className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500'
                                disabled={isEditLoading}
                                type="Submit"
                                lable='Submit'
                                isLoading={isEditLoading}
                                loadingLable='Adding template...'
                            />
                        </div>
                    </Form>
                )
            }

            {/* --- Variable details modal --- */}
            <ModalComponent isOpen={isOpenVariableDetailsModal} setIsOpen={setIsOpenVariableDetailsModal} title='Variable Details' width={800}>
                <div className='p-5'>
                    {
                        template?.emailVariablesList?.length > 0 ? (
                            <div className='grid md:grid-cols-2 gap-5 '>
                                {
                                    template?.emailVariablesList?.map((variable, index) => (
                                        <div key={index} className="flex items-center gap-1">
                                            <p className='text-base primary font-semibold'>{variable?.emailVariableName}</p>
                                            <Copy text={`{{${variable?.emailVariableName}}}`} />
                                        </div>
                                    ))
                                }
                            </div>
                        ) : (
                            <p className="text-gray-500 text-lg">Variable is not required for this email template.</p>
                        )
                    }
                </div>
            </ModalComponent>
        </div>
    )
}
