import React, { useEffect, useState } from 'react';
import IbPending from './components/IbPending';
import IbAccepted from './components/IbAccepted';
import IbReject from './components/IbReject';
import { useSearchParams } from "react-router-dom";
import { Input } from 'antd';
import Segmented from '../../components/segmented/Segmented.jsx';
import { GetAllibApi } from "../../api/request/ibapproved.js";
import ExportHandler from '../../components/global/export/ExportHandler.jsx';

export default function IbApproved() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [value, setValue] = useState('Pending');
    const [search, setSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const [isExportLoading, setIsExportLoading] = useState(false);

    const exportHandler = async () => {
        try {
            let params = {
                ApplyPagination: false,
                SearchText: searchText,
                FilterText:
                    value === "Pending"
                        ? "Pending"
                        : value === "Approved"
                            ? "Approved"
                            : value === "Declined"
                                ? "Rejected"
                                : value,
                SortBy: 'actionDate'
            };
            const { data } = await GetAllibApi(new URLSearchParams(params).toString());
            if (data?.success) {
                return { data: data?.data, removableColumns: ['userId'], fileName: 'IBs' };
            } else {
                return { data: [], removableColumns: [] };
            }
        } catch (error) {
            return { data: [], removableColumns: [] };
        }
    };

    useEffect(() => {
        setSearchParams(`filter=${value?.toString()}`);
    }, [value]);

    useEffect(() => {
        if (searchParams?.get("filter")?.trim()) {
            setValue(searchParams?.get("filter")?.trim());
        }
    }, []);

    return (
        <>
            <div className="flex justify-between flex-wrap gap-5">
                <Segmented options={['Pending', 'Approved', 'Declined']} value={value} setValue={setValue} />
                <div className="flex items-center flex-wrap gap-3">
                    <Input
                        size="large"
                        style={{ width: 220 }}
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onPressEnter={() => setSearchText(search)}
                        prefix={
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                                <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                            </svg>
                        }
                        suffix={search &&
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(''); setSearch('') }} viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                            </svg>
                        }
                    />
                    <div >
                        <ExportHandler exportHandler={exportHandler} />
                    </div>
                </div>
            </div>
            {value === "Pending" ? <IbPending dates={searchText} /> : ''}
            {value === "Approved" ? <IbAccepted dates={searchText} /> : ''}
            {value === "Declined" ? <IbReject dates={searchText} /> : ''}
            {/* {value === "IB Commission" ? <IbCommission dates={searchText} /> : ''} */}
        </>
    )
}
