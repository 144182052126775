import React, { useState, useEffect, useRef } from "react";
import { Form, Select, Input, Upload, Image, Switch } from "antd";
import TableWithSkeleton from "../../components/global/table/TableWithSkeleton.jsx";
import Pagination from "../../components/global/pagination/Pagination.jsx";
import ModalComponent from "../../components/global/modal/ModalComponent.jsx";
import LoadableButton from "../../components/buttons/LoadableButton.jsx";
import { AddTutorialApi, GetAllTutorialApi, GetTutorialTypeApi, UpdateTutorialStatusApi, DeleteTutorialApi } from "../../api/request/tutorial.js";
import toast from "react-hot-toast";
import columns from "../../columns/tutorial.js";
import moment from "moment/moment.js";
import Segmented from "../../components/segmented/Segmented.jsx";
import Eye from "../../assets/Eye.jsx";
import ConfirmationModal from "../../components/global/modal/ConfirmationModal.jsx";
import { useSearchParams } from "react-router-dom";
import Edit from "../../assets/Edit.jsx";
import AddTutorial from "./component/AddTutorial.jsx"
const { TextArea } = Input;

export default function Tutorial() {

  const [form] = Form.useForm();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isAddTutorialLoading, setIsAddTutorialLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tutorialType, setTutorialType] = useState([]);
  console.log(tutorialType,"tutorialType");
  
  const [value, setValue] = useState("YouTube URL");
  const [initialValues, setInitialValues] = useState({ Title: "", YoutubeUrl: "", Description: "", TutorialTypeId: null, UploadVideo: [] });
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
  const [isStatusConfirmLoading, setIsStatusConfirmLoading] = useState(false);
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [isDeleteTutorialLoading, setIsDeleteTutorialLoading] = useState(false);
  const [deleteTutorialModal, setDeleteTutorialModal] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [valuenew, setValuenew] = useState("Active");
  const [searchParams, setSearchParams] = useSearchParams();
  const [TutorialId, setTutorialId] = useState();
  const [IsOpenAddTutorialsModal, setIsOpenAddTutorialsModal] = useState(false);
  const videoRef = useRef(null);

  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    form.setFieldsValue({ UploadVideo: newFileList });
    if (newFileList?.length == 0) {
      form.setFieldsValue({ UploadVideo: [] });
    }
  }

  const UploadButton = (
    <button type="button">
      <div className="flex justify-center"><img src="assets/icons/Upload_Document_icon.svg" alt="icon" /></div>
      Upload
    </button>
  );

  const openEditHandler = (tutorial) => {
    getTutorialType()
    setTutorialId(tutorial?.id);

    setInitialValues({
      Title: tutorial?.title,
      Description: tutorial?.description,
      TutorialTypeId: tutorial?.tutorialType,
    })
    form.setFieldsValue({
      Title: tutorial?.title,
      Description: tutorial?.description,
      TutorialTypeId: tutorial?.tutorialType,
    });

    setIsOpenModal(true);
  };


  const getTutorialType = async () => {
    try {
      const { data } = await GetTutorialTypeApi();
      if (data?.success) {
        setTutorialType(
          data?.data?.map((item) => ({
            ...item,
            label: item?.name,
          }))
        );
      }
    } catch (error) { }
  };

  const statusHandler = async (tutorial) => {
    setSelectedTutorial(tutorial);
    setIsStatusConfirmOpen(true);
  }

  const getAllTutorials = async () => {
    try {
      setIsLoading(true);
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        SearchText: searchText,
        FilterText: valuenew
      };
      const { data } = await GetAllTutorialApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              title: (item?.title?.length > 20 ? (item?.title).slice(0, 20) + '...' : item?.title),
              description: (item?.description?.length > 20 ? (item?.description).slice(0, 20) + '...' : item?.description),
              createdDate: (item?.createdDate ? moment(item?.createdDate).format("DD-MM-YYYY, hh:mm A") : null),
              status: (<Switch onChange={() => statusHandler(item)} checkedChildren="On" unCheckedChildren="Off" checked={item?.status == 'Active'} />),
              actions: (<div className="flex items-center gap-2">
                <button type="button" onClick={() => { setSelectedTutorial(item); setIsOpenDetailsModal(true) }}><Eye /></button>
                {/* <button onClick={e => openEditHandler(item)} className="me-3"><Edit /></button> */}
                <button onClick={() => { setSelectedTutorial(item); setDeleteTutorialModal(true) }}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D91819" className="w-6 h-6 cursor-pointer hover:stroke-[#aa0001] duration-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                  </svg>
                </button>
              </div>
              ),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setSearchParams(`filter=${valuenew?.toString()}`);
  }, [valuenew]);

  useEffect(() => {
    if (searchParams?.get("filter")?.trim()) {
      setValuenew(searchParams?.get("filter")?.trim());
    }
  }, []);

  useEffect(() => {
    getAllTutorials();
  }, [page, pageSize,valuenew,searchText]);


  useEffect(() => {
    setPage(1);
  }, [searchText]);

  const handleSubmit = async (values) => {
    const extractOriginFileObjs = (fileArray) => {
      return fileArray.map(file => file.originFileObj);
    };
    try {
      setIsAddTutorialLoading(true);
      let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
      let formData = new FormData();
      formData.append("id", TutorialId);
      formData.append("Title", values?.Title);
      formData.append("TutorialTypeId", values?.TutorialTypeId);
      formData.append("Description", values?.Description);
      formData.append("IsActive", true);
      if (value == 'YouTube URL') {
        formData.append("YoutubeUrl", values?.YoutubeUrl);
        formData.append("IsYoutube", true);
      } else {
        formData.append("IsYoutube", false);
        extractOriginFileObjs(values?.UploadVideo)?.map((file) => {
          formData.append("UploadVideo", file);
        })
      };
      const { data } = await AddTutorialApi(formData, apiHeader);
      if (data?.success) {
        getAllTutorials();
        toast.success(data?.message);
        setIsOpenModal(false);
      }
      setIsAddTutorialLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsAddTutorialLoading(false);
    }
  };


  useEffect(() => {
    form.resetFields();
    setFileList([]);
  }, [isOpenModal]);

  const statusChangeConfirm = async () => {
    try {
      setIsStatusConfirmLoading(true);
      let params = {
        id: selectedTutorial?.id,
        isActive: selectedTutorial?.status == 'Active' ? false : true
      }

      const { data } = await UpdateTutorialStatusApi(params);
      if (data?.success) {
        getAllTutorials();
        toast.success(data?.message);
      }

      setIsStatusConfirmLoading(false);
      setIsStatusConfirmOpen(false);
    } catch (error) {
      setIsStatusConfirmLoading(false);
    }
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const validateFileSize = (_, fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      const isLt10M = file.size / 1024 / 1024 < 10; // Size limit 2MB
      if (!isLt10M) {
        return Promise.reject(new Error('Video must be smaller than 10MB!'));
      }
    }
    return Promise.resolve();
  };

  const deleteTutorialHandler = async () => {
    try {
      setIsDeleteTutorialLoading(true);
      const { data } = await DeleteTutorialApi(selectedTutorial?.id);
      if (data?.success) {
        toast.success(data?.message);
        setDeleteTutorialModal(false);
        getAllTutorials();
        setPage(1);
      }
      setIsDeleteTutorialLoading(false);
    } catch (err) {
      setIsDeleteTutorialLoading(false);
    }
  }

  useEffect(() => {
    if (!isOpenDetailsModal && videoRef.current) {
      videoRef.current.pause();
    }
  }, [isOpenDetailsModal]);

  useEffect(() => {
    if (!isOpenModal) {
        form.resetFields();
        setInitialValues({ Title: "", YoutubeUrl: "", Description: "", TutorialTypeId: null, UploadVideo: [] })
    }
}, [isOpenModal])

  return (
    <div className="max-w-full">
      <div className="flex justify-between flex-wrap gap-5">
        <Segmented
          options={["Active", "Deactive"]}
          value={valuenew}
          setValue={setValuenew}
        />

        <div className="flex items-center flex-wrap gap-4">
          <Input
            size="large"
            style={{ width: 220 }}
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onPressEnter={() => setSearchText(search)}
            prefix={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-5 h-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            suffix={
              search && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    setSearchText("");
                    setSearch("");
                  }}
                  viewBox="0 0 24 24"
                  fill="#bebebe"
                  className="size-5 cursor-pointer duration-300 hover:fill-[#969595]"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                    clip-rule="evenodd"
                  />
                </svg>
              )
            }
          />
          <div className="flex items-center gap-4">
            <button
              className="flex items-center bg-primary text-sm text-white font-semibold px-4 py-2 rounded-lg themeHover  duration-500"
              onClick={() => { getTutorialType(); setIsOpenAddTutorialsModal(true) }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                  clip-rule="evenodd"
                />
              </svg>
              Add Tutorial
            </button>
          </div>
        </div>

        <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
          <div className="px-4 lg:px-6">
            <p className="text-[#2F2B3D] text-xl font-semibold">All Tutorials</p>
          </div>
          <div className="my-6">
            <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
            <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
          </div>
        </div>
      </div>

      {/* ---- Add tutorial modal ---- */}
      <AddTutorial isOpentutorial={IsOpenAddTutorialsModal} setIsOpentutorial={setIsOpenAddTutorialsModal} getTutorials={getAllTutorials} />
      {/* ---- Edit tutorial modal ---- */}
      <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} title="Edit Tutorial" width={800}>
        <Segmented options={['YouTube URL', 'Upload Video']} value={value} setValue={setValue} />
        <Form className="w-full mt-4" autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
          <div className="grid sm:grid-cols-2 gap-5">
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Title</label>
              <Form.Item name="Title" rules={[{ required: true, message: "Please enter a title." }]}>
                <Input placeholder="Title" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Tutorial Type</label>
              <Form.Item name="TutorialTypeId" rules={[{ required: true, message: "Please select a tutorial type." }]}>
                <Select placeholder="Select a role" className="w-full mt-1" options={tutorialType} />
              </Form.Item>
            </div>
            <div className="col-span-2 input-white">
              <label className='text-base text-[#2F2B3DCC] font-medium'>Description</label>
              <Form.Item name="Description" rules={[{ required: true, message: 'Please enter tutorial description.' }]}>
                <TextArea className="mt-1 py-2" placeholder="Description..." rows={3} value={initialValues.BannerCaption} />
              </Form.Item>
            </div>
            {value == 'YouTube URL' &&
              <div className="col-span-2 input-white">
                <label className="text-base text-[#2F2B3DCC] font-medium">Youtube Url</label>
                <Form.Item name="YoutubeUrl" rules={[{ required: value == 'YouTube URL', message: "Please enter a youtube url." }]}>
                  <Input placeholder="Youtube url" className="mt-1 w-full py-2" />
                </Form.Item>
              </div>
            }
            {value == 'Upload Video' &&
              <div>
                <label className='text-base text-[#2F2B3DCC] font-medium'>Upload Video</label>
                <Form.Item
                  name="UploadVideo"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[{ required: value == 'Upload Video', message: 'Please upload a video.' }, { validator: validateFileSize }]}
                >
                  <Upload
                    className="mt-1"
                    beforeUpload={() => false}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {fileList.length >= 1 ? null : UploadButton}
                  </Upload>
                </Form.Item>
                {previewImage && (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                  />
                )}
              </div>
            }
          </div>
          <div className="flex justify-end mt-8">
            <LoadableButton
              className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
              disabled={isAddTutorialLoading}
              type="Submit"
              lable="Submit"
              isLoading={isAddTutorialLoading}
              loadingLable="Adding tutorial..."
            />
          </div>
        </Form>
      </ModalComponent>

      <ConfirmationModal
        isOpen={isStatusConfirmOpen}
        setIsOpen={setIsStatusConfirmOpen}
        message='Are you sure to change the tutorial status?'
        onConfirm={statusChangeConfirm}
        isLoading={isStatusConfirmLoading}
        loadingLabel='Changing status...'
      />

      {/* ---- Delete tutorial modal ---- */}
      <ConfirmationModal
        isOpen={deleteTutorialModal}
        setIsOpen={setDeleteTutorialModal}
        message='Are you sure you want to remove this tutorial?'
        onConfirm={deleteTutorialHandler}
        isLoading={isDeleteTutorialLoading}
        loadingLabel='Removing tutorial...'
      />

      {/* ---- tutorial details modal ---- */}
      <ModalComponent isOpen={isOpenDetailsModal} setIsOpen={setIsOpenDetailsModal} title='Tutorial' width={1000}>
        {selectedTutorial?.isYoutube ? (
          <iframe
            className='w-full'
            height="500"
            src={selectedTutorial.youtubeUrl.replace("watch?v=", "embed/")}
            title={selectedTutorial.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <video ref={videoRef} className='w-full' controls>
            <source src={selectedTutorial?.videoUrl} type="video/mp4" />
            Your browser does not support video.
          </video>
        )}
        <p className='text-lg font-semibold mt-4'>{selectedTutorial?.title}</p>
        <p className='text-[#7A7A7A] text-sm mt-2'>{selectedTutorial?.description}</p>
      </ModalComponent>
    </div>
  )
}