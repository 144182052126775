import React, { useEffect, useState } from 'react'

const Promotion = ({active}) => {
    const [primaryColor, setPrimaryColor] = useState(active ? "#fff" : (getComputedStyle(document.documentElement).getPropertyValue('--theme'))?.trim())
    useEffect(() => {
      setPrimaryColor(active ? "#fff" : (getComputedStyle(document.documentElement).getPropertyValue('--theme'))?.trim())
      
    }, [active])
    return (
        <svg width="24" height="24" viewBox="0 0 22 24" fill={primaryColor} xmlns="http://www.w3.org/2000/svg">
            <path d="M21.2333 6.36351H19.6367C20.1493 5.72805 20.456 4.97345 20.456 4.28045C20.456 3.86207 20.3079 3.44143 20.0297 3.06646C18.69 1.24464 17.2027 1.09531 16.191 1.29031C14.1094 1.69202 12.2489 3.97278 11.5463 5.76035C11.1349 4.63739 10.3574 3.38584 9.41484 2.35858C8.01913 0.837522 6.43496 0 4.9543 0C2.5542 0 0.601404 1.64395 0.601404 3.66482C0.601404 4.63814 1.0786 5.60261 1.81755 6.36351H0.766705C0.343177 6.36351 0 6.69988 0 7.11466V11.8966C0 12.3113 0.343177 12.6477 0.766705 12.6477H1.14837V23.2489C1.14837 23.6638 1.49155 24 1.91508 24H12.9202H14.4536H20.0849C20.5084 24 20.8516 23.6638 20.8516 23.2489V12.6479H21.2333C21.6568 12.6479 22 12.3115 22 11.8967V7.11466C22 6.69988 21.6568 6.36351 21.2333 6.36351ZM20.4666 11.1454H19.3182H14.4536V7.8658H20.4666V11.1454ZM9.07979 10.0202V7.8658H12.9204V10.0202H9.07979ZM16.4874 2.76435C17.3305 2.6024 18.0821 2.98789 18.7877 3.94739C18.8491 4.03047 18.9228 4.15576 18.9228 4.2806C18.9228 5.15959 17.8449 6.33707 16.7424 6.33707H15.9757V6.36366H14.4536H12.9512C13.469 4.97435 15 3.05143 16.4874 2.76435ZM2.13497 3.66497C2.13497 2.47246 3.39973 1.50243 4.95445 1.50243C7.10445 1.50243 9.4429 4.31065 10.1397 6.36366H9.07979H7.54637H4.92118C3.45094 6.20037 2.13497 4.78056 2.13497 3.66497ZM1.53356 7.8658H4.86168C4.97362 7.87541 5.08571 7.88172 5.19811 7.88172H5.96481V7.8658H7.54622V11.1454H2.68194H1.53356V7.8658ZM2.68194 12.6479H7.54637V22.4979H2.68194V12.6479ZM9.07979 22.4979V12.6479L9.07994 11.5226H12.9204V12.6479V22.4979H9.07979ZM19.3182 22.4979H14.4536V12.6479H19.3182V22.4979Z" />
        </svg>

    )
}

export default Promotion