import React, { useState, useEffect } from "react";
import { Input, Form, Switch, Spin } from "antd";
import columns from "../../../columns/Role.js";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal.jsx";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx"
import Edit from "../../../assets/Edit.jsx";
import Account from "../../../assets/Account.jsx";
import IbApproved from "../../../assets/IbApproved.jsx";
import Kyc from "../../../assets/Kyc.jsx";
import Wallet from "../../../assets/wallet/Wallet.jsx";
import Ticket from "../../../assets/support/Ticket.jsx";
import { AddRole, GetAllRole, GetRolesAndPermissionApi, updatePermissionByrole, DeleteRole } from "../../../api/request/settings/role.js";
import toast from "react-hot-toast";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
import moment from "moment";

export default function Role() {

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isRolesAndPermissionLoading, setIsRolesAndPermissionLoading] = useState(false);
  const [rolesAndPermissionData, setRolesAndPermissionData] = useState([]);
  const [isAddRoleLoading, setIsAddRoleLoading] = useState(false);
  const [isUpdateRoleLoading, setIsUpdateRoleLoading] = useState(false);
  const [isAddRole, setIsAddRole] = useState(false);
  const [isEditRole, setIsEditRole] = useState(false);
  const [initialValues, setInitialValues] = useState({ roleName: '' });
  const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
  const [isStatusConfirmLoading, setIsStatusConfirmLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [data, setData] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--theme'))?.trim();

  const statusHandler = async (role) => {
    setSelectedRole(role);
    setIsStatusConfirmOpen(true);
  }

  const getRole = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetAllRole();
      if (data?.success) {
        setData(data?.data?.map((item) => {
          return {
            ...item,
            createdDate: moment(item?.createdDate).format('DD-MM-YYYY, LT'),
            status: ((<Switch onChange={() => statusHandler(item)} checkedChildren="On" unCheckedChildren="Off" checked={item?.isActive} />)),
            actions: <div>
              <button onClick={e => openEditHandler(item)} className="me-3"><Edit /></button>
              <button onClick={e => { setRoleId(item?.id); setIsDelete(true) }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D91819" className="w-6 h-6 cursor-pointer hover:stroke-[#aa0001] duration-500">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>
              </button>
            </div>
          }
        }));
      }
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
    }
  }

  const statusChangeConfirm = async () => {
    try {
      setIsStatusConfirmLoading(true);

      const { data } = await AddRole({ id: selectedRole?.id, isActive: !selectedRole?.isActive });
      if (data?.success) {
        getRole();
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }

      setIsStatusConfirmLoading(false);
      setIsStatusConfirmOpen(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsStatusConfirmLoading(false);
    }
  }

  const handleSubmit = async (value) => {
    try {
      setIsAddRoleLoading(true);
      const { data } = await AddRole({ name: value?.roleName });
      if (data?.success) {
        toast.success(data?.message);
        form.resetFields();
        setIsAddRoleLoading(false);
        setIsAddRole(false);
        getRole();
      }
      else {
        toast.error(data?.message);
        setIsAddRoleLoading(false);
      }
    }
    catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsAddRoleLoading(false);
    }
  }

  const handleRoleChange = (modulePermissionId, haveModulePermission) => {
    setRolesAndPermissionData(prevModules =>
      prevModules.map(module =>
        module.modulePermissionId === modulePermissionId ? { ...module, haveModulePermission } : module
      )
    );
  };

  const handleDeleteRole = async () => {
    try {
      setIsDeleteLoading(true);
      const { data } = await DeleteRole(roleId);
      if (data?.success) {
        toast.error(data?.message);
        getRole();
        setIsDeleteLoading(false);
        setIsDelete(false);
      }
      else {
        toast.message(data?.message);
        setIsDeleteLoading(false);
        setIsDelete(false);
        getRole();
      }
    }
    catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsDeleteLoading(false);
      setIsDelete(false);
    }
  }

  useEffect(() => {
    getRole();
  }, []);

  useEffect(() => {
    if (!isAddRole) {
      form.resetFields();
    }
  });

  const openEditHandler = (role) => {
    getRolesAndPermissionById(role?.id);
    setSelectedRole(role);
    setIsEditRole(true);
  }

  const getRolesAndPermissionById = async (roleId) => {
    try {
      setIsRolesAndPermissionLoading(true);
      const { data } = await GetRolesAndPermissionApi(roleId);
      if (data?.success) {
        setRolesAndPermissionData(data?.data);
      }
      setIsRolesAndPermissionLoading(false);
    } catch (error) {
      setIsRolesAndPermissionLoading(false);
    }
  }

  const getIcons = (title) => {
    switch (title) {
      case 'MT5Account':
        return <Account />;
      case 'Ticket':
        return <Ticket />;
      case 'Payment':
        return <Wallet />;
      case 'KYC':
        return <Kyc />;
      case 'IB':
        return <IbApproved />;
    }
  }

  const updateRolesAndPermission = async () => {
    setIsUpdateRoleLoading(true)
    try {
      let transformedArray = rolesAndPermissionData.map(item => ({
        modulePermissionId: item.modulePermissionId,
        isActive: item.haveModulePermission
      }));
      const { data } = await updatePermissionByrole(transformedArray);
      if (data.success) {
        setIsUpdateRoleLoading(false)
        toast.success(data.message)
        setIsEditRole(false)
      }
      // need to call api and pass -->> transformedArray

    } catch (error) {

    }
  }

  return (
    <div>
      <div className="flex flex-col sm:flex-row sm:justify-end">
        <div className="flex">
          {/* <div className="mr-3">
            <Input
              size="large"
              style={{
                width: 200,
                height: 38,
              }}
              placeholder="Search"
              prefix={<SearchOutlined />}
            />
          </div> */}
          <div>
            <button
              className="export-btm flex bg-primary text-sm text-white font-medium uppercase px-9 py-2 rounded-lg themeHover  duration-400"
              onClick={e => setIsAddRole(true)}
              size="large"
            >
              <img
                src="assets/icons/add_icon.svg"
                alt="icon"
                className="mr-2 mt-1"
                width={15}
              />
              Add Role
            </button>
          </div>
        </div>
      </div>

      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">Roles</p>
        </div>
        <div className="mt-6 mb-6 ">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
        </div>

        <ConfirmationModal
          isOpen={isStatusConfirmOpen}
          setIsOpen={setIsStatusConfirmOpen}
          message='Are you sure to change the role status?'
          onConfirm={statusChangeConfirm}
          isLoading={isStatusConfirmLoading}
          loadingLabel='Changing status...'
        />

        {/* Add role */}
        <ModalComponent isOpen={isAddRole} setIsOpen={setIsAddRole} title={<div className="font-bold text-lg">Add Role</div>} onCancel={() => setIsAddRole(false)} width={400}>
          <Form className="w-full" autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
            <div className="mt-2  p-3">
              <label htmlFor="rolename" >Role Name:</label>
              <Form.Item
                name="roleName"
                rules={[
                  { required: true, message: "Please enter role name." },
                ]}
              >
                <Input placeholder="Enter role name" className="h-12" />
              </Form.Item>
            </div>
            <div className=" text-center mt-6">
              {/* <button type="submit" className="bg-primary text-base text-white font-medium uppercase px-9 py-2 rounded-lg themeHover  duration-400" >Save</button> */}

              <LoadableButton
                className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
                type="Submit"
                lable="Save"
                isLoading={isAddRoleLoading}
                disabled={isAddRoleLoading}
                loadingLable="Adding role..."
              />
            </div>
          </Form>
        </ModalComponent>

        {/* Edit role */}
        <ModalComponent isOpen={isEditRole} setIsOpen={setIsEditRole} title={<div className="font-bold text-lg">List of Permission</div>} onCancel={() => setIsEditRole(false)} width={650}>
          {
            isRolesAndPermissionLoading ? (
              <div className="flex justify-center flex-col my-[10%]">
                <Spin size="large" />
                <p className="primary text-center mt-2">Loading...</p>
              </div>
            ) : (
              <div>
                <div className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 mt-2 px-4">
                  {rolesAndPermissionData.map((item, index) => (
                    <div key={index} className="h-[100px] relative" onClick={() => handleRoleChange(item?.modulePermissionId, !item.haveModulePermission)} >
                      <div className={`border rounded p-4 h-full flex flex-col justify-center items-center cursor-pointer duration-300 ${item?.haveModulePermission ? " border-primary" : ""}`}>
                        {
                          item?.haveModulePermission &&
                          <div className="h-[10px] w-[10px] rounded-full absolute top-2 left-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="size-4" stroke={primaryColor} >
                              <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                            </svg>

                          </div>
                        }
                        <div>{getIcons(item?.moduleName)}</div>
                        <p className="text-center roles-title ">{item?.moduleName}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className=" text-center mt-6">
                  <LoadableButton
                    className="bg-primary text-base text-white font-medium uppercase px-9 py-2 rounded-lg themeHover duration-400"
                    disabled={isUpdateRoleLoading}
                    type="Submit"
                    lable="Save"
                    isLoading={isUpdateRoleLoading}
                    loadingLable="Updating role..."
                    onClick={updateRolesAndPermission}
                  />
                </div>
              </div>
            )
          }
        </ModalComponent>

        {/* ---- Delete role modal ---- */}
        <ConfirmationModal
          isOpen={isDelete}
          setIsOpen={setIsDelete}
          message='Are you sure you want to remove this role ?'
          onConfirm={handleDeleteRole}
          isLoading={isDeleteLoading}
          loadingLabel='Deleting Role...'
        />

      </div>
    </div>
  );
}