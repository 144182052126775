import React, { useEffect, useState } from 'react';
import { Avatar, Switch } from 'antd';
import { useSelector } from 'react-redux';
import { TurnOnOffMaintenanceApi, TurnOnOffMobileNoOfIBTreeApi, GetMaintenanceStatusApi, GetContactStatusApi, UpdateParentVisibilityApi } from '../../api/request/header/maintenance';
import toast from 'react-hot-toast';

export default function Profile({ isAdmin }) {

    const { userDetails } = useSelector(state => state.user);
    const [checked, setChecked] = useState(false);
    const [contactChecked, setContactChecked] = useState(false);
    const [parentChecked, setParentChecked] = useState(false);

    const handleChange = async (checked) => {
        try {
            const { data } = await TurnOnOffMaintenanceApi(new URLSearchParams({ isEnabled: checked }).toString());
            if (data?.success) {
                setChecked(checked);
                toast.success(data?.message);
            }
        } catch (error) {
            if (error?.response?.data?.error?.messag) {
                toast.error(error?.response?.data?.error?.message);
            }
        }
    };

    const handleContacts = async (checked) => {
        try {
            const { data } = await TurnOnOffMobileNoOfIBTreeApi({ onOffMobileNo: checked });
            if (data?.success) {
                setContactChecked(checked);
                toast.success(data?.message);
            }
        } catch (error) {
            if (error?.response?.data?.error?.messag) {
                toast.error(error?.response?.data?.error?.message);
            }
        }
    }

    const getMaintenanceStatus = async () => {
        try {
            const { data } = await GetMaintenanceStatusApi();
            if (data?.success) {
                setChecked(data?.data);
            }
        } catch (error) { }
    }

    const getContactStatus = async () => {
        try {
            const { data } = await GetContactStatusApi();
            if (data?.success) {
                setContactChecked(data?.data?.isVisible);
            }
        } catch (error) { }
    }

    const handleParentChange = async (checked) => {
        try {
            const { data } = await UpdateParentVisibilityApi(userDetails?._id, new URLSearchParams({ status: checked }).toString());
            if (data?.success) {
                setParentChecked(checked);
                toast.success(data?.message);
            }
        } catch (error) {
            if (error?.response?.data?.error?.messag) {
                toast.error(error?.response?.data?.error?.message);
            }
        }
    }

    useEffect(() => {
        getContactStatus();
        getMaintenanceStatus();
    }, []);

    return (
        <div>
            <div className='border rounded-lg flex justify-between items-start p-5'>
                <div className='flex items-center'>
                    <Avatar round size={60} src={userDetails?.profileImage} />
                    <div className='ml-4'>
                        <p className='font-medium text-lg'>{userDetails?.fullName}</p>
                        <p className='text-[#848484] text-lg mt-1'>{userDetails?.address}</p>
                    </div>
                </div>
            </div>

            <div className='border rounded-lg p-4 mt-4'>
                <p className='font-medium text-xl '>Personal Information</p>
                <div className='grid sm:grid-cols-2 grid-cols-1 mt-5 items-center gap-5'>
                    <div>
                        <p className='text-[#848484] font-normal text-base '>First Name</p>
                        <p className='text-[#848484] font-semibold text-base mt-1'>{userDetails?.firstName}</p>
                    </div>
                    <div className=''>
                        <p className='text-[#848484] font-normal text-base '>Lat Name</p>
                        <p className='text-[#848484] font-semibold text-base mt-1'>{userDetails?.lastName}</p>
                    </div>
                    <div>
                        <p className='text-[#848484] font-normal text-base '>Email Address</p>
                        <p className='text-[#848484] font-semibold text-base mt-1'>{userDetails?.email}</p>
                    </div>
                </div>
            </div>

            {isAdmin &&
                <div>
                    <div className='flex items-center border rounded-lg p-4 mt-4'>
                        <Switch
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            checked={checked}
                            onChange={handleChange}
                        />
                        <p className='text-[#848484] font-semibold text-base ms-2'>Maintenance Mode</p>
                    </div>

                    <div className='flex items-center border rounded-lg p-4 mt-4'>
                        <Switch
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            checked={contactChecked}
                            onChange={handleContacts}
                        />
                        <p className='text-[#848484] font-semibold text-base ms-2'>Show Contacts</p>
                    </div>

                    <div className='flex items-center border rounded-lg p-4 mt-4'>
                        <Switch checkedChildren="ON" unCheckedChildren="OFF" checked={parentChecked} onChange={handleParentChange} />
                        <p className='text-[#848484] font-semibold text-base ms-2'>Visible as Parent</p>
                    </div>
                </div>
            }
        </div>
    )
}