import React, { useState, useEffect } from "react";
import { Input, DatePicker } from "antd";
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import columns from "../../../columns/ibCommission/generateCommission/GenerateCommission.js";
import TableWithSkeleton from '../../../components/global/table/TableWithSkeleton.jsx';
import { GetGenerateCommissionApi } from '../../../api/request/ibCommission.js';
import moment from "moment";
import Support from '../../../assets/support/Support.jsx';
import { useNavigate } from "react-router-dom";
import ExportHandler from "../../../components/global/export/ExportHandler.jsx";
const { RangePicker } = DatePicker;

export default function GenerateCommission() {

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);

  const getCommission = async () => {
    try {
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        SearchText: searchText,
        FilterText: 'Credit',
        FromDate: dateRange?.[0]?.format('MM/DD/YYYY') ?? '',
        ToDate: dateRange?.[1]?.format('MM/DD/YYYY') ?? '',
      }
      setIsLoading(true);
      const { data } = await GetGenerateCommissionApi(new URLSearchParams(params).toString());
      setData(
        data?.data?.map((item) => {
          return {
            ...item,
            name: `${item?.firstName} ${item?.lastName}`,
            transactionDate: moment(item?.transactionDate).format('DD-MM-YYYY, hh:mm A'),
            commissionAmount: `$ ${item?.commissionAmount}`,
            actions: (<div className="cursor-pointer" onClick={() => navigate(`/ib_commission/${item?.id}`)}><Support /></div>),
          };
        })
      );
      setTotalRecord(data?.totalRecords);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const exportHandler = async () => {
    try {
      let params = { 
        ApplyPagination: false,
         SearchText: searchText,
         FilterText: 'Credit',
         FromDate: dateRange?.[0]?.format('MM/DD/YYYY') ?? '',
         ToDate: dateRange?.[1]?.format('MM/DD/YYYY') ?? '',
       };
      const { data } = await GetGenerateCommissionApi(new URLSearchParams(params).toString());
      if (data?.success) {
        return { data: data?.data, removableColumns: ['id'], fileName: 'Generate Commission List' };
      } else {
        return { data: [], removableColumns: [] };
      }
    } catch (error) {
      return { data: [], removableColumns: [] };
    }
  };

  useEffect(() => {
    getCommission();
  }, [page, pageSize,searchText, dateRange]);

  useEffect(() => {
    setPage(1);
}, [searchText]);

  return (
    <div>
      <div className="flex justify-end flex-wrap gap-5">
        <div className="flex items-center gap-3">
          <div>
            <div className='ant-date-range-selector-white'>
              <RangePicker className='border-primary  py-2' format='DD-MM-YYYY'
                onChange={(dates) => { setDateRange(dates) }} />
            </div>
          </div>
          <div>
            <Input
              size="large"
              style={{ width: 220 }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onPressEnter={() => setSearchText(search)}
              prefix={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                  <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                </svg>
              }
              suffix={search &&
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(''); setSearch('') }} viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                  <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                </svg>
              }
            />
          </div>
          <div>
            <ExportHandler exportHandler={exportHandler} />
          </div>
        </div>
      </div>

      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">IB Commission Credit</p>
        </div>
        <div className="my-6 w-auto">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
          <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div>
      </div>
    </div>
  )
}