import React, { useEffect, useState } from 'react'
import TableWithSkeleton from '../../../components/global/table/TableWithSkeleton'
import Pagination from '../../../components/global/pagination/Pagination';
import columns from '../../../columns/cashDeposit/depositRequest/columns';
import ModalComponent from '../../../components/global/modal/ModalComponent';
import Eye from '../../../assets/Eye';
import ConfirmationModal from '../../../components/global/modal/ConfirmationModal';
import LoadableButton from '../../../components/buttons/LoadableButton';
import { Form, Input, Image, Spin } from 'antd';
import { GetAllDepositRequestApi, AcceptRejectDepositRequestApi } from '../../../api/request/cashDeposit/depositRequest';
import moment from 'moment';
import Segmented from '../../../components/segmented/Segmented.jsx';
import toast from "react-hot-toast";

const { TextArea } = Input;

const DepositRequest = () => {
  const [form] = Form.useForm();
  const [isReqLoading, setIsReqLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [data, setData] = useState([]);
  const [viewModalData, setViewModalData] = useState([]);
  const [filterDeposit, setFilterDeposit] = useState('Pending');
  const [isOpenModalimg, setIsOpenModalimg] = useState(false);
  const [sorter, setSorter] = useState({ SortBy: 'requestDate', SortOrder: '' });

  const initialValues = {};

  const getAllDepositRequest = async () => {
    setIsLoading(true);
    try {
      let params = {
        // FilterText: filterDeposit
        FilterText: filterDeposit === "Pending" ? "pending" : filterDeposit === "Approved" ? "approved" : filterDeposit === "Declined" ? "rejected" : filterDeposit,
        ...sorter

      }
      const { data } = await GetAllDepositRequestApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(data?.data?.map((item) => {
          return {
            ...item,
            documentUrl: <div><Image src={item?.documentUrl[0]} alt='document-img' width={40} height={40} className='rounded-full object-cover' /></div>,
            requestDate: item?.requestDate ? moment(item?.requestDate).format('DD-MM-YYYY, hh:mm A') : '--',
            actions: <button onClick={() => { setIsView(true); setViewModalData(item); form.setFieldValue('amount', item?.requestedAmount); }}><Eye /></button>,
          }
        }))
        setTotalRecord(data?.totalCount);
      }
      setIsLoading(false);
    }
    catch (error) { }
  }
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
    } else {
      setSorter({ SortBy: 'requestDate', SortOrder: '' });
    }
  };
  const handleSubmit = async (values) => {

    try {
      setIsLoading(true);
      let params = {
        requestId: viewModalData?.requestId,
        amount: values?.amount,
        comment: values?.comment,
        status: 2,

      };
      const { data } = await AcceptRejectDepositRequestApi(params);
      if (data?.success) {
        form?.resetFields();
        setIsAccept(false)
        setIsView(false)
        getAllDepositRequest()
        toast.success(data?.message);
      }
      setIsLoading(true);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsLoading(false);
    }

  }

  const RequestHandlerRejected = async (value) => {
    if (!value) {
      if (!textAreaValue) {
        toast.error("Please enter a reason.");
        return;
      }
    }
    try {
      setIsReqLoading(true);
      let params = {
        requestId: viewModalData?.requestId,
        status: 3,
        comment: textAreaValue

      };
      const { data } = await AcceptRejectDepositRequestApi(params);
      if (data?.success) {
        setIsReject(false)
        setIsView(false)
        getAllDepositRequest()
        toast.success(data?.message);

      }
      setTextAreaValue("");
      setIsReqLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsReqLoading(false);
    }

  };

  useEffect(() => {
    getAllDepositRequest();
  }, []);



  const openInNewTab = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    getAllDepositRequest();
  }, [page, pageSize, filterDeposit, sorter]);

  return (
    <>
      <Segmented options={['Pending', 'Approved', 'Declined']} value={filterDeposit} setValue={setFilterDeposit} />
      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="flex justify-between gap-5 px-4 lg:px-6">
          <div>
            <p className="text-[#2F2B3D] text-xl font-semibold">Deposit Request</p>
          </div>
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} onChange={handleTableChange} />
          <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
        </div>
      </div>

      <ModalComponent isOpen={isView} setIsOpen={setIsView} title="Deposit detail" width={1300}>
        <div className='p-5'>
          <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10 '>
            <div>
              <p className='text-base font-normal '>First Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{viewModalData?.firstName}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Last Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{viewModalData?.lastName}</p>
            </div>
            <div>
              <p className='text-base font-normal'>Email</p>
              <p className='text-xl primary font-semibold  mt-2'>{viewModalData?.email}</p>
            </div>
            {/* <div>
              <p className='text-base font-normal '>Available Balance</p>
              <p className='text-xl primary font-semibold  mt-2'>$ 524</p>
            </div> */}

            <div>
              <p className='text-base font-normal '>Mobile No.</p>
              <p className='text-xl primary font-semibold  mt-2'>{viewModalData?.phoneNo}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Wallet Account No.</p>
              <p className='text-xl primary font-semibold  mt-2'>{viewModalData?.walletAccountNo}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Balance</p>
              <p className='text-xl primary font-semibold  mt-2'>{viewModalData?.balance}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Amount</p>
              <p className='text-xl primary font-semibold mt-2'>$ {viewModalData?.requestedAmount}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Comment</p>
              <p className='text-xl primary font-semibold mt-2'>{viewModalData?.comment}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Status</p>
              <p className='text-xl primary font-semibold mt-2'>{viewModalData?.status}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Transaction ID</p>
              <p className='text-xl primary font-semibold mt-2'>{viewModalData?.referenceId}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Request Date</p>
              <p className='text-xl primary font-semibold mt-2'>{viewModalData?.requestDate ? moment(viewModalData?.requestDate).format('DD-MM-YYYY, hh:mm A') : '--'} </p>
            </div>
            {viewModalData.status === "Approved" ? <div>
              <p className='text-base font-normal '>Accepted Amount</p>
              <p className='text-xl primary font-semibold mt-2'>{viewModalData?.acceptedAmount || "0"} </p>
            </div> : ""}
            <div>
              <p className="text-[#1E232CC7] text-lg">Document</p>
              <div className="flex items-center cursor-pointer" onClick={() => setIsOpenModalimg(true)}>
                <p className="primary text-lg font-semibold mt-2 underline mr-2">View Document</p>

              </div>
            </div>

          </div>

          {viewModalData.status === "Pending" ? <div className="flex justify-center items-center">
            <div className="mt-8">
              <button
                onClick={() => setIsAccept(true)}
                className="bg-[#2D9B6333] text-sm text-[#2D9B63] font-semibold px-4 py-2 rounded-lg uppercase hover:bg-[#2D9B63] hover:text-white duration-500">
                Accept
              </button>
              <button
                onClick={() => setIsReject(true)}
                className="bg-[#F8222333] text-sm text-[#F82223] font-semibold px-4 py-2 rounded-lg uppercase hover:bg-[#F82223] hover:text-white duration-500 ml-4" >
                Reject
              </button>
            </div>
          </div> : ""}
        </div>
      </ModalComponent>

      {/* ---- Accept-request ---- */}
      <ModalComponent isOpen={isAccept} setIsOpen={setIsAccept} title="Accept deposit request  " width={500}>
        <>
          <Form className='w-full' autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Amount</label>
              <Form.Item name="amount" rules={[{ required: true, message: 'Please enter amount.' }, { validator: (_, value) => Number.isInteger(Number(value)) ? Promise.resolve() : Promise.reject(new Error('Please enter a valid amount.')) }]}>
                <Input type='number' placeholder="amount" className="mt-1 w-full ps-4 py-2" />
              </Form.Item>
            </div>
            <div className='input-white w-full mt-4'>
              <label className='text-base text-[#2F2B3DCC] font-medium mb-2'>Comment</label>
              <Form.Item name="comment" rules={[{ required: true, message: 'Please enter your comment.' }]}>
                <TextArea placeholder="comment" className="mt-1 ps-4 py-2" rows={3} />
              </Form.Item>

            </div>

            <div className='flex justify-center items-center gap-5 mt-5'>
              <button onClick={() => setIsAccept(false)} disabled={isLoading} className='bg-[#D91819] text-sm text-white font-medium uppercase px-10 py-2 rounded-lg hover:bg-[#aa0001] duration-500'>No</button>
              <LoadableButton
                type="Submit"
                disabled={isLoading}
                className='bg-[#2D9B63] text-sm text-white font-medium uppercase px-10 py-2 rounded-lg hover:bg-[#096034] duration-500'
                lable='Yes'
                isLoading={isLoading}
                loadingLable='Accepting...'
              />
            </div>
          </Form>
        </>
      </ModalComponent>

      {/* ---- Reject-request ---- */}
      <ConfirmationModal
        isOpen={isReject}
        setIsOpen={setIsReject}
        message="Are you sure you want to reject this request?"
        onConfirm={() => RequestHandlerRejected(false)}
        isLoading={isReqLoading}
        loadingLabel="Rejecting request..."
        textArea={true}
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        placeholder="Reason of rejection..."
      />

      {/* View Document */}
      <ModalComponent isOpen={isOpenModalimg} setIsOpen={setIsOpenModalimg} title="View Document" width={1000}>
        {
          isReqLoading ? (
            <div className="flex justify-center flex-col my-[10%]">
              <Spin size="large" />
              <p className="primary text-center mt-2">Loading...</p>
            </div>
          ) : (
            <div>
              {viewModalData?.documentUrl && viewModalData.documentUrl.length > 0 ? (
                <div className='p-5'>
                  <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10'>
                    {viewModalData.documentUrl.map((url, index) => (
                      <div key={index}>
                        <div className="border border-gray-300 rounded-lg">
                          {url.endsWith('.pdf') ? (
                            <iframe
                              src={url}
                              title={`Deposit Document ${index + 1}`}
                              className="w-full h-64 rounded-lg cursor-pointer"
                              onClick={() => openInNewTab(url)}
                            />
                          ) : (
                            <img
                              src={url}
                              alt={`Deposit Document ${index + 1}`}
                              className="w-full h-auto rounded-lg cursor-pointer"
                              onClick={() => openInNewTab(url)}
                            />
                          )}
                        </div>

                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>No documents available</p>
              )}
            </div>

          )
        }
      </ModalComponent>

    </>
  )
}

export default DepositRequest