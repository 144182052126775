const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
    },
    {
        title: 'Description',
        dataIndex: 'content',
    },
    {
        title: 'Date',
        dataIndex: 'createdDate',
    },
    {
        title: "Actions",
        dataIndex:'actions'
    }
];

export default columns;