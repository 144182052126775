import React, { useState, useEffect, useRef } from "react";
import { Form, Select, Input, Upload, Image } from "antd";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
import { AddTutorialApi,GetTutorialTypeApi} from "../../../api/request/tutorial.js";
import toast from "react-hot-toast";
import Segmented from "../../../components/segmented/Segmented.jsx";
import { useSearchParams } from "react-router-dom";
const { TextArea } = Input;

export default function EditTutorial({isOpentutorial, setIsOpentutorial,getTutorials}) {

  const [form] = Form.useForm();
  const [isAddTutorialLoading, setIsAddTutorialLoading] = useState(false);
  const [tutorialType, setTutorialType] = useState([]);
  const [value, setValue] = useState("YouTube URL");
  const [initialValues, setInitialValues] = useState({ Title: "", YoutubeUrl: "", Description: "", TutorialTypeId: null, UploadVideo: [] });
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [valuenew, setValuenew] = useState("Active");
  const [searchParams, setSearchParams] = useSearchParams();
  const [TutorialId, setTutorialId] = useSearchParams();
  
  const videoRef = useRef(null);

  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    form.setFieldsValue({ UploadVideo: newFileList });
    if (newFileList?.length == 0) {
      form.setFieldsValue({ UploadVideo: [] });
    }
  }

  const UploadButton = (
    <button type="button">
      <div className="flex justify-center"><img src="assets/icons/Upload_Document_icon.svg" alt="icon" /></div>
      Upload
    </button>
  );
  useEffect(() => {
    getTutorialType();
  }, []);
  const getTutorialType = async () => {
    try {
      const { data } = await GetTutorialTypeApi();
      if (data?.success) {
        setTutorialType(
          data?.data?.map((item) => ({
            ...item,
            label: item?.name,
          }))
        );
      }
    } catch (error) { }
  };

  useEffect(() => {
    setSearchParams(`filter=${valuenew?.toString()}`);
  }, [valuenew]);

  useEffect(() => {
    if (searchParams?.get("filter")?.trim()) {
      setValuenew(searchParams?.get("filter")?.trim());
    }
  }, []);

  const handleSubmit = async (values) => {
    const extractOriginFileObjs = (fileArray) => {
      return fileArray.map(file => file.originFileObj);
    };
    try {
      setIsAddTutorialLoading(true);
      let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
      let formData = new FormData();
      formData.append("Title", values?.Title);
      formData.append("TutorialTypeId", values?.TutorialTypeId);
      formData.append("Description", values?.Description);
      formData.append("IsActive", true);
      if (value == 'YouTube URL') {
        formData.append("YoutubeUrl", values?.YoutubeUrl);
        formData.append("IsYoutube", true);
      } else {
        formData.append("IsYoutube", false);
        extractOriginFileObjs(values?.UploadVideo)?.map((file) => {
          formData.append("UploadVideo", file);
        })
      };
      const { data } = await AddTutorialApi(formData, apiHeader);
      if (data?.success) {
        getTutorials();
        toast.success(data?.message);
        setIsOpentutorial(false);
      }
      setIsAddTutorialLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsAddTutorialLoading(false);
    }
  };




  useEffect(() => {
    form.resetFields();
    setFileList([]);
  }, [setIsOpentutorial]);



  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const validateFileSize = (_, fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      const isLt10M = file.size / 1024 / 1024 < 10; // Size limit 2MB
      if (!isLt10M) {
        return Promise.reject(new Error('Video must be smaller than 10MB!'));
      }
    }
    return Promise.resolve();
  };


  useEffect(() => {
    if (!isOpenDetailsModal && videoRef.current) {
      videoRef.current.pause();
    }
  }, [isOpenDetailsModal]);

  useEffect(() => {
    if (isOpentutorial) {
        form.resetFields();
        setInitialValues({ Title: "", YoutubeUrl: "", Description: "", TutorialTypeId: null, UploadVideo: [] })
    }
}, [isOpentutorial])



  return (
    <div>

      {/* ---- Add tutorial modal ---- */}
      <ModalComponent isOpen={isOpentutorial} setIsOpen={setIsOpentutorial} title="Add Tutorial" width={800}>
        <Segmented options={['YouTube URL', 'Upload Video']} value={value} setValue={setValue} />
        <Form className="w-full mt-4" autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
          <div className="grid sm:grid-cols-2 gap-5">
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Title</label>
              <Form.Item name="Title" rules={[{ required: true, message: "Please enter a title." }]}>
                <Input placeholder="Title" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Tutorial Type</label>
              <Form.Item name="TutorialTypeId" rules={[{ required: true, message: "Please select a tutorial type." }]}>
                <Select placeholder="Select a role" className="w-full mt-1" options={tutorialType} />
              </Form.Item>
            </div>
            <div className="col-span-2 input-white">
              <label className='text-base text-[#2F2B3DCC] font-medium'>Description</label>
              <Form.Item name="Description" rules={[{ required: true, message: 'Please enter tutorial description.' }]}>
                <TextArea className="mt-1 py-2" placeholder="Description..." rows={3} value={initialValues.BannerCaption} />
              </Form.Item>
            </div>
            {value == 'YouTube URL' &&
              <div className="col-span-2 input-white">
                <label className="text-base text-[#2F2B3DCC] font-medium">Youtube Url</label>
                <Form.Item name="YoutubeUrl" rules={[{ required: value == 'YouTube URL', message: "Please enter a youtube url." }]}>
                  <Input placeholder="Youtube url" className="mt-1 w-full py-2" />
                </Form.Item>
              </div>
            }
            {value == 'Upload Video' &&
              <div>
                <label className='text-base text-[#2F2B3DCC] font-medium'>Upload Video</label>
                <Form.Item
                  name="UploadVideo"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[{ required: value == 'Upload Video', message: 'Please upload a video.' }, { validator: validateFileSize }]}
                >
                  <Upload
                    className="mt-1"
                    beforeUpload={() => false}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {fileList.length >= 1 ? null : UploadButton}
                  </Upload>
                </Form.Item>
                {previewImage && (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                  />
                )}
              </div>
            }
          </div>
          <div className="flex justify-end mt-8">
            <LoadableButton
              className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
              disabled={isAddTutorialLoading}
              type="Submit"
              lable="Submit"
              isLoading={isAddTutorialLoading}
              loadingLable="Adding tutorial..."
            />
          </div>
        </Form>
      </ModalComponent>

    </div>
  )
}