const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        sorter:true,
        // sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
        title: 'Email',
        dataIndex: 'email',
        sorter:true,
        // sorter: (a, b) => a?.email?.localeCompare(b?.email),
    },
    {
        title: 'Mobile No.',
        dataIndex: 'phoneNo',
        sorter:true,
        // sorter: (a, b) => a?.phoneNo?.localeCompare(b?.phoneNo),
    },
    {
        title: 'Request Date',
        dataIndex: 'requestDate',
        sorter:true,
        // sorter: (a, b) => new Date(a.requestDate) - new Date(b.requestDate),
    },
    {
        title: 'Approved Date',
        dataIndex: 'actionDate',
        sorter:true,
        // sorter: (a, b) => new Date(a.actionDate) - new Date(b.actionDate),
    },
    {
        title: 'Action By',
        dataIndex: 'actionBy',
        sorter:true,
    },
    {
        title: 'Action',
        dataIndex: 'action'
    }
];

export default columns;