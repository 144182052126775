const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'Phone No',
        dataIndex: 'mobileNo',
    },
    {
        title: 'Account No',
        dataIndex: 'loginId',
    },
    {
        title: 'Deal Id',
        dataIndex: 'dealId',
    },
    {
        title: 'Position Id',
        dataIndex: 'positionId',
    },
    {
        title: 'Close Time',
        dataIndex: 'closeTime',
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
    },
];

export default columns;