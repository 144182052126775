const columns = [
    {
        title: 'Account Holder Name',
        dataIndex: 'accountHolderName',
    },
    {
        title: 'Bank Name',
        dataIndex: 'bankName',
    },
    {
        title: 'IFSC Code',
        dataIndex: 'ifscCode',
    },
    {
        title: 'Swift Code',
        dataIndex: 'swiftCode',
    },
    {
        title: 'Branch Name',
        dataIndex: 'branchName',
    },
    {
        title: 'Create Date',
        dataIndex: 'createdDate',
    },
    {
        title: 'Status',
        dataIndex: 'isActive',
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
    }
];


export default columns;