const columns = [
    {
        title: "Group Name",
        dataIndex: "groupName",
        width: 100
    },
    {
        title: "Actions",
        dataIndex: "actions",
        width: 100
    },
];

export default columns;