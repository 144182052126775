const columns = [
    {
        title: "Key Name",
        dataIndex: "keyName",
    },
    {
        title: "Value",
        dataIndex: "value",
    },
    {
        title: "isEnable",
        dataIndex: "isDefault",
    },

    {
        title: "Update date",
        dataIndex: "lastModifiedDate",
    },
    {
        title: "Actions",
        dataIndex: "actions",
    },
];

export default columns;
