const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        render: defaultRender,
        // sorter: (a, b) => {
        //     const nameA = `${a?.firstName ?? ''} ${a?.lastName ?? ''}`.trim();
        //     const nameB = `${b?.firstName ?? ''} ${b?.lastName ?? ''}`.trim();
        //     return nameA.localeCompare(nameB);
        // },
    },
    {
        title: 'Email',
        dataIndex: 'email',
        render: defaultRender,
    },
    {
        title: 'Mobile No',
        dataIndex: 'phoneNo',
        render: defaultRender,
    },
    {
        title: 'Verified',
        dataIndex: 'isActive',
        render: defaultRender,
    },

    {
        title: 'User Type',
        dataIndex: 'userType',
        render: defaultRender,
    },
    {
        title: 'Register Date',
        dataIndex: 'createdDate',
        render: defaultRender,
        // sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
    },
    {
        title: 'Parent',
        dataIndex: 'parentName',
        render: defaultRender,
        // sorter: (a, b) => a?.parentName?.localeCompare(b?.parentName),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: defaultRender,
    },
];

export default columns;