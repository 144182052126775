import React, { useEffect, useState } from 'react'
import TableWithSkeleton from '../../../components/global/table/TableWithSkeleton'
import Pagination from '../../../components/global/pagination/Pagination';
import columns from '../../../columns/cashDeposit/bankDetails/columns';
import ModalComponent from '../../../components/global/modal/ModalComponent';
import { Input, Form, Switch } from "antd";
import LoadableButton from '../../../components/buttons/LoadableButton';
import { ActiveInactiveBankDetailApi, AddUpdateBankDetailsApi, DeleteBankDetailApi, GetAllBankDetailsApi } from '../../../api/request/cashDeposit/bankDetail';
import moment from 'moment/moment';
import toast from 'react-hot-toast';
import ConfirmationModal from '../../../components/global/modal/ConfirmationModal';
import Edit from '../../../assets/Edit';


const BankDetails = () => {
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isAddEditModal, setIsAddEditModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);
    const [bankDetailId, setBankDetailId] = useState('');

    const initialValues = { bankName: '', accountHolderName: '', accountNumber: null, ifscCode: '', branchName: '', swiftCode: '' };

    const handleEdit = (bankDetail) => {
        setIsAddEditModal(true);
        setIsAdd(false);
        setBankDetailId(bankDetail.id);
        form.setFieldsValue(bankDetail);
    };

    const getAllBankDetails = async () => {
        try {
            const { data } = await GetAllBankDetailsApi();
            if (data?.success) {
                setData(data?.data?.map((item) => {
                    return {
                        ...item,
                        isActive: <Switch onChange={() => handleStatusChange({ id: item?.id, isActive: !item?.isActive })} checkedChildren="ON" unCheckedChildren="OFF"
                            checked={item?.isActive} />,
                        createdDate: item?.createdDate ? moment(item?.createdDate).format('DD-MM-YYYY, hh:mm A') : '--',
                        actions: <div className='flex items-center gap-3'>
                            <button onClick={() => handleEdit(item)}><Edit /></button>
                            <button onClick={() => { setIsDelete(true); setBankDetailId(item?.id) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D91819" className="w-6 h-6 cursor-pointer hover:stroke-[#aa0001] duration-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                            </button>
                        </div>
                    }
                }));
                setTotalRecord(data?.totalCount);
            }
        }
        catch (error) { }
    }

    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            const payload = isAdd ? values : { ...values, id: bankDetailId };
            const { data } = await AddUpdateBankDetailsApi(payload);
            if (data?.success) {
                setIsLoading(false);
                getAllBankDetails();
                form.resetFields();
                setIsAddEditModal(false);
                toast.success(data?.message);
            }
        }
        catch (error) { setIsLoading(false) }
    }

    const handleStatusChange = async (value) => {
        setIsLoading(true);
        try {
            const { data } = await ActiveInactiveBankDetailApi(value);
            if (data?.success) {
                toast.success(data?.message);
                getAllBankDetails();
            }
            setIsLoading(false);
        }
        catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsLoading(false);
        }
    }

    const handleDelete = async (id) => {
        setIsLoading(true);
        try {
            const { data } = await DeleteBankDetailApi(id);
            if (data?.success) {
                toast.success(data?.message);
                setIsDelete(false);
                getAllBankDetails();
            }
            setIsLoading(false);
        }
        catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAllBankDetails();
    }, []);
    
    useEffect(() => {
        getAllBankDetails();
    }, [page, pageSize]);

    useEffect(() => {
        if (!isAddEditModal) {
            form.resetFields();
            setIsAddEditModal(false);
        }
    }, [isAddEditModal]);


    return (
        <>
            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6 flex justify-between items-center">
                    <p className="text-[#2F2B3D] text-xl font-semibold">Bank Details</p>
                    <button onClick={() => { setIsAdd(true); setIsAddEditModal(true); }} className="flex items-center bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover  duration-500">
                        <svg width="14" height="14" viewBox="0 0 16 16" fill="none" className="mr-2" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.85714 9.14286H0V6.85714H6.85714V0H9.14286V6.85714H16V9.14286H9.14286V16H6.85714V9.14286Z" fill="white" />
                        </svg>
                        Add
                    </button>
                </div>
                <div className="my-6">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
                    <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
                </div>
            </div>

            {/* Add Edit modal */}
            <ModalComponent isOpen={isAddEditModal} setIsOpen={setIsAddEditModal} title={isAdd ? 'Add bank detail' : 'Edit bank detail'} width={1300}>
                <Form className='w-full' autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">Bank Name</label>
                            <Form.Item name="bankName" rules={[{ required: true, message: 'Please enter bank name' }]}>
                                <Input placeholder="Bank name" className="mt-1 w-full ps-4 py-2" />
                            </Form.Item>
                        </div>
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">AccountHolder Name</label>
                            <Form.Item name="accountHolderName" rules={[{ required: true, message: 'Please enter account holder name' }]}>
                                <Input placeholder="Bank name" className="mt-1 w-full ps-4 py-2" />
                            </Form.Item>
                        </div>
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">Account Number</label>
                            <Form.Item name="accountNumber" rules={[{ required: true, message: 'Please enter account number' }]}>
                                <Input type='number' placeholder="Account number" className="mt-1 w-full ps-4 py-2" />
                            </Form.Item>
                        </div>
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">IFSC Code</label>
                            <Form.Item name="ifscCode" rules={[{ required: true, message: 'Please enter ifsc code' }]}>
                                <Input placeholder="IFSC code" className="mt-1 w-full ps-4 py-2" />
                            </Form.Item>
                        </div>
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">Branch Name</label>
                            <Form.Item name="branchName" rules={[{ required: true, message: 'Please enter  Branch Name' }]}>
                                <Input placeholder="Branch Name" className="mt-1 w-full ps-4 py-2" />
                            </Form.Item>
                        </div>
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">Swift Code</label>
                            <Form.Item name="swiftCode" rules={[{ required: true, message: 'Please enter Swift Code' }]}>
                                <Input placeholder="Swift Code" className="mt-1 w-full ps-4 py-2" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="flex justify-center items-center mt-7 gap-5">
                        <button type="button" onClick={() => setIsAddEditModal(false)} className="bg-primary text-sm text-white font-medium uppercase px-10 py-2 rounded-lg hover:bg-primary duration-500">Back</button>
                        <LoadableButton type='submit' className='bg-primary text-sm text-white font-medium uppercase px-10 py-2 rounded-lg hover:bg-primary duration-500'
                            lable={isAdd ? 'Submit' : 'Update'} isLoading={isLoading} loadingLable={isAdd ? 'Submitting...' : 'Updating...'} />
                    </div>
                </Form>
            </ModalComponent>

            {/* delete modal */}
            <ConfirmationModal
                isOpen={isDelete}
                setIsOpen={setIsDelete}
                message='Are you sure, you want to delete ?'
                isLoading={isLoading}
                loadingLabel='Removing...'
                onConfirm={() => handleDelete(bankDetailId)}
            />
        </>
    )
}

export default BankDetails;