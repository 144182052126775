import React, { useEffect, useState } from 'react';
import { Form, Select, Input} from 'antd';
import Segmented from "../../../components/segmented/Segmented.jsx";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
import { useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GetMT5AccountsApi, GetWalletApi, WalletToAccountTransferApi, AccountToWalletTransferApi } from '../../../api/request/client.js';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

export default function Transfer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userDetails } = useSelector(state => state.user);
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState('Wallet To Account');
  const [wallet, setWallet] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({ amount: '', LoginTerminalId: null, conditions: false });

  const getWallet = async () => {
    try {
      let params = {
        RuleType: 'InternalTransfer'
      }
      const { data } = await GetWalletApi(id, new URLSearchParams(params).toString());
      if (data?.success) {
        setWallet(data?.data)
      }
    } catch (error) { }
  }

  const getAccounts = async () => {
    try {
      const { data } = await GetMT5AccountsApi(id);
      if (data?.success) {
        setAccounts(
          data?.data?.map((item) => {
            return { value: (item?.login).toString(), label: (item?.login).toString() }
          })
        )
      }
    } catch (error) { }
  }

  useEffect(() => {
    if (searchParams?.get('filter')) {
      setFilter(searchParams?.get('filter'));
    };
    getWallet();
    getAccounts();
  }, []);

  useEffect(() => {
    setSearchParams(`filter=${filter}`);
    form?.resetFields();
  }, [filter]);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      let params = {
        LoginTerminalId: values?.LoginTerminalId,
        UserId: userDetails?._id,
        walletId: wallet?.walletId
      }
      if (filter == 'Wallet To Account') {
        const { data } = await WalletToAccountTransferApi({ ...params, walletInternalAmount: Number(values?.amount) });
        if (data?.success) {
          toast.success(data?.message);
          form.resetFields();
        }
      }
      if (filter == 'Account To Wallet') {
        const { data } = await AccountToWalletTransferApi({ ...params, terminalInternalAmount: Number(values?.amount) });
        if (data?.success) {
          toast.success(data?.message);
          form.resetFields();
        }
      }
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div>
        <Segmented options={['Wallet To Account', 'Account To Wallet']} value={filter} setValue={setFilter} />
      </div>
      <div className='mt-5'>
        <div className='border-[2px] border-light rounded-[20px] py-2 w-full'>
          <div className='p-4 grid sm:grid-cols-3 lg:grid-cols-4 gap-5 lg:divide-x'>
            <div>
              <div className='flex justify-center'>
                <p className='text-base font-normal text-center pb-2 '>Wallet Balance</p>
              </div>
              <p className='text-xl primary font-semibold text-center mt-2'>$ {wallet?.walletAmount || '0'}</p>
            </div>
            <div>
              <div className='flex justify-center'>
                <p className='text-base font-normal text-center pb-2 '>Currencies</p>
              </div>
              <p className='text-xl primary font-semibold text-center mt-2'>{wallet?.currency || '--'}</p>
            </div>
            <div>
              <div className='flex justify-center'>
                <p className='text-base font-normal text-center pb-2 '>Minimum Transfer</p>
              </div>
              <p className='text-xl primary font-semibold text-center mt-2'>$ {wallet?.minAmount || '0'}</p>
            </div>
            <div>
              <div className='flex justify-center'>
                <p className='text-base font-normal text-center pb-2 '>Maximum Transfer</p>
              </div>
              <p className='text-xl primary font-semibold text-center mt-2'>$ {wallet?.maxAmount || '0'}</p>
            </div>
          </div>
        </div>

        <div className='border-[2px] border-light rounded-[20px] pt-4 lg:pt-6 w-full mt-5'>
          <p className='text-[#2F2B3D] text-xl font-semibold px-4 lg:px-6 pb-4 border-b  border-light'>{filter}</p>
          <Form className='w-full lg:p-6 p-4' autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
            <div className='md:flex md:flex-wrap gap-5'>
              <div className="md:w-1/3 input-white">
                <label className='text-lg text-[#2F2B3DCC] font-medium'>Amount In USD</label>
                <Form.Item name='amount' rules={[{ required: true, message: 'Please enter amount.' }]}>
                  <Input placeholder="Amount in USD" className="mt-1 w-full ps-4 py-2" />
                </Form.Item>
              </div>
              <div className="md:w-1/3 ant-select-selector-white">
                <label className='text-lg text-[#2F2B3DCC] font-medium'>Account</label>
                <Form.Item name='LoginTerminalId' rules={[{ required: true, message: 'Please select an account.' }]}>
                  <Select className='w-full mt-1' placeholder='Select an acount' options={accounts} />
                </Form.Item>
              </div>
            </div>
           
            <div className="md:w-1/4 mt-6 md:mt-4">
              <LoadableButton
                className='bg-primary text-sm text-white font-semibold rounded-lg px-8 py-2 uppercase themeHover duration-500'
                disabled={isLoading}
                type="Submit"
                lable='Submit'
                isLoading={isLoading}
                loadingLable='Processing...'
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
