import React, { useState, useEffect } from "react";
import { Form, Input, Upload, Image, Switch } from "antd";
import columns from "../../columns/PromotionKit.js";
import Pagination from "../../components/global/pagination/Pagination.jsx";
import TableWithSkeleton from "../../components/global/table/TableWithSkeleton.jsx";
import ModalComponent from "../../components/global/modal/ModalComponent.jsx";
import toast from "react-hot-toast";
import ImgCrop from 'antd-img-crop';
import LoadableButton from "../../components/buttons/LoadableButton.jsx";
import { AddUpdatePromotionKitApi, GetPromotionKitApi, PromotionKitStatusChangeApi, DeleteKitsApi } from "../../api/request/promotionKit.js";
import moment from "moment";
import ConfirmationModal from "../../components/global/modal/ConfirmationModal.jsx";
import Segmented from "../../components/segmented/Segmented.jsx";
import { useSearchParams } from "react-router-dom";
import Edit from "../../assets/Edit.jsx";
import Addpromotionkit from "./component/addpromotionkit.js"


export default function PromotionKit() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isOpenAddKitModal, setIsOpenAddKitModal] = useState(false);
    const [isOpenEditKitModal, setIsOpenEditKitModal] = useState(false);
    const [isLoadingAddKitModal, setIsLoadingAddKitModal] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [file, setFile] = useState(null);
    const [initialValues, setInitialValues] = useState({ height: '', width: '', uploadPromotionKit: null });
    const [formValue, setFormValue] = useState({ height: '', width: '' });
    const [selectedKit, setSelectedKit] = useState(null);
    const [isStatusConfirmationModal, setIsStatusConfirmationModal] = useState(false);
    const [isKitStatusLoading, setIsKitStatusLoading] = useState(false);
    const [deleteBannerModal, setDeleteKitsModal] = useState(false);
    const [selectedFaqId, setSelectedKitId] = useState('');
    const [isDelKitsLoading, setIsDelKitsLoading] = useState(false);
    const [value, setValue] = useState("Active");
    const [modalTitle, setModalTitle] = useState('Add Promotion Kit');
    const [kitId, setkitId] = useState(null);


    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ file: newFile }) => {
        if (newFile.status === 'removed') {
            setFile(null);
            form.setFieldsValue({ UploadPromotionKit: null });
        } else {
            setFile(newFile);
            form.setFieldsValue({ UploadPromotionKit: newFile });
        }
    };

    const UploadButton = (
        <button type="button">
            <div className="flex justify-center"><img src="assets/icons/Upload_Document_icon.svg" alt="icon" /></div>
            Upload
        </button>
    );

    const statusHandler = (item) => {
        setSelectedKit(item);
        setIsStatusConfirmationModal(true);
    }

    const getAllKits = async () => {
        try {
            setIsLoading(true);
            let params = {
                PageNumber: page,
                PageSize: pageSize,
                SearchText: searchText,
                FilterText: value
            }
            const { data } = await GetPromotionKitApi(new URLSearchParams(params).toString());
            if (data?.success) {
                setData(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            createdDate: item?.createdDate ? moment(item?.createdDate).format('DD-MM-YYYY, hh:mm A') : null,
                            image: (<Image src={item?.url} fallback='assets/images/banner-placeholder.png' className="object-cover" style={{ width: '40px', height: '40px' }} alt="img" />),
                            status: (
                                <Switch onChange={() => statusHandler(item)} checkedChildren="On" unCheckedChildren="Off" checked={item?.status == 'Active' ? true : false} />
                            ),

                            actions: (
                                <div className="flex gap-2">
                                    <button onClick={() => updatekitHandler(item)} className="mr-3 ml-3" button><Edit /></button>
                                    <button onClick={() => { setSelectedKitId(item?.id); setDeleteKitsModal(true) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D91819" className="w-6 h-6 cursor-pointer hover:stroke-[#aa0001] duration-500">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                        </svg>
                                    </button>
                                </div>
                            )
                        }
                    })
                );
                setTotalRecord(data?.totalRecords);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const openAddKitModal = () => {
        setIsOpenAddKitModal(true);
    };

    const updatekitHandler = (kit) => {
        setModalTitle('Update Promotion Kit');
        setkitId(kit?.id);
        setFormValue({
            height: Number(kit?.height),
            width: Number(kit?.width),
        })
        setInitialValues({
            height: Number(kit?.height),
            width: Number(kit?.width),
        })
        form.setFieldsValue({
            height: Number(kit?.height),
            width: Number(kit?.width),
        });

        setIsOpenEditKitModal(true);
    };

    useEffect(() => {
        setSearchParams(`filter=${value?.toString()}`);
    }, [value]);

    useEffect(() => {
        if (searchParams?.get("filter")?.trim()) {
            setValue(searchParams?.get("filter")?.trim());
        }
    }, []);

    useEffect(() => {
        getAllKits();
    }, [page, pageSize, searchText, value]);


    const deleteFaqHandler = async () => {
        try {
            setIsDelKitsLoading(true);
            const { data } = await DeleteKitsApi(selectedFaqId);
            if (data?.success) {
                toast.success(data?.message);
                setDeleteKitsModal(false);
                getAllKits();
            }
            setIsDelKitsLoading(false);
        } catch (err) {
            setIsDelKitsLoading(false);
        }
    }

    const EditKitHandler = async (values) => {
        try {
            setIsLoadingAddKitModal(true);

            let apiHeader = {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            };

            let params = {
                id: kitId,
                width: Number(values?.width),
                height: Number(values?.height),
                IsActive: true,
                UploadPromotionKit: values?.UploadPromotionKit?.originFileObj,
            };
            const { data } = await AddUpdatePromotionKitApi(params, apiHeader);

            if (data?.success) {
                getAllKits();
                toast.success(data?.message);
                setIsOpenEditKitModal(false);
                setFile(null);
                form.resetFields();
            }

            setIsLoadingAddKitModal(false);
        } catch (error) {
            if (error?.response?.data?.error?.message) {
                toast.error(error?.response?.data?.error?.message);
            }
            setIsLoadingAddKitModal(false);
        }
    };


    const onChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }

    const kitStatusHandler = async () => {
        try {
            setIsKitStatusLoading(true);
            let params = {
                id: selectedKit?.id,
                status: selectedKit?.status == 'Active' ? false : true
            };
            const { data } = await PromotionKitStatusChangeApi(params);
            if (data?.success) {
                toast.success(data?.message);
                setIsStatusConfirmationModal(false);
                getAllKits();
            }
            setIsKitStatusLoading(false);
        } catch (error) {
            setIsKitStatusLoading(false);
        }
    }

    useEffect(() => {
        if (!isOpenEditKitModal) {
            form.resetFields();
            setkitId(null)
            setFile(null);
            setInitialValues({ height: '', width: '' })
            setFormValue({ height: '', width: '' })

        }
    }, [isOpenEditKitModal])

    return (
        <div>
            <div className="flex justify-between flex-wrap gap-5">
                <Segmented
                    options={["Active", "Deactive"]}
                    value={value}
                    setValue={setValue}
                />
                <div className="flex items-center">
                    <button onClick={openAddKitModal} className="flex items-center bg-primary text-sm text-white font-semibold px-4 py-2 rounded-lg themeHover  duration-500">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 mr-2">
                            <path fill-rule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
                        </svg>
                        Add New Kit
                    </button>
                </div>
            </div>


            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6"><p className="text-[#2F2B3D] text-xl font-semibold">Promotion Kits</p></div>
                <div className="my-6">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
                    <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
                </div>
            </div>

            {/* Add Promotion kit modal */}

            <Addpromotionkit isOpen={isOpenAddKitModal} setIsOpen={setIsOpenAddKitModal} getAllKits={getAllKits} />

            {/* Edit Promotion kit modal */}
            <ModalComponent isOpen={isOpenEditKitModal} setIsOpen={setIsOpenEditKitModal} title={"Update Promotion Kit"} width={600}>
                <Form className='w-full' autoComplete="off" form={form} initialValues={initialValues} onFinish={EditKitHandler}>
                    <div className="grid sm:grid-cols-2 gap-5">
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">Width</label>
                            <Form.Item name="width" rules={[{ validator: (_, value) => value > 0 ? Promise.resolve() : Promise.reject('Width must be greater than 0.') }]}>
                                <Input type="number" placeholder="Width" className="mt-1 w-full py-2" name="width" onChange={(e) => onChange(e)} value={initialValues.width} />
                            </Form.Item>
                        </div>
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">Height</label>
                            <Form.Item name="height" rules={[{ validator: (_, value) => value > 0 ? Promise.resolve() : Promise.reject('Height must be greater than 0.') }]}>
                                <Input type="number" placeholder="Height" className="mt-1 w-full py-2" name="height" onChange={(e) => onChange(e)} value={initialValues.height} />
                            </Form.Item>
                        </div>
                        <div className="sm:col-span-2">
                            {Number(formValue.height) && Number(formValue.width) ? (
                                <div>
                                    <label className="text-base text-[#2F2B3DCC] font-medium">Promotion Kit Image ({formValue.width}x{formValue.height})</label>
                                    <Form.Item name="UploadPromotionKit" rules={[{ required: true, message: "Please upload a image." }]}>
                                        <ImgCrop rotationSlider showGrid aspect={formValue?.width / formValue?.height}>
                                            <Upload
                                                customRequest={({ file, onSuccess }) => { onSuccess("ok") }}
                                                className="mt-1"
                                                listType="picture-card"
                                                fileList={file ? [file] : []}
                                                onPreview={handlePreview}
                                                onChange={handleChange}
                                            >
                                                {file ? null : UploadButton}
                                            </Upload>
                                        </ImgCrop>
                                    </Form.Item>
                                    {previewImage && (
                                        <Image
                                            wrapperStyle={{ display: 'none' }}
                                            preview={{
                                                visible: previewOpen,
                                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            ) : (null)}
                        </div>
                    </div>
                    <div className="flex justify-end mt-5">
                        <LoadableButton
                            className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
                            type="Submit"
                            lable="Submit"
                            isLoading={isLoadingAddKitModal}
                            loadingLable="Adding kit..."
                        />
                    </div>
                </Form>
            </ModalComponent>

            {/* ---- Status confirmation modal ---- */}
            <ConfirmationModal
                isOpen={isStatusConfirmationModal}
                setIsOpen={setIsStatusConfirmationModal}
                message={`Are you sure you want to ${selectedKit?.status == 'Active' ? 'inactive' : 'active'} this promotion kit?`}
                onConfirm={kitStatusHandler}
                isLoading={isKitStatusLoading}
                loadingLabel='Changing status...'
            />
            {/* ---- Delete Faq modal ---- */}
            <ConfirmationModal
                isOpen={deleteBannerModal}
                setIsOpen={setDeleteKitsModal}
                message='Are you sure you want to remove this Promotion Kits?'
                onConfirm={deleteFaqHandler}
                isLoading={isDelKitsLoading}
                loadingLabel='Deleting banner...'
            />
        </div>
    )
}