import React, { useEffect, useState } from 'react';
import columns from '../../../columns/Withdraw.js';
import { Checkbox, Form, Input } from 'antd';
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import TableWithSkeleton from '../../../components/global/table/TableWithSkeleton.jsx';
import Pagination from '../../../components/global/pagination/Pagination.jsx';
import Segmented from "../../../components/segmented/Segmented.jsx";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
import toast from 'react-hot-toast';
import moment from 'moment/moment.js';
import ModalComponent from '../../../components/global/modal/ModalComponent.jsx';
import { GetWithdrawRequestApi, GetWalletApi, AddWithdrawRequestApi, GetTraderByIdApi } from '../../../api/request/client.js';

export default function Withdraw() {
  const [form] = Form.useForm();
  const params = useParams();
  const [trader, setTrader] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [wallet, setWallet] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddReqLoading, setIsAddReqLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [filter, setFilter] = useState('Pending');
  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState({ amount: '', thirdPartyWalletAddress: '', conditions: false });
  const [sorter, setSorter] = useState({ SortBy: 'name', SortOrder: '' });

  const getUser = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetTraderByIdApi(params?.id);
      if (data?.success) {
        setTrader(data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  const getWallet = async () => {
    try {
      let apiParams = {
        RuleType: 'InternalTransfer'
      }
      const { data } = await GetWalletApi(params?.id, new URLSearchParams(apiParams).toString());

      if (data?.success) {
        setWallet(data?.data)
      }
    } catch (error) { }
  }

  const getRequests = async () => {
    try {
      setIsLoading(true);
      let apiParams = {
        UserId: params?.id,
        FilterText: filter,
        ...sorter
      }
      const { data } = await GetWithdrawRequestApi(new URLSearchParams(apiParams).toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return ({
              ...item,
              createdAt: item?.createdAt ? moment(item?.createdAt).format('DD-MM-YYYY LT') : null
            })
          })
        );
        setTotalRecord(data?.totalItems);
      }
      setIsLoading(false);
    } catch (error) {
      setData([]);
      setIsLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    try {
      setIsAddReqLoading(true);
      let apiParams = {
        userId: params?.id,
        amount: values?.amount,
        walletAccountNo: wallet?.walletAccountNo,
        thirdPartyWalletAddress: values?.thirdPartyWalletAddress
      }
      const { data } = await AddWithdrawRequestApi(apiParams);
      if (data?.success) {
        getRequests();
        toast.success(data?.message);
        setSearchParams('filter=Pending');
        form?.resetFields();
      }
      setIsAddReqLoading(false);
      setIsOpenModal(false);
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsAddReqLoading(false);
    }
  }
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
    } else {
      setSorter({ SortBy: 'name', SortOrder: '' });
    }
  };

  useEffect(() => {
    form?.resetFields();
  }, [isOpenModal]);

  useEffect(() => {
    getWallet();
    getUser();
  }, []);

  useEffect(() => {
    setSearchParams(`filter=${filter}`);
    getRequests();
  }, [filter, sorter]);

  return (
    <div>
      <div className='border-[2px] border-light rounded-xl pt-4 w-full'>
        <div className='p-4 mb-3 grid sm:grid-cols-3 gap-5 lg:divide-x'>
          <div>
            <div className='flex justify-center'>
              <p className='text-base font-normal text-center pb-2'>Full Name</p>
            </div>
            <p className='text-xl primary font-semibold text-center mt-2'>{trader?.firstName} {trader?.lastName}</p>
          </div>
          <div>
            <div className='flex justify-center'>
              <p className='text-base font-normal text-center pb-2'>Wallet Account No.</p>
            </div>
            <p className='text-xl primary font-semibold text-center mt-2'>{wallet?.walletAccountNo}</p>
          </div>
          <div>
            <div className='flex justify-center'>
              <p className='text-base font-normal text-center pb-2'>Wallet Balance</p>
            </div>
            <p className='text-xl primary font-semibold text-center mt-2'>$ {wallet?.walletAmount}</p>
          </div>
        </div>
      </div>

      <div className='border-[2px] border-light rounded-xl pt-4 lg:pt-6 w-full mt-5'>
        <div className='flex flex-wrap justify-between items-center gap-5 px-4 lg:px-6'>
          <Segmented options={['Pending', 'Approved', 'Rejected']} value={filter} setValue={setFilter} />
          <button className='bg-primary text-sm text-white font-medium uppercase px-6 py-2 rounded-lg themeHover duration-500' onClick={() => setIsOpenModal(true)}>Withdraw</button>
        </div>
        <div className='my-6'>
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} onChange={handleTableChange} />
          <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div>
      </div>

      <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} title='Create withdraw request'>
        <Form className='w-full' autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
          <div className='input-white'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>Amount In USD</label>
            <Form.Item name='amount' rules={[{ required: true, message: 'Please enter amount.' }, { validator: (_, value) => Number.isInteger(Number(value)) ? Promise.resolve() : Promise.reject(new Error('Please enter a valid amount.')) }]}>
              <Input type='number' placeholder="Amount in USD" className="mt-1 w-full ps-4 py-2" />
            </Form.Item>
          </div>
          <div className='input-white mt-6'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>Wallet Address</label>
            <Form.Item name='thirdPartyWalletAddress' rules={[{ required: true, message: 'Please enter wallet address.' }]}>
              <Input placeholder="Wallet Address" className="mt-1 w-full ps-4 py-2" />
            </Form.Item>
          </div>
          <div>
            <Form.Item name='conditions' valuePropName="checked" rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Please accept the terms and conditions.')), }]} >
              <Checkbox className='mt-4'>Yes, I agreed to the <Link to={'/terms_&_conditions'} className='primary hover:underline duration-300'>Terms and conditions</Link></Checkbox>
            </Form.Item>
          </div>
          <div className='flex justify-center items-center gap-5 mt-10'>
            <LoadableButton
              className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover duration-500'
              disabled={isAddReqLoading}
              type="Submit"
              lable='Submit'
              isLoading={isAddReqLoading}
              loadingLable='Adding request...'
            />
            <button className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500' onClick={() => setIsOpenModal(false)}>Cancel</button>
          </div>
        </Form>
      </ModalComponent>
    </div >
  )
}