import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const exportAsPDF = (data, removableColumns, fileName = 'Download') => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    let filteredData = [];
    if (removableColumns?.length > 0) {
        filteredData = data.map(item => {
            const filteredItem = { ...item };
            removableColumns.forEach(column => {
                delete filteredItem[column];
            });
            return filteredItem;
        });
    } else {
        filteredData = data;
    }

    // Prepare the data for the table
    const tableColumnHeaders = Object.keys(filteredData[0]); // Get the column headers from the keys of the first object
    const tableRows = filteredData.map(item => Object.values(item)); // Convert each object to an array of its values

    // Add table to the PDF
    doc.autoTable({
        head: [tableColumnHeaders], // Set column headers
        body: tableRows, // Set table rows
        startY: 20, // Starting y coordinate of the table
        theme: 'striped', // Optional: table styling ('striped', 'grid', 'plain')
        styles: { fontSize: 8 }, // Optional: font size for table
    });

    // Save the PDF document
    doc.save(`${fileName}.pdf`);
};