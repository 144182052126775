import React, { useEffect, useState } from 'react';
import TableWithSkeleton from '../../components/global/table/TableWithSkeleton';
import ModalComponent from '../../components/global/modal/ModalComponent';
import columns from '../../columns/ibApproved/IbAccept';
import { useNavigate, useParams } from "react-router-dom";
import { GetAllAccountTypeApi, IBRejectApproveApi } from '../../api/request/ibapproved';
import { Form, Input, Spin } from 'antd';
import LoadableButton from '../../components/buttons/LoadableButton';
import toast from 'react-hot-toast';

const IBAccept = () => {

    const params = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [data, setData] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState([]);
    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--theme'))?.trim();
    const lightPrimaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--theme-light'))?.trim();

    const getAccountTypes = async () => {
        try {
            setIsLoadingAccounts(true);
            let apiParams = {
                UserId: params?.id,
                AccountType: true
            }
            const { data } = await GetAllAccountTypeApi(new URLSearchParams(apiParams).toString());
            if (data?.success) {
                setAccounts(data?.data);
            }
            setIsLoadingAccounts(false);
        } catch (error) {
            setIsLoadingAccounts(false);
        }
    }

    const addAccount = (account, index) => {
        const updatePlansArray = (plansArray, newPlan) => {
            const index = plansArray.findIndex(plan => plan.planId === newPlan.planId);
            if (index !== -1) {
                plansArray.splice(index, 1);
            } else {
                plansArray.push(newPlan);
            }
            return plansArray;
        }
        setSelectedIndex((prevNumbers) => {
            if (prevNumbers.includes(index)) {
                return prevNumbers.filter((n) => n !== index);
            } else {
                return [...prevNumbers, index];
            }
        });
        setSelectedAccounts(updatePlansArray(selectedAccounts, account));
    }

    useEffect(() => {
        setSelectedAccounts([]);
        setSelectedIndex([]);
    }, [isOpenModal]);

    const onFinish = async (values) => {
        let commissionArray = [];
        let groupIdArray = [];
        data?.map((item, index) => (
            commissionArray.push(values?.[`commission-${index + 1}`]),
            groupIdArray.push(item?.groupId)
        ))
        try {
            setIsLoadingSubmit(true);
            let apiParams = {
                userId: params?.id,
                comment: '',
                isApproved: true,
                isRejected: false,
                commission: commissionArray,
                groupId: groupIdArray,
                isNewRecord: true
            }
            const { data } = await IBRejectApproveApi(apiParams);
            if (data?.success) {
                toast.success(data?.message);
                setSelectedAccounts([]);
                setSelectedIndex([]);
                setData([]);
                form.resetFields();
                navigate('/ib_approved');
            }
            setIsLoadingSubmit(false);
        } catch (error) {
            if (error?.response?.data?.error?.message) {
                toast.error(error?.response?.data?.error?.message);
            }
            setIsLoadingSubmit(false);
        }
    };

    const accountSelectionHandler = () => {
        setIsOpenModal(false);
        setIsLoading(true);
        let array = [];
        selectedAccounts?.map((account, index) => (
            array.push({ no: index + 1, planName: account?.planName, maxCommission: account?.maxCommission, commisionamount: '', groupId: account?.groupId })
        ));
        setData(array?.map((item) => {
            return {
                ...item,
                commisionamount: (
                    <Form.Item name={`commission-${item.no}`} rules={[
                        { required: true, message: 'Please enter commission.' },
                        { validator: (_, value) => value > item.maxCommission ? Promise.reject(new Error(`Maximum commission should be ${item.maxCommission}.`)) : Promise.resolve(), },
                    ]} >
                        <Input type='number' className='py-2' placeholder='Enter commission' />
                    </Form.Item>
                )
            };
        }));
        setIsLoading(false);
    }


    return (
        <div>
            <div className="flex justify-end flex-wrap gap-5">
                <div className="flex items-center flex-wrap gap-4">
                    <div className="flex items-center gap-4">
                        <button
                            className="flex items-center bg-primary text-sm text-white font-semibold px-4 py-2 rounded-lg themeHover  duration-500"
                            onClick={() => { getAccountTypes(); setIsOpenModal(true) }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-5 h-5 mr-2"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                            Add Account Type
                        </button>
                    </div>
                </div>
            </div>

            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">{'Ib Accept'}</p>
                </div>
                <div className="my-6">
                    <Form form={form} onFinish={onFinish}>
                        <TableWithSkeleton data={data} columns={columns} loading={isLoading} />
                        {
                            data?.length > 0 &&
                            <div className='mt-6 mx-6 flex justify-end'>
                                <Form.Item>
                                    <LoadableButton
                                        type='submit'
                                        className='bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover duration-500'
                                        lable='Submit'
                                        loadingLable='Submitting...'
                                        isLoading={isLoadingSubmit}
                                    />
                                </Form.Item>
                            </div>
                        }
                    </Form>
                </div>
            </div>

            {/* Add accounts modal */}
            <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} title={'Add Account Type'}>
                {
                    isLoadingAccounts ? (
                        <div className="flex justify-center flex-col my-[10%]">
                            <Spin size="large" />
                            <p className="primary text-center mt-2">Loading...</p>
                        </div>
                    ) : (
                        <div>
                            <div className='grid grid-cols-4'>
                                {accounts?.map((account, index) => (
                                    <div className='px-2' key={index}>
                                        <div className='cursor-pointer border rounded-md p-2' style={{ borderColor: `${selectedIndex.includes(index) ? primaryColor : lightPrimaryColor}` }} onClick={() => addAccount(account, index)}>
                                            <p className='text-center font-semibold text-lg'>{account?.planName}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='flex justify-center mt-6'>
                                <button onClick={accountSelectionHandler} className='bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover duration-500'>Proceed</button>
                            </div>
                        </div>
                    )
                }
            </ModalComponent>
        </div>
    )
}

export default IBAccept