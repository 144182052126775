const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        render: defaultRender,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        render: defaultRender,
    },
    {
        title: 'Mobile No',
        dataIndex: 'phoneNo',
        render: defaultRender,
    },
    {
        title: 'Role',
        dataIndex: 'userType',
        render: defaultRender,
    },
    {
        title: 'Register Date',
        dataIndex: 'createdDate',
        render: defaultRender,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: defaultRender,
    },
];

export default columns;