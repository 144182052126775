const columns = [
    {
        title: 'First Name',
        dataIndex: 'firstName',
        // sorter: (a, b) => a.firstName - b.firstName,
    },
    {
        title: 'Last Name',
        dataIndex: 'lastName',
        // sorter: (a, b) => a.lastName - b.lastName,
    },
    
    {
        title: 'Email',
        dataIndex: 'primaryEmail',
        // sorter: (a, b) => a.primaryEmail - b.primaryEmail,
    },
    {
        title: 'Commission',
        dataIndex: 'commissionAmount',
        // sorter: (a, b) => a.commissionAmount - b.commissionAmount,
    },
    {
        title: 'Request Date',
        dataIndex: 'transactionDate',
        // sorter: (a, b) => a.transactionDate - b.transactionDate,
    },
   
    {
        title: 'Status',
        dataIndex: 'status',
    }
];

export default columns;