import React, { useState,useEffect } from 'react';
import { Input, Divider, Space } from 'antd';
import TableWithSkeleton from '../../../components/global/table/TableWithSkeleton'
import Pagination from '../../../components/global/pagination/Pagination';
import columns from '../../../columns/cashDeposit/usdConversion/columns';
import {  AllCurrency,AllCurrencySync,AddUpdatUpdateCurrencyApi } from '../../../api/request/cashDeposit/usdConversion';
import moment from 'moment/moment';
import toast from 'react-hot-toast';

function UsdConversion() {
    
    const [usdValue, setUsdValue] = useState('1');
    const [inrValue, setInrValue] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [currencyid,setCurrencyid] = useState()
    console.log(currencyid,"currencyid");
    

    const getAllCurrencyDetails = async () => {
        try {
            const { data } = await AllCurrency();
            if (data?.success) {
                setData(data?.data?.map((item) => {
                    return {
                        ...item,
                        modifiedDate: item?.modifiedDate ? moment(item?.modifiedDate).format('DD-MM-YYYY, hh:mm A') : '--',
                    }
                }));
                setCurrencyid(data?.data[0]?.id)
                setTotalRecord(data?.totalCount);
            }
        }
        catch (error) { }
    }
    const getAllCurrencySync = async () => {
        try {
            const payload = {
                BaseCurrency: "USD",
                TargetCurrency:"INR",
                Amount:1
            }
            const { data } = await AllCurrencySync(new URLSearchParams(payload).toString());
            if (data?.success) {
                setInrValue(data?.data?.exchangeRate)
            }
        }
        catch (error) { }
    }

    const handleUpdate = async (values) => {
        setIsLoading(true);
        try {
            const payload = {
                currencyId:currencyid,
                amount:inrValue,
            }
            const { data } = await AddUpdatUpdateCurrencyApi(payload);
            if (data?.success) {
                setIsLoading(false);
                getAllCurrencyDetails();
                setInrValue("")
                toast.success(data?.message);
            }
        }
        catch (error) { setIsLoading(false) }
    }
    useEffect(() => {
        getAllCurrencyDetails();
    }, [page, pageSize]);
    return (
        <>
            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6 flex justify-between items-center">
                    <p className="text-[#2F2B3D] text-xl font-semibold">INR / USD</p>
                    <div className='flex items-center'>

                        <button
                            className="flex items-center bg-primary text-sm text-white font-semibold px-5 py-1 mr-3 rounded-lg themeHover duration-500"
                            onClick={getAllCurrencySync}
                        >
                            <svg  width="14" height="14" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                        </button>

                        <button
                            className="flex items-center bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover duration-500"
                            onClick={handleUpdate}
                        >
                            Update
                        </button>
                    </div>
                </div>
                <Divider />
                <div className='px-4 lg:px-6 mb-7'>
                    <div className="grid sm:grid-cols-5 lg:grid-cols-5 gap-5">
                        
                        <div className="input-white">
                        <label className="text-base text-[#2F2B3DCC] font-medium block mb-1">USD</label>
                            <Space.Compact>
                                <Input
                                    style={{ width: '20%' }}
                                    disabled
                                    defaultValue="USD"
                                    className="mt-1 w-full ps-4 py-2"
                                />
                                <Input
                                    style={{ width: '80%', backgroundColor: '#ffffff' }}
                                    disabled
                                    defaultValue={usdValue}
                                    className="mt-1 w-full ps-4 py-2"
                                />
                            </Space.Compact>
                        </div>
                        <div className="input-white col-span-3" >
                        <label className="text-base text-[#2F2B3DCC] font-medium block mb-1">Amount in INR</label>
                            <Space.Compact>
                                <Input
                                    style={{ width: '20%' }}
                                    disabled
                                    defaultValue="INR"
                                    className="mt-1 w-full ps-4 py-2"
                                />
                                <Input
                                    style={{ width: '80%' }}
                                    type='number'
                                    value={inrValue}
                                    onChange={(e) => setInrValue(e.target.value)}
                                    className="mt-1 w-full ps-4 py-2"
                                />
                            </Space.Compact>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6 flex justify-between items-center">
                    <p className="text-[#2F2B3D] text-xl font-semibold">Update History</p>
                    {/* <button onClick={() => { setIsAdd(true); setIsAddEditModal(true); }} className="flex items-center bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover  duration-500">
                        <svg width="14" height="14" viewBox="0 0 16 16" fill="none" className="mr-2" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.85714 9.14286H0V6.85714H6.85714V0H9.14286V6.85714H16V9.14286H9.14286V16H6.85714V9.14286Z" fill="white" />
                        </svg>
                        Add
                    </button> */}
                </div>
                <div className="my-6">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
                    <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
                </div>
            </div>

        </>
    )
}

export default UsdConversion
