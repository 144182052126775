const columns = [
    {
      title: "Rule Name",
      dataIndex: "ruleName",
    },
    {
      title: "Description",
      dataIndex: "description",
    //   width:100
    },
    {
      title: "Rule Type",
      dataIndex: "ruleType",
    },
    {
      title: "Is Active",
      dataIndex: "isDefault",
    },
    {
        title: "Demo Account",
        dataIndex: "demoAccount",
      },
      {
        title: "Live Account",
        dataIndex: "liveAccount",
      },
    {
      title: "Actions",
      dataIndex: "actions",
    },
  ];
  
  export default columns;
  