import Dashboard from '../pages/dashboard/Dashboard.jsx';
import Client from '../pages/client/Client.jsx';
import ClientDetails from '../pages/client/components/ClientDetails.jsx';
import LiveAccountDetails from '../pages/client/components/LiveAccountDetails.jsx';
import Kyc from '../pages/kyc/Kyc.jsx';
import Accounts from '../pages/accounts/MainMT5.jsx';
import GenerateCommission from '../pages/ibCommission/generateCommission/GenerateCommission.jsx';
import IBCommission from '../pages/ibCommission/generateCommission/components/IBCommission.jsx';
import WithdrawCommission from '../pages/ibCommission/withdrawCommission/WithdrawCommission.jsx';
import Withdraw from '../pages/wallet/Withdraw/Withdraw.jsx';
import Deposit from '../pages/wallet/components/Deposit.jsx';
import InternalTransfer from '../pages/wallet/components/InternalTransfer.jsx';
import EmailTemplate from '../pages/emailTemplate/EmailTemplate.jsx';
import Task from '../pages/task/Task.jsx';
import Banner from '../pages/banner/Banner.jsx';
import AdminLogin from '../pages/auth/Admin_login.jsx';
import ResetPassword from '../pages/authpage/ResetPassword.jsx';
import ForgetPassword from '../pages/authpage/ForgetPassword.jsx';
import UserOtp from '../pages/authpage/Otp.jsx';
import Login from '../pages/authpage/Login.jsx';
import ForgetPasswordotp from '../pages/authpage/forget_otp.jsx';
import Roles from '../pages/settings/roles/Roles.jsx';
import Ticket from '../pages/support/ticket/Ticket.jsx';
import ViewTicket from '../pages/support/ticket/ViewTicket.jsx';
import Faq from "../pages/support/faq/Faq.jsx";
import AccountType from '../pages/accountType/AccountType.jsx';
import Notifications from '../pages/settings/notifications/Notifications.jsx';
import Rules from '../pages/settings/rules/Mainrules.jsx';
import PaymentGateway from '../pages/settings/paymentGateway/PaymentGateway.jsx';
import KycRules from '../pages/settings/kycRules/KycRules.jsx';
import FAQs from '../pages/support/faq/Faq.jsx';
import IbApproved from "../pages/ibApproved/IbApproved.jsx";
import ParentTree from "../pages/client/components/ParentTree.jsx"
import IbTree from "../pages/client/components/IbTree.jsx"
import IBAccept from "../pages/ibAccept/IBAccept.jsx";
import ChangePassword from '../pages/client/components/ChangePassword.jsx'
import DepositUser from '../pages/client/components/Deposit.jsx'
import InternalTransferuser from '../pages/client/components/InternalTransfer.jsx'
import Logs from '../pages/client/components/Logs.jsx'
import MT5Account from '../pages/client/components/MT5Account.jsx'
import MT5LoginDetails from '../pages/client/components/MT5LoginDetails.jsx'
import SendCredentials from '../pages/client/components/SendCredentials.jsx'
import SendNotification from '../pages/client/components/SendNotification.jsx'
import Withdrawuser from '../pages/client/components/Withdraw.jsx'
import DepositUSDT from "../pages/client/components/DepositUSDT.jsx";
import Inbox from '../pages/inbox/Inbox.jsx';
import AddEmailTemplate from '../pages/emailTemplate/components/AddEmailTemplate.jsx';
import EditEmailTemplate from '../pages/emailTemplate/components/EditEmailTemplate.jsx';
import IbDetails from '../pages/ibApproved/components/IbDetails.jsx';
import PromotionKit from '../pages/promotionKit/PromotionKit.jsx';
import Reports from '../pages/ibCommission/reports/Reports.jsx';
import Rolback from '../pages/ibCommission/rolback/Rolback.jsx';
import Teams from '../pages/settings/teams/Teams.jsx';
import Tutorial from '../pages/tutorial/Tutorial.jsx';
import Lead from '../pages/salesDashboard/lead/Lead.jsx';
import Source from '../pages/salesDashboard/source/Source.jsx';
import SalesDashboard from '../pages/salesDashboard/dashboard/Dashboard.jsx';
import Subadmin from '../pages/SubAdmins/Subadmin.jsx';
import BankDetails from '../pages/cashDeposite/bankDetails/BankDetails.jsx';
import DepositRequest from '../pages/cashDeposite/request/DepositRequest.jsx';
import UsdConversion from "../pages/cashDeposite/usdConversion/UsdConversion.jsx"
import Configuration from "../pages/settings/configuration/Configuration.jsx";
import Commissiondata from "../pages/Reports/Commissiondata.jsx";
import DepositReminderLog from "../pages/DepositReminderLog/DepositReminderLog.jsx"
import TerminalOnlineUsers from "../pages/TerminalOnlineUsers/TerminalOnlineUsers.jsx"
import InactiveAccounts from "../pages/accounts/InactiveAccounts.jsx"
import IbHandler from '../pages/ibApproved/components/IbHandler.jsx';
import Symbol from "../pages/settings/symbol/Symbol.jsx"
import SymbolDetai from "../pages/settings/symbol/SymbolDetail.jsx"


export const initialRoutes = () => {
    const routes = [
        // ============ Auth ===============
        { path: "/admin_login", type: "auth", component: AdminLogin },
        { path: "/", type: "auth", component: Login },
        { path: "/otp", type: "auth", component: UserOtp },
        { path: "/forget_password", type: "auth", component: ForgetPassword },
        { path: "/reset_password", type: "auth", component: ResetPassword },
        { path: "/forgotpassword_otp", type: "auth", component: ForgetPasswordotp },
        // ============ Public =============
        // all public routes should be here
        // ============ Privet ==============
        { path: "/dashboard", type: "private", component: Dashboard, title: "Dashboard" },
        { path: "/client", type: "private", component: Client, title: "Client" },
        {
            path: "/client_details/:id", type: "private", component: ClientDetails, title: "Client Details",
            parent: [{ title: 'Client', path: '/client' }]
        },
        {
            path: "/account_details/:loginId/:id", type: "private", component: LiveAccountDetails, title: "Account Details",
            parent: [{ title: 'Client', path: '/client' }, { title: "Client Details", path: "/client_details/:id" }]
        },
        { path: "/kyc", type: "private", component: Kyc, title: "Kyc" },
        { path: "/terminal_Online_Users", type: "private", component: TerminalOnlineUsers, title: "Terminal Online Users" },
        { path: "/inactive_Accounts", type: "private", component: InactiveAccounts, title: "Inactive Accounts" },
        { path: "/accounts", type: "private", component: Accounts, title: "Accounts" },
        { path: "/ib_manager", type: "private", component: IbApproved, title: "IB Manager" },
        { path: "/ib_details/:id", type: "private", component: IbDetails, title: "IB Details", parent: [{ title: 'IB Manager', path: '/ib_manager?filter=Approved' }] },
        { path: "/ib_handler/:id", type: "private", component: IbHandler, title: "Update IB", parent: [{ title: 'IB Manager', path: '/ib_manager?filter=Approved' }] },
        { path: "/ib_accept/:id", type: "private", component: IBAccept, title: "IB Accept" },
        { path: "/ib_commission_generate", type: "private", component: GenerateCommission, title: "Generate Commission" },
        { path: "/ib_commission/:id", type: "private", component: IBCommission, title: "IB Commission", parent: [{ title: 'Generate Commission', path: '/ib_commission_generate' }] },
        { path: "/ib_commission_withdraw", type: "private", component: WithdrawCommission, title: "Withdraw Commission" },
        { path: "/ib_commission_reports", type: "private", component: Reports, title: "Commission Reports" },
        { path: "/ib_commission_rollback", type: "private", component: Rolback, title: "Commission Rollback" },
        { path: "/wallet_withdraw", type: "private", component: Withdraw, title: "Withdraw" },
        { path: "/wallet_deposit", type: "private", component: Deposit, title: "Deposit" },
        { path: "/wallet_internal_transfer", type: "private", component: InternalTransfer, title: "Internal Transfer" },
        { path: "/sub_admin", type: "private", component: Subadmin, title: "Sub Admins" },
        { path: "/email_template", type: "private", component: EmailTemplate, title: "Email Template" },
        { path: "/add_email_template", type: "private", component: AddEmailTemplate, title: "Add Email Template", parent: [{ title: 'Email Template', path: '/email_template' }] },
        { path: "/edit_email_template/:id", type: "private", component: EditEmailTemplate, title: "Edit Email Template", parent: [{ title: 'Email Template', path: '/email_template' }] },
        { path: "/task", type: "private", component: Task, title: "Task" },
        { path: "/banner", type: "private", component: Banner, title: "Banner" },
        { path: "/symbol_detail/:id", type: "private", component: SymbolDetai, title: "Symbol Detail" },
        
        { path: "/roles", type: "private", component: Roles, title: "Roles" },
        { path: "/teams", type: "private", component: Teams, title: "Manage Team" },
        { path: "/ticket", type: "private", component: Ticket, title: "Ticket" },
        { path: "/tutorial", type: "private", component: Tutorial, title: "Tutorial" },
        { path: "/view_ticket/:id", type: "private", component: ViewTicket, title: "View Ticket", parent: [{ title: 'Ticket', path: '/ticket' }] },
        { path: "/faq", type: "private", component: Faq, title: "Faq" },
        { path: "/promotion_kit", type: "private", component: PromotionKit, title: "Promotion Kit" },
        { path: "/account_Type", type: "private", component: AccountType, title: "Account Type" },
        { path: "/inbox", type: "private", component: Inbox, title: "Inbox" },
        { path: "/notifications", type: "private", component: Notifications, title: "Notifications" },
        { path: "/rules", type: "private", component: Rules, title: "Rules" },
        { path: "/payment_Gateway", type: "private", component: PaymentGateway, title: "Payment Gateway" },
        { path: "/kyc_Rules", type: "private", component: KycRules, title: "KycRules" },
        { path: "/faq", type: "private", component: FAQs, title: "FAQs" },
        {
            path: "/parent_tree/:id", type: "private", component: ParentTree, title: "Parent Tree",
            parent: [{ title: 'Client', path: '/client' }, { title: "Client Details", path: "/client_details/:id" }]
        },
        { path: "/ib_tree/:id", type: "private", component: IbTree, title: "Ib Tree", parent: [{ title: 'Client', path: '/client' }, { title: "Client Details", path: `/client_details/:id` }] },
        { path: "/change_password", type: "private", component: ChangePassword, title: "Change Password" },
        { path: "/internal_transfer/:id", type: "private", component: InternalTransferuser, title: "Internal Transferuser" },
        {
            path: "/deposit/:id", type: "private", component: DepositUser, title: "Deposit",
            parent: [{ title: 'Client', path: '/client' }, { title: "Client Details", path: "/client_details/:id" }]
        },
        {
            path: "/deposit_usdt/:paymentId/:id", type: "private", component: DepositUSDT, title: "Deposit USDT",
            parent: [{ title: 'Client', path: '/client' }, { title: "Client Details", path: "/client_details/:id" }, { title: "Deposit", path: "/deposit/:id" }]
        },
        { path: "/internal_transfer", type: "private", component: InternalTransferuser, title: "Internal Transferuser" },
        {
            path: "/logs/:id", type: "private", component: Logs, title: "Logs",
            parent: [{ title: 'Client', path: '/client' }, { title: "Client Details", path: "/client_details/:id" }]
        },
        {
            path: "/mt5_account/:id", type: "private", component: MT5Account, title: "MT5Account",
            parent: [{ title: 'Client', path: '/client' }, { title: "Client Details", path: "/client_details/:id" }]
        },
        { path: "/mt5_login_details", type: "private", component: MT5LoginDetails, title: "MT5Login Details" },
        { path: "/send_credentials", type: "private", component: SendCredentials, title: "Send Credentials" },
        { path: "/send_notification", type: "private", component: SendNotification, title: "Send Notification" },
        {
            path: "/withdraw/:id", type: "private", component: Withdrawuser, title: "Withdraw",
            parent: [{ title: 'Client', path: '/client' }, { title: "Client Details", path: "/client_details/:id" }]
        },
        { path: "/sales_dashboard", type: "private", component: SalesDashboard, title: "Sales Dashboard" },
        { path: "/source", type: "private", component: Source, title: "Source" },
        { path: "/leads", type: "private", component: Lead, title: "Lead" },
        { path: "/bank_details", type: "private", component: BankDetails, title: "Bank Details" },
        { path: "/deposit_request", type: "private", component: DepositRequest, title: "Deposit Request" },
        { path: "/usd_conversion", type: "private", component: UsdConversion, title: "Usd Conversion" },
        { path: "/thres_hold", type: "private", component: Configuration, title: "Thres hold" },
        { path: "/symbol", type: "private", component: Symbol, title: "Symbol" },
        { path: "/thres_hold_reports", type: "private", component: Commissiondata, title: "Thres hold Reports" },
        { path: "/log", type: "private", component: DepositReminderLog, title: "Log" },
        
        
    ]
    return routes;
}

// export default initialRoutes