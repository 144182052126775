const columns = [
    {
        title: 'Account No.',
        dataIndex: 'login',
        // sorter: (a, b) => a.login - b.login,
    },
    {
        title: 'Account Type',
        dataIndex: 'acType',
        // sorter: (a, b) => a?.acType?.localeCompare(b?.acType),
    },
    {
        title: 'Balance',
        dataIndex: 'balance',
      },
      {
        title: 'Leverage',
        dataIndex: 'leverage',
        // sorter: (a, b) => a?.leverage - b?.leverage,
      },
    {
        title: 'Platform Type',
        dataIndex: 'groupName',
        // sorter: (a, b) => a?.groupName?.localeCompare(b?.groupName),
    },
    {
        title: 'Create Date',
        dataIndex: 'createdDate',
        // sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
    },
];

export default columns;