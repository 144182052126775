import React, { useState} from "react";
import Segmented from "../../../components/segmented/Segmented.jsx";
import Account from "./Account.jsx"
import Rulesall from "./Rules.jsx"

export default function Rules() {
  const [value, setValue] = useState("All Rules");

  return (
    <div>
      <div className="item-center flex-wrap gap-5">
      <Segmented
          options={["All Rules","Wallet", "Deposit", "Account","Internal Transfer", "Withdraw","IB Wallet Withdrawal"]}
          value={value}
          setValue={setValue}
        />
      </div>
      {value==="Account"?<Account allfilter={value}/>:<Rulesall allfilter={value}/>}
    </div>
  );
}
