import { render } from "@testing-library/react";

const defaultRender = (value) => (value ? value : '--');
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        render:defaultRender,
        sorter:true
    },
    {
        title: 'Email',
        dataIndex: 'email',
        sorter:true
    },
    {
        title: 'Mobile No.',
        dataIndex: 'phoneNo',
        sorter:true
    },
    {
        title: 'Wallet Account Number',
        dataIndex: 'walletAccountNo',
        sorter:true
    },
    {
        title:'Balance',
        dataIndex: 'balance',
        sorter:true
    },
    {
        title:'Amount',
        dataIndex: 'requestedAmount',
        sorter:true
    },
    {
        title:'Comment',
        dataIndex: 'comment',
        sorter:true
    },
    // {
    //     title:'Document',
    //     dataIndex: 'documentUrl',
    // },
    {
        title:'Request Date',
        dataIndex: 'requestDate',
        sorter:true
    },
    {
        title:'Status',
        dataIndex: 'status',
        sorter:true
    },
    {
        title:'Actions',
        dataIndex: 'actions',
    },
    
   
];


export default columns;