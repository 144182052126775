const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        // sorter: (a, b) => a.name - b.name,
    },
    {
        title: 'Email',
        dataIndex: 'primaryEmail',
        // sorter: (a, b) => a.primaryEmail - b.primaryEmail,
    },
    {
        title: 'Lots',
        dataIndex: 'lot',
        // sorter: (a, b) => a.lots - b.lots,
    },
    {
        title: 'Commission',
        dataIndex: 'commissionAmount',
        // sorter: (a, b) => a?.commissionAmount?.localeCompare(b?.commissionAmount),
    },
    {
        title: 'Created Date',
        dataIndex: 'transactionDate',
        // sorter: (a, b) => new Date(a.transactionDate) - new Date(b.transactionDate),
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        fixed:'right'
    }
];


export default columns;