import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Select, Input, Switch, Radio, Spin, Tooltip } from "antd";
import columns from "../../columns/Client.js";
import TableWithSkeleton from "../../components/global/table/TableWithSkeleton.jsx";
import ModalComponent from "../../components/global/modal/ModalComponent.jsx";
import LoadableButton from "../../components/buttons/LoadableButton.jsx";
import Pagination from "../../components/global/pagination/Pagination.jsx";
import { AddTraderApi, GetAllTraderApi, UpdateUserStatusApi, Downloadclientdetails } from "../../api/request/client.js";
import { GetAllRole } from "../../api/request/settings/role.js"
import { GetAllCountryApi } from "../../api/request/common.js";
import Segmented from "../../components/segmented/Segmented.jsx";
import toast from "react-hot-toast";
import moment from "moment";
import Eye from "../../assets/Eye.jsx";
import Download from "../../assets/Download.jsx";
import ConfirmationModal from "../../components/global/modal/ConfirmationModal.jsx";
import ExportHandler from "../../components/global/export/ExportHandler.jsx";
import { Cookies } from "react-cookie";
import CryptoJS from 'crypto-js'
import Redirect from "../../assets/Redirect.jsx";

export default function Client() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [value, setValue] = useState("All Clients");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isAddTraderLoading, setIsAddTraderLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [countryFilter, setCountryFilter] = useState([]);
  const [kycFilter, setKycFilter] = useState([]);
  const [ftdFilter, setFtdFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countriesForFilter, setCountriesForFilter] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
  const [isStatusConfirmLoading, setIsStatusConfirmLoading] = useState(false);
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    countryId: null,
    role: null,
    referralCode: "",
    autoKyc: "",
  });


  let aditionColumns = [
    {
      title: 'Country',
      dataIndex: 'countryName',
      filters: countriesForFilter,
      filterSearch: true,
      onFilter: (value, record) => { return record.countryName },
      filteredValue: countryFilter ? countryFilter : null,
      render: (text) => text || '--',
    },
    {
      title: 'KYC',
      dataIndex: 'isKyc',
      filters: [{ text: 'Completed', value: 'KYCCompleted' }, { text: 'Pending', value: 'KYCPending' }],
      onFilter: (value, record) => { return record.isKyc },
      filteredValue: kycFilter ? kycFilter : null,
      render: (text) => text || '--',
    },
    {
      title: 'FTD',
      dataIndex: 'isDeposit',
      filters: [{ text: 'Completed', value: 'FTDCompleted' }, { text: 'Pending', value: 'FTDPending' }],
      onFilter: (value, record) => { return record?.isDeposit },
      filteredValue: ftdFilter ? ftdFilter : null,
      render: (text) => text || '--',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: "right"
    }
  ];

  const handleFilterChange = (pagination, filters, sorter) => {
    setKycFilter(filters.isKyc ? filters.isKyc : []);
    setFtdFilter(filters.isDeposit ? filters.isDeposit : []);
    setCountryFilter(filters.countryName ? filters.countryName : []);
  };

  const statusChangeConfirm = async () => {
    try {
      setIsStatusConfirmLoading(true);
      let params = {
        userId: selectedUser?.userId,
        isActive: !selectedUser?.isActive
      }
      const { data } = await UpdateUserStatusApi(params);
      if (data?.success) {
        getAllTrader();
        toast.success(data?.message);
      }
      setIsStatusConfirmLoading(false);
      setIsStatusConfirmOpen(false);
    } catch (error) {
      setIsStatusConfirmLoading(false);
    }
  }

  // const Downloaddetails = async (id) => {
  //   try {
  //     setIsDownloadLoading(true);

  //     const { data } = await Downloadclientdetails(id);
  //     if (data?.success) {
  //       toast.success(data?.message);
  //     }
  //     setIsDownloadLoading(false);
  //   } catch (error) {
  //     setIsDownloadLoading(false);
  //   }
  // }

  const statusHandler = async (user) => {
    setSelectedUser(user);
    setIsStatusConfirmOpen(true);
  }

  const handleLogin = async (user) => {
    const cookies = new Cookies();
    const { token } = cookies.getAll();
    var message = JSON.stringify({ token, user }); // Replace with your actual message
    var key = process.env.REACT_APP_SECRET_KEY; // Replace with your actual key
    var encrypted = CryptoJS.AES.encrypt(message, key);
    var safeCiphertext = encrypted.toString()
      .replace('+', 'xMl3Jk')
      .replace('/', 'Por21Ld')
      .replace('=', 'Ml32');
    console.log(encrypted.toString());

    var originalCiphertext = safeCiphertext
      .replace('xMl3Jk', '+')
      .replace('Por21Ld', '/')
      .replace('Ml32', '=');
    console.log(originalCiphertext)
    const redirectUrl = `https://cabinet.neptunefxcrm.com/adminlogin?token=${encodeURIComponent(originalCiphertext)}`
    window.open(redirectUrl);
  }

  const getAllTrader = async () => {
    try {
      setIsLoading(true);
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        UserType: value === "IB Clients" ? "IB" : value === "User Clients" ? "User" : '',
        KYCType: kycFilter,
        FTDType: ftdFilter,
        CountryName: countryFilter,
        SearchText: searchText.trim(),
      };
      const { data } = await GetAllTraderApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              name: `${item?.firstName} ${item?.lastName}`,
              createdDate: (item?.createdDate ? moment(item?.createdDate).format("DD-MM-YYYY, hh:mm A") : null),
              isActive: (item?.isActive ? (item?.user ? item?.user : 'Yes') : 'No'),
              actions: (
                <div className="flex justify-center">
                  <Tooltip title="More Info">
                  <button type="button" onClick={() => navigate(`/client_details/${item?.userId}`)}><Eye /></button>
                  </Tooltip>
                  <Tooltip title="Download PDF">
                    <button className={`ml-4 ${item?.isKyc === "No" && 'opacity-50'}`} type="button"
                     onClick={() => window.open(`${item?.documentUrl}`, '_blank')} 
                     title="Download client details"
                     
                     disabled={item?.isKyc === "No"} 
                     > <Download disabled={item?.isKyc === "No"} /> </button>
                  </Tooltip>
                  <Tooltip title="Login User">
                    <button className={`ml-4 ${!item?.isActive && 'opacity-50'}`} disabled={!item?.isActive} type="button" onClick={() => handleLogin(item)} title="Download client details"> <Redirect /> </button>
                  </Tooltip>
                </div>
              ),
              status: (<Switch onChange={() => statusHandler(item)} checkedChildren="On" unCheckedChildren="Off" checked={item?.isActive} />),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getContries = async () => {
    try {
      const { data } = await GetAllCountryApi();
      if (data?.success) {
        setCountries(
          data?.data?.map((item) => ({
            ...item,
            value: item?.id,
            label: item?.countryName,
          }))
        );
        setCountriesForFilter(
          data?.data?.map((item) => ({
            text: item?.countryName,
            value: item?.countryName,
          }))
        );
      }
    } catch (error) { }
  };

  const getRoles = async () => {
    try {
      const { data } = await GetAllRole();
      if (data?.success) {
        setRoles(
          data?.data?.map((item) => ({
            ...item,
            value: item?.id,
            label: item?.name,
          }))
        );
      }
    } catch (error) { }
  };

  useEffect(() => {
    getAllTrader();
  }, [page, pageSize, ftdFilter, kycFilter,searchText, countryFilter, value]);

  useEffect(() => {
    setPage(1);
  }, [searchText, ftdFilter, kycFilter, countryFilter, value]);

  useEffect(() => {
    if (searchParams?.get("filter")?.trim()) {
      setValue(searchParams?.get("filter")?.trim());
    }
    getContries();
  }, []);

  useEffect(() => {
    setSearchParams(`filter=${value?.toString()}`);
  }, [value]);

  useEffect(() => {
    form.resetFields();
  }, [isOpenModal]);

  const handleSubmit = async (value) => {
    try {
      setIsAddTraderLoading(true);
      const { data } = await AddTraderApi(value);
      if (data?.success) {
        toast.success(data?.message);
        form.resetFields();
        getAllTrader();
        setIsAddTraderLoading(false);
        setIsOpenModal(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsAddTraderLoading(false);
    }
  };

  const filterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const exportHandler = async () => {
    try {
      let params = {
        ApplyPagination: false,
        UserType: value === "IB Clients" ? "IB" : value === "User Clients" ? "User" : '',
        KYCType: kycFilter,
        FTDType: ftdFilter,
        CountryName: countryFilter,
        SearchText: searchText.toLowerCase().replace(/\s/g, '')
      };
      const { data } = await GetAllTraderApi(new URLSearchParams(params).toString());
      if (data?.success) {
        return { data: data?.data, removableColumns: ['userId'], fileName: 'Clients' };
      } else {
        return { data: [], removableColumns: [] };
      }
    } catch (error) {
      return { data: [], removableColumns: [] };
    }
  };

  return (
    <div className="max-w-full">
      <div className="flex justify-between flex-wrap gap-5">
        <div className="ant-select-selector-white ant-multi-select-selector">
          <Segmented
            options={[
              "All Clients",
              "IB Clients",
              "User Clients",
            ]}
            value={value}
            setValue={setValue}
          />
        </div>
        <div className="flex items-center flex-wrap gap-4">
          <Input
            size="large"
            style={{ width: 220 }}
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onPressEnter={() => setSearchText(search)}
            prefix={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-5 h-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            suffix={
              search && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    setSearchText("");
                    setSearch("");
                  }}
                  viewBox="0 0 24 24"
                  fill="#bebebe"
                  className="size-5 cursor-pointer duration-300 hover:fill-[#969595]"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                    clip-rule="evenodd"
                  />
                </svg>
              )
            }
          />
          <div className="flex items-center gap-4">
            <button
              className="flex items-center bg-primary text-sm text-white font-semibold px-4 py-2 rounded-lg themeHover  duration-500"
              onClick={() => {
                getRoles();
                setIsOpenModal(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                  clip-rule="evenodd"
                />
              </svg>
              Add Users
            </button>
            <ExportHandler exportHandler={exportHandler} />
          </div>
        </div>
      </div>

      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">{value} <span className="primary">{isLoading ? (<Spin className="ml-2" size="small" />) : `(${totalRecord})`}</span></p>
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={[...columns, ...aditionColumns]} data={data} loading={isLoading} onChange={handleFilterChange} />
          <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
        </div>
      </div>

      {/* ---- Add user modal ---- */}
      <ModalComponent
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        title="Add User"
        width={1200}
      >
        <Form
          className="w-full"
          autoComplete="off"
          form={form}
          initialValues={initialValues}
          onFinish={handleSubmit}
        >
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">
                First Name
              </label>
              <Form.Item
                name="firstName"
                rules={[
                  { required: true, message: "Please enter first name." },
                ]}
              >
                <Input placeholder="First name" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">
                Last Name
              </label>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: "Please enter last name." }]}
              >
                <Input placeholder="Last name" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">
                Primary Email
              </label>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter valid email.",
                  },
                ]}
              >
                <Input
                  placeholder="Primary email"
                  className="mt-1 w-full py-2"
                />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">
                Phone No.
              </label>
              <Form.Item
                name="phoneNo"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid phone number.",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Phone no."
                  className="mt-1 w-full py-2"
                />
              </Form.Item>
            </div>
            <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">
                Country
              </label>
              <Form.Item
                name="countryId"
                rules={[
                  { required: true, message: "Please select a country." },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a country"
                  className="w-full mt-1"
                  filterOption={filterOption}
                  options={countries}
                />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">
                Referral Code
              </label>
              <Form.Item name="affiliateCode" rules={[]}>
                <Input
                  placeholder="Referral code"
                  className="mt-1 w-full py-2"
                />
              </Form.Item>
            </div>
            <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">
                Role
              </label>
              <Form.Item
                name="roleId"
                rules={[{ required: true, message: "Please select a role." }]}
              >
                <Select
                  placeholder="Select a role"
                  className="w-full mt-1"
                  options={[{ value: 2, label: "User" }, { value: 4, label: "IB User" }]}
                />
              </Form.Item>
            </div>
            <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">
                Auto Kyc
              </label>
              <Form.Item
                name="autoKyc"
                rules={[{ required: true, message: "Please select a role." }]}
              >
                <Radio.Group className="mt-3">
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-end mt-8">
            <LoadableButton
              className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
              disabled={isAddTraderLoading}
              type="Submit"
              lable="Submit"
              isLoading={isAddTraderLoading}
              loadingLable="Adding trader..."
            />
          </div>
        </Form>
      </ModalComponent>

      <ConfirmationModal
        isOpen={isStatusConfirmOpen}
        setIsOpen={setIsStatusConfirmOpen}
        message='Are you sure to change the user status?'
        onConfirm={statusChangeConfirm}
        isLoading={isStatusConfirmLoading}
        loadingLabel='Changing status...'
      />
    </div>
  );
}
