import React, { useEffect, useState } from 'react'
import BarChart from '../../../components/global/charts/BarChart.jsx';
import PieChart from '../../../components/global/charts/PieChart.jsx';
import StatisticsCard from './components/StatisticsCard.jsx';
import TotalUser from '../../../assets/dashboard/TotalUser.jsx';
import { Select, DatePicker, Spin } from 'antd';
import { presets } from '../../../utils/RangePickerPresets.js';
import { GetSalesDashboardApi } from '../../../api/request/salesDashboard/dashboard.js';
import { GetAllSourceApi } from '../../../api/request/salesDashboard/source.js';
import moment from 'moment';
const { RangePicker } = DatePicker;

export default function Dashboard() {

    const [sourceDateRange, setSourceDateRange] = useState({ fromDate: '', toDate: '' });
    const [sources, setSources] = useState([]);
    const [sourceData, setSourceData] = useState([]);
    const [leadsCount, setLeadsCount] = useState([]);
    const [activeSource, setActiveSource] = useState('');
    const [activeSourceColor, setActiveSourceColor] = useState('');
    const [activeSourceData, setActiveSourceData] = useState([]);
    const [isSourceLoading, setIsSourceLoading] = useState(true);
    const sourceOrder = ['new', 'attended', 'progress', 'positive', 'won', 'lost'];
    const [leadsDateRange, setLeadsDateRange] = useState({ fromDate: '', toDate: '' });
    const [leadsData, setLeadsData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [isLeadsLoading, setIsLeadsLoading] = useState(true);
    const [highestAndLowestSource, sethighestAndLowestSource] = useState({});

    const getAllSource = async () => {
        try {
            const { data } = await GetAllSourceApi();
            if (data?.success) {
                setSources(data?.data?.map((item) => ({ ...item, value: item?.name, label: item?.name })));
                setActiveSource(data?.data?.[0]?.name);
            }
        } catch (error) { }
    }

    useEffect(() => {
        getAllSource();
    }, []);

    const getObjectValuesInOrder = (obj, order) => {
        if (obj) {
            return order?.map(key => obj[key]);
        }
    }

    const findObjectBySourceName = (array, sourceName) => {
        return array?.find((obj) => obj.sourceName === sourceName);
    }

    const getSourceTotalCountInOrder = (array, order) => {
        // Create a map to easily access objects by their sourceName
        const dataMap = new Map(array?.map((item) => [item?.sourceName, item]));
        // Calculate the total count of all sourceTotalCounts
        const total = array?.reduce((sum, item) => sum + item?.sourceTotalCount, 0);
        // Extract the sourceTotalCount values in the specified order and calculate percentages
        const count = order?.map((source) => dataMap?.get(source)?.sourceTotalCount || 0);
        const percentage = count?.map((value) => Math?.round((value / total) * 100));
        return { count, percentage };
    }

    useEffect(() => {
        setActiveSourceData(getObjectValuesInOrder(findObjectBySourceName(sourceData, activeSource), sourceOrder));
        sethighestAndLowestSource(findHighestAndLowestSource(sourceData));
    }, [activeSource, sourceData]);

    useEffect(() => {
        let leadsOrder = sources?.slice(0, 5)?.map((source) => (source?.name));
        setLeadsCount(getSourceTotalCountInOrder(leadsData, leadsOrder));
    }, [leadsData]);

    const findHighestAndLowestSource = (array = []) => {
        if (array?.length === 0) return null;

        // Initialize variables to store the highest and lowest counts and their respective source names
        let highestSource = array[0];
        let lowestSource = array[0];

        // Calculate the total sum of sourceTotalCount
        const totalSum = array?.reduce((sum, source) => sum + source?.sourceTotalCount, 0);

        // Loop through the array to find the highest and lowest sourceTotalCount
        array.forEach((source) => {
            if (source?.sourceTotalCount > highestSource?.sourceTotalCount) {
                highestSource = source;
            }
            if (source?.sourceTotalCount < lowestSource?.sourceTotalCount) {
                lowestSource = source;
            }
        });

        // Calculate the percentages
        const highestSourcePercentage = Math?.round((highestSource?.sourceTotalCount / totalSum) * 100);
        const lowestSourcePercentage = Math?.round((lowestSource?.sourceTotalCount / totalSum) * 100);

        return {
            highestSourceCount: highestSource?.sourceTotalCount,
            highestSourcePercentage: highestSourcePercentage,
            highestSourceName: highestSource?.sourceName,
            lowestSourceCount: lowestSource?.sourceTotalCount,
            lowestSourcePercentage: lowestSourcePercentage,
            lowestSourceName: lowestSource?.sourceName,
        };
    }

    const getDashboardDetails = async (dateRange, handleLoading, setData) => {
        try {
            handleLoading(true);
            let params = {
                FromDate: dateRange?.fromDate,
                ToDate: dateRange?.toDate
            };
            const { data } = await GetSalesDashboardApi(new URLSearchParams(params).toString());
            if (data?.success) {
                setData(data?.data?.source);
                setTotalData(data?.data);
            }
            handleLoading(false);
        } catch (error) {
            handleLoading(false);
        }
    }

    useEffect(() => {
        getDashboardDetails(sourceDateRange, setIsSourceLoading, setSourceData);
    }, [sourceDateRange]);

    useEffect(() => {
        getDashboardDetails(leadsDateRange, setIsLeadsLoading, setLeadsData);
    }, [leadsDateRange]);

    const handleDateChange = (value, key) => {
        if (value && value?.length === 2) {
            const fromDate = value[0].format('MM/DD/YYYY');
            const toDate = value[1].format('MM/DD/YYYY');
            if (key == 'leads') {
                setLeadsDateRange({ fromDate: fromDate, toDate: toDate });
            } else {
                setSourceDateRange({ fromDate: fromDate, toDate: toDate });
            }
        }
        if (!value || value?.length === 0) {
            if (key == 'leads') {
                setLeadsDateRange({ fromDate: '', toDate: '' });
            } else {
                setSourceDateRange({ fromDate: '', toDate: '' });
            }
        }
    };

    const disabledFutureDate = (current, { from }) => {
        // Check if the date is a future date
        if (current && current > moment().endOf('day')) {
            return true;
        }
        return false;
    };

    const getColorCodeByName = (name) => {
        const foundObject = sources.find(item => item.name === name);
        return foundObject ? foundObject.colorCode : null;
    };

    useEffect(() => {
        setActiveSourceColor(getColorCodeByName(activeSource));
    }, [activeSource]);

    return (
        <div>
            <div className='mb-5 border-2 border-light rounded-lg'>
                <div className='px-4 pt-2'>
                    <div className='flex justify-between items-center'>
                        <p className='text-2xl font-bold'>Overview of Sales Performance</p>
                        <div className='ant-date-range-selector-white mt-2'>
                            <RangePicker disabledDate={disabledFutureDate} className='py-2' onChange={(value) => handleDateChange(value, 'leads')} presets={presets} format='DD-MM-YYYY' />
                        </div>
                    </div>
                    <p className='mt-3 text-slate-500'>Sales Dashboard: Track and manage your leads from multiple sources like Facebook, Email, and Telegram. Monitor the status of each lead through stages such as New, Attended, Progress, Positive, Won, and Lost for streamlined sales performance.</p>
                </div>
                {
                    isLeadsLoading ? (
                        <div className="flex justify-center flex-col my-[10%]">
                            <Spin size="large" />
                            <p className="primary text-center mt-2">Loading...</p>
                        </div>
                    ) : (
                        <div className="grid sm:grid-cols-2 xl:grid-cols-5 gap-5 sm:pr-4">
                            <div className="sm:col-span-2 flex justify-center gap-5">
                                <div className='flex justify-center items-center'>
                                    <PieChart values={leadsCount?.percentage} labels={sources?.slice(0, 5)?.map((source) => (source?.name))} colors={sources?.slice(0, 5)?.map((source) => (source?.colorCode))} />
                                </div>
                                <div className='mt-4'>
                                    {
                                        sources?.slice(0, 5)?.map((source, index) => (
                                            <div className='flex gap-3 items-center my-5' key={index}>
                                                <img className="h-[25px] w-[25px]" src={source?.sourceUrl} /><p>{source?.name}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="sm:col-span-3 w-full">
                                <BarChart data={{ categories: sources?.slice(0, 5)?.map((source) => (source?.name)), series: [{ name: 'Total Leads', data: leadsCount?.count || [] }] }} colors={sources?.slice(0, 5)?.map((source) => (source?.colorCode))} horizontal={true} distributed={true} dataLabels={true} height={300} />
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="border-2 border-light rounded-lg">
                <div className='grid sm:grid-cols-2 xl:grid-cols-3 gap-5'>
                    <div>
                        <p className='font-bold text-lg my-5 ms-5'>Overview of Leads</p>
                        <div className='grid sm:grid-cols-2 gap-5 p-2'>
                            <StatisticsCard title='New Leads' value={totalData?.totalNewLeads || 0} icon={<TotalUser />} />
                            <StatisticsCard title='Attended Leads' value={totalData?.totalAttendedLeads || 0} icon={<TotalUser />} secondary={true} />
                            <StatisticsCard title='Progress Leads' value={totalData?.totalProgressLeads || 0} icon={<TotalUser />} secondary={true} />
                            <StatisticsCard title='Positive Leads' value={totalData?.totalPositiveLeads || 0} icon={<TotalUser />} />
                            <StatisticsCard title='Won Leads' value={totalData?.totalWonLeads || 0} icon={<TotalUser />} />
                            <StatisticsCard title='Lost Leads' value={totalData?.totalLostLeads || 0} icon={<TotalUser />} secondary={true} />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <div className='flex items-center justify-between gap-3 mt-2 pe-5'>
                            <p className='font-bold text-lg'>Leads average comparison</p>
                            <div className='flex items-center gap-3'>
                                <div className='ant-date-range-selector-white mt-2'>
                                    <RangePicker disabledDate={disabledFutureDate} className='py-2' onChange={(value) => handleDateChange(value)} presets={presets} format='DD-MM-YYYY' />
                                </div>
                                <div className="ant-select-selector-white">
                                    <Select placeholder='Select source' className='w-[145px]' options={sources} onChange={(source) => setActiveSource(source)} value={activeSource} />
                                </div>
                            </div>
                        </div>
                        {isSourceLoading ? (
                            <div className="flex justify-center flex-col my-[12%]">
                                <Spin size="large" />
                                <p className="primary text-center mt-2">Loading...</p>
                            </div>) : (
                            <div className='sm:flex mt-0'>
                                <div className='sm:w-3/5 p-4'>
                                    <BarChart data={{ categories: ['New', 'Attended', 'Progress', 'Positive', 'Won', 'Lost'], series: [{ name: 'Total Leads', data: activeSourceData || [] }] }} colors={[activeSourceColor]} horizontal={true} dataLabels={true} height={280} />
                                </div>
                                <div className='sm:w-2/5 flex flex-col justify-center items-center'>
                                    <div className='bg-[#e6f1f3] px-2 py-4 rounded-xl'>
                                        <p className='font-bold text-lg mb-5 ms-5'>Latest Performance</p>
                                        <div className='flex flex-wrap items-center'>
                                            <div className='relative'>
                                                <PieChart values={[highestAndLowestSource?.highestSourcePercentage || 0, (100 - highestAndLowestSource?.highestSourcePercentage) || 0]} labels={['Highest']} colors={[getColorCodeByName(highestAndLowestSource?.highestSourceName), '#FFF']} width={150} height={150} tooltip={false} />
                                                <p className='absolute top-12 left-[35%] text-xs text-center w-[50px] font-bold' style={{ color: getColorCodeByName(highestAndLowestSource?.highestSourceName) }}>Highest {`(${highestAndLowestSource?.highestSourcePercentage || 0}%)`}</p>
                                                <p className='text-center font-bold text-xs' style={{ color: getColorCodeByName(highestAndLowestSource?.highestSourceName) }}>{`${highestAndLowestSource?.highestSourceName || "--"} (${highestAndLowestSource?.highestSourceCount || "--"})`}</p>
                                            </div>
                                            <div className='relative'>
                                                <PieChart values={[highestAndLowestSource?.lowestSourcePercentage || 0, (100 - highestAndLowestSource?.lowestSourcePercentage) || 0]} labels={['Lowest']} colors={[getColorCodeByName(highestAndLowestSource?.lowestSourceName), '#FFF']} width={150} height={150} tooltip={false} />
                                                <p className='absolute top-12 left-[35%] text-xs text-center w-[50px] font-bold' style={{ color: getColorCodeByName(highestAndLowestSource?.lowestSourceName) }}>Lowest {`(${highestAndLowestSource?.lowestSourcePercentage || 0}%)`}</p>
                                                <p className='text-center font-bold text-xs' style={{ color: getColorCodeByName(highestAndLowestSource?.lowestSourceName) }}>{`${highestAndLowestSource?.lowestSourceName || "--"} (${highestAndLowestSource?.lowestSourceCount || "--"})`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}