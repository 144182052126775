import React, { useEffect, useState } from 'react';
import './Sidebar.scss';
import { SidebarData } from './sidebarData/SideabrData.js';
import { useNavigate, useLocation } from 'react-router-dom';
import Avatar from 'react-avatar';
import { Sidebar, Menu, SubMenu } from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import ConfirmationModal from "../modal/ConfirmationModal.jsx";
import { removeCookies } from "../../../services/Cookies.js";
import { setloggedIn } from "../../../store/Slices/userSlice.js";
import { LogoutApi, ClearNotificationApi, GetUserPermissionApi, GetNotificationApi } from "../../../api/request/users";
import Bellicon from '../../../assets/Bellicon.jsx';
import { Drawer, Badge } from 'antd';
import Profile from '../../../pages/profile/Profile.jsx';
import Notifications from '../notifications/Notifications.jsx';
import rolesSidebar from './sidebarData/rolesData.js';
import * as signalR from '@microsoft/signalr';
import { Cookies } from "react-cookie";
import { IpProvider } from '../../../services/IpProvider.js';

export default function UserSidebar() {

    const dispatch = useDispatch();
    const cookies = new Cookies();
    const { token } = cookies.getAll();
    const userDetails = useSelector((state) => state.user.userDetails);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
    const [isNotification, setIsNotification] = useState(false);
    const [notifications, setNotifications] = useState({ isReadCount: 0, unReadCount: 0, totalMessageCount: 0, details: [] });
    const [isfetchNotification, setIsFetchNotification] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false)
    const [modulesPermission, setModulesPermission] = useState([]);
    console.log(modulesPermission,"modulesPermission");
    
    const [connection, setConnection] = useState('');
    const [ipAddress, setIpAddress] = useState('');

    function initializeSignalRConnection() {
        try {
            if (!connection) {
                const newConnection = new signalR.HubConnectionBuilder()
                    .withUrl("https://mt5.neptunefxcrm.com/chatHub", { accessTokenFactory: () => token })
                    .withAutomaticReconnect()
                    .build()

                setConnection(newConnection);
            }
        } catch (error) { }
    }

    useEffect(() => {
        if (isfetchNotification) {
            initializeSignalRConnection();
        }
    }, [isfetchNotification]);


    useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {  })
                .catch(err => console.error(err.toString()));

            connection.on("ReceiveMessage", (data) => {
                setNotifications(prevState => ({
                    ...prevState,
                    isReadCount: prevState.isReadCount,
                    unReadCount: prevState.unReadCount + 1,
                    totalMessageCount: prevState.totalMessageCount + 1,
                    details: prevState?.details?.length ? [...prevState?.details, data] : [data]
                }));
            });
        }
    }, [connection]);

    const getNotification = async () => {
        try {
            const { data } = await GetNotificationApi();
            if (data?.success) {
                setNotifications(data?.data);
            }
            setIsFetchNotification(true);
        } catch (error) { setIsFetchNotification(true); }
    }

    const getIpAddress = async () => {
        try {
            const { data } = await IpProvider();
            setIpAddress(data?.ip);
        } catch (error) { }
    }

    useEffect(() => {
        getNotification();
        getIpAddress();
    }, []);

    const handleMenuClick = (index) => { setOpenSubMenuIndex(index); };

    function getFirstPath(path) {
        const parts = path.split('/');
        return parts.length > 1 ? '/' + parts[1] : path;
    }

    const isActivePath = (path) => {
        let active = false;
        path?.map((item) => {
            if (item == getFirstPath(pathname)) {
                active = true;
            }
        })
        return active;
    }

    const logoutHandler = async () => {
        try {
            setIsLoading(true);
            const { data } = await LogoutApi({ ipAddress });
            if (data?.success) {
                toast.success(data?.message);
            }
            localStorage.clear();
            dispatch(setloggedIn(false));
            removeCookies('token');
            navigate('/');
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.error?.message) {
                toast.error(error?.response?.data?.error?.message);
            }
            localStorage.clear();
            dispatch(setloggedIn(false));
            removeCookies('token');
            navigate('/');
            setIsLoading(false);
        }
    }

    const clearNotifications = async () => {
        try {
            const { data } = await ClearNotificationApi();
            if (data.success) {
                setNotifications([])
                toast.success(data.message);
            }
        } catch (error) {
            if (error?.response?.data?.error?.message) {
                toast.error(error?.response?.data?.error?.message);
            }
        }
    };

    const getPermission = async () => {
        if (!userDetails?._id) {
            return;
        }
        try {
            if (userDetails?.roleId == 1) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
                const { data } = await GetUserPermissionApi(userDetails?._id);
                if (data?.success) {
                    let modulePermissions = [];
                    data?.data?.map((item, index) => {
                        if (item?.haveModulePermission) {
                            modulePermissions.push(rolesSidebar[item?.moduleName])
                        }
                    })
                    setModulesPermission(modulePermissions);
                }
            }
        } catch (error) { }
    }

    useEffect(() => {
        getPermission();
    }, [userDetails?._id]);

    const isUrlMatched = (targetUrl) => {
        return modulesPermission.some(item => item.url === targetUrl);
    }

    useEffect(() => {
        if (!isUrlMatched(getFirstPath(pathname))) {
            navigate(modulesPermission[0]?.url);
        }
    }, [modulesPermission]);

    return (
        <div className='sidebar-container w-[250px] py-3 fixed'>
            <div className='h-[70px] px-4'>
                <div className='flex items-center justify-between pt-4 pb-6 header'>
                    <img src='/assets/logo/logo.png' alt='logo' className='h-[30px] object-contain' />
                    <div className='cursor-pointer' onClick={() => setIsNotification(true)}>
                        <Badge count={notifications?.unReadCount || 0} overflowCount={5}>
                            <Bellicon />
                        </Badge>
                    </div>
                </div>
                <hr className='border-[#8D5AE226] mx-2 pb-2' />
            </div>

            <div className='h-[calc(100vh-172px)] overflow-y-auto pl-4'>
                {
                    (isAdmin ? SidebarData : modulesPermission).map((item, index) => {
                        const iconWithProps = React.cloneElement(item?.icon, { active: isActivePath(item?.activeUrl) })
                        return item?.children ? (
                            <Sidebar>
                                <Menu>
                                    <SubMenu key={index} open={openSubMenuIndex == index} onOpenChange={() => handleMenuClick(index == openSubMenuIndex ? null : index)} label={
                                        <div key={index} className={`flex items-center text-[#2F2B3DB2] font-medium cursor-pointer py-2 px-6 header`}>
                                            {item?.icon}
                                            <p className='text-base ms-3'>{item?.title}</p>
                                        </div>
                                    }>
                                        <div className='pt-1'>
                                            {
                                                item?.children?.map((childItem, index) => {
                                                    const childIconWithProps = React.cloneElement(childItem?.icon, { active: isActivePath(childItem?.activeUrl) })
                                                    return <div key={index} onClick={() => navigate(childItem?.url)} className={` submenu child flex items-center text-[#2F2B3DB2] cursor-pointer font-medium py-2 ms-5 ps-4 pe-6 mr-4 duration-200 ${index != 0 ? 'mt-1' : null} ${isActivePath(childItem?.activeUrl) ? 'active_icon primary text-white rounded-lg font-semibold' : null}`}>
                                                        {childIconWithProps}
                                                        <p className='ms-2'>{childItem?.title}</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </SubMenu>
                                </Menu>
                            </Sidebar>
                        ) : (
                            <div key={index} onClick={() => { navigate(item?.url); handleMenuClick(index) }} className={` header flex items-center text-[#2F2B3DB2] font-medium cursor-pointer my-3 py-2 px-6 mr-4 duration-200 ${isActivePath(item?.activeUrl) ? 'active_icon bg-primary text-white rounded-lg font-semibold' : null}`}>
                                {/* <img src={item?.icon} alt='icon' className='mr-4 h-[24px] w-[24px]' /> */}
                                {iconWithProps}
                                <p className='text-base ms-2'>{item?.title}</p>
                            </div>
                        )
                    })
                }
            </div>

            <div className='px-4 mt-6'>
                <div className='flex items-center justify-between cursor-pointer' >
                    <div className='flex items-center' onClick={e => setIsDrawerOpen(true)}>
                        <Avatar size={46} src={userDetails.profileImage} className='rounded-full' />
                        <div className='ml-2'>
                            <p className='font-semibold text-base line-clamp-1'>{userDetails.fullName}</p>
                            <p className='text-sm primary'>{userDetails.roleName}</p>
                        </div>
                    </div>
                    <button className='text-sm text-red-600 font-semibold px-6 py-2 flex items-center gap-3' onClick={() => setIsModalOpen(true)}>
                        <img src='/assets/icons/_logout.svg' className='h-5' alt='icon' />
                        {/* <p>Logout</p> */}
                    </button>
                </div>
            </div>

            {/* --- Loguot confirmation modal --- */}
            <ConfirmationModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                message='Are you sure you want to logout?'
                onConfirm={logoutHandler}
                isLoading={isLoading}
                loadingLabel='Loging out...'
            />

            {/* --- admin profile drawer --- */}
            <Drawer title={
                <div className='flex justify-between items-center'>
                    <p>Profile</p>
                    <button className='text-sm text-red-600 font-semibold px-6 py-2 flex items-center gap-3' onClick={() => setIsModalOpen(true)}>
                        <img src='/assets/icons/_logout.svg' className='h-5' alt='icon' />
                        <p>Logout</p>
                    </button>
                </div>
            } onClose={e => setIsDrawerOpen(false)} width={600} open={isDrawerOpen}>
                <Profile isAdmin={isAdmin} />
            </Drawer>

            {/* --- Notification drawer --- */}
            <Drawer
                title={
                    <div className="flex justify-between items-center">
                        <p>Notification</p>
                        <p className='cursor-pointer' onClick={clearNotifications} >Clear all</p>
                    </div>
                }
                onClose={(e) => setIsNotification(false)}
                width={600}
                open={isNotification}
            >
                <Notifications notifications={notifications} />
            </Drawer>
        </div>
    )
}
