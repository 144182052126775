import React, { useState, useEffect } from 'react';
import Bellicon from '../../../assets/Bellicon.jsx';
import ModalComponent from '../../global/modal/ModalComponent.jsx';
import moment from 'moment/moment.js';
import { MarkAsReadNotificationApi } from '../../../api/request/users.js';

export default function Notifications({ notifications }) {

    const [selectedNotification, setSelectedNotification] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationData, setNotificationData] = useState(notifications?.details || []);

    const viewNotification = (notification) => {
        setSelectedNotification(notification);
        setIsOpen(true);
    }

    const markAsRead = async (id) => {
        try {
            let params = { IsRead: true };
            const { data } = await MarkAsReadNotificationApi(id, new URLSearchParams(params).toString());
            if (data?.success) {
                let updatedNotifications = notificationData?.map((item) => {
                    if (item?.userNotificationId == id) {
                        return { ...item, isRead: true }
                    } else {
                        return item;
                    }
                })
                setNotificationData(updatedNotifications);
            }
        } catch (error) { }
    }

    useEffect(() => {
        setNotificationData(notifications?.details);
    }, [notifications]);

    return (
        <div>
            {
                notificationData?.length > 0 ? (
                    <div>
                        {
                            notificationData?.map((item, index) => (
                                <div className={`flex gap-4 border-b p-5 cursor-pointer ${item?.isRead ? '' : 'bg-slate-100'}`} key={index}
                                    onClick={() => {
                                        viewNotification(item);
                                        if (item.isRead === false) {
                                            markAsRead(item?.userNotificationId);
                                        }
                                    }}>
                                    <Bellicon />
                                    <div className="w-full">
                                        <div className="flex justify-between items-center w-full">
                                            <p className="font-semibold text-base line-clamp-1 w-2/3">{item?.title}</p>
                                            <p className="text-sm text-gray-400">{moment(item?.createdDate).fromNow()}</p>
                                        </div>
                                        <p className="mt-1 text-gray-500 line-clamp-1" dangerouslySetInnerHTML={{ __html: item?.message }}></p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ) : (
                    <div className='mt-12'>
                        <p className='text-gray-400 text-center text-lg'>No Notification</p>
                    </div>
                )
            }


            <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} title='Notification' width={800}>
                <div className="flex justify-between items-center w-full">
                    <p className='font-semibold text-xl mb-4'>{selectedNotification?.title}</p>
                    <p className="text-sm text-gray-400 text-right mb-4" > {moment(selectedNotification?.createdDate).fromNow()}</p >
                </div>
                <p className="text-lg" dangerouslySetInnerHTML={{ __html: selectedNotification?.message }}></p>
            </ModalComponent >
        </div >
    )
}
