import Account from "../../../../assets/Account";
import AccountType from "../../../../assets/AccountType";
import Disc from "../../../../assets/common/Disc";
import EmailTemplate from "../../../../assets/EmailTemplate";
import IbApproved from "../../../../assets/IbApproved";
import Kyc from "../../../../assets/Kyc";
import Sales from "../../../../assets/Sales";
import Support from "../../../../assets/support/Support";
import Wallet from "../../../../assets/wallet/Wallet";
import Reports from "../../../../assets/Reports.jsx";

const rolesData = {
    Ticket: {
        title: 'Ticket',
        activeUrl: ['/ticket', '/view_ticket'],
        icon: <Support />,
        url: '/ticket',
    },
    Sales : {
        title: 'Sales',
        icon: <Sales />,
        activeUrl: ['/sales_dashboard', '/source', '/leads'],
        children: [
            {
                title: 'Dashboard',
                icon: <Disc />,
                url: '/sales_dashboard',
                activeUrl: ['/sales_dashboard'],
            },
            {
                title: 'Source',
                icon: <Disc />,
                url: '/source',
                activeUrl: ['/source'],
            },
            {
                title: 'Leads',
                icon: <Disc />,
                url: '/leads',
                activeUrl: ['/leads'],
            },
        ],
    },
    KYC: {
        title: 'KYC',
        icon: <Kyc />,
        url: '/kyc',
        activeUrl: ['/kyc']
    },
    Inbox: {
        title: 'Inbox',
        icon: <EmailTemplate />,
        url: '/inbox',
        activeUrl: ['/inbox']
    },
    Deposit: {
        title: 'Deposit',
        icon: <Wallet />,
        url: '/wallet_deposit',
        activeUrl: ['/wallet_deposit'],
    },
    Withdraw: {
        title: 'Withdraw',
        icon: <Reports />,
        url: '/wallet_withdraw',
        activeUrl: ['/wallet_withdraw'],
    },
    Account: {
        title: 'Accounts',
        icon: <Account />,
        url: '/accounts',
        activeUrl: ['/accounts']
    },
    'IB Manager': {
        title: 'IB Manager',
        icon: <IbApproved />,
        url: '/ib_manager',
        activeUrl: ['/ib_manager', '/ib_details']
    },
    'Internal Transfer':{
        title: 'Internal Transfer',
        icon: <AccountType />,
        url: '/wallet_internal_transfer',
        activeUrl: ['/wallet_internal_transfer'],
    },
}

export default rolesData;