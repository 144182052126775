
const defaultRender = (value) => (value ? value : '--');
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        sorter: true,
    },
    
    {
        title: 'Phone No',
        dataIndex: 'phoneNo',
        sorter: true,
    },
    {
        title: 'Group Name',
        dataIndex: 'groupName',
        sorter: true,
    },
    
    {
        title: 'Account No.',
        dataIndex: 'login',
        sorter: true,
    },
    {
        title: 'Leverage',
        dataIndex: 'leverage',
        sorter: true,
    },
    {
        title: 'group Name',
        dataIndex: 'groupName',
        sorter: true,
    },
    {
        title: 'Currency',
        dataIndex: 'currencyCode',
        sorter: true,
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
    }

];

export default columns;