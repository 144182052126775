import React, { useState, useEffect } from "react";
import { Input, Form, Switch, Spin } from "antd";
import columns from "../../columns/Subadmin.js";
import ConfirmationModal from "../../components/global/modal/ConfirmationModal.jsx";
import TableWithSkeleton from "../../components/global/table/TableWithSkeleton.jsx";
import ModalComponent from "../../components/global/modal/ModalComponent.jsx"
import Edit from "../../assets/Edit.jsx";
import Account from "../../assets/Account.jsx";
import IbApproved from "../../assets/IbApproved.jsx";
import Kyc from "../../assets/Kyc.jsx";
import Inbox from "../../assets/Task.jsx";
import Withdraw from "../../assets/EmailTemplate.jsx";
import Wallet from "../../assets/wallet/Wallet.jsx";
import Ticket from "../../assets/support/Ticket.jsx";
import { AddSubadmin, GetAllSubadmin, GetSubadminsAndPermissionApi, updatePermissionBysubadmin,DeactiveUser } from "../../api/request/subadmin.js";
import toast from "react-hot-toast";
import LoadableButton from "../../components/buttons/LoadableButton.jsx";
import moment from "moment";

export default function Subadmin() {

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubadminsAndPermissionLoading, setIsSubadminsAndPermissionLoading] = useState(false);
    const [subadminsAndPermissionData, setSubadminsAndPermissionData] = useState([]);
    const [isAddSubadminLoading, setIsAddSubadminLoading] = useState(false);
    const [isUpdateSubadminLoading, setIsUpdateSubadminLoading] = useState(false);
    const [isAddSubadmin, setIsAddSubadmin] = useState(false);
    const [isEditSubadmin, setIsEditSubadmin] = useState(false);
    const [initialValues, setInitialValues] = useState({ subadminName: '' });
    const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
    const [isStatusConfirmLoading, setIsStatusConfirmLoading] = useState(false);
    const [selectedSubadmin, setSelectedSubadmin] = useState(null);
    const [data, setData] = useState([]);
    const [selectedsubid, setSelectedSub] = useState(null);
    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--theme'))?.trim();


    const statusHandler = async (role) => {
        setSelectedSub(role);
        setIsStatusConfirmOpen(true);
    }


    const getSubadmin = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetAllSubadmin();
            if (data?.success) {
                setData(data?.data?.map((item) => {
                    return {
                        ...item,
                        name: `${item?.firstName} ${item?.lastName}`,
                        createdDate: moment(item?.createdDate).format('DD-MM-YYYY, LT'),
                        status: ((<Switch onChange={() => statusHandler(item)} checkedChildren="On" unCheckedChildren="Off" checked={item?.isActive} />)),
                        actions: <div>
                            <button onClick={e => openEditHandler(item)} className="me-3"><Edit /></button>
                        </div>
                    }
                }));
            }
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    }

    const statusChangeConfirm = async () => {
        try {
            setIsStatusConfirmLoading(true);

            const { data } = await DeactiveUser({ userId: selectedsubid?.userId, isActive: !selectedsubid?.isActive });
            if (data?.success) {
                getSubadmin();
                toast.success(data?.message);
            } else {
                toast.error(data?.message);
            }

            setIsStatusConfirmLoading(false);
            setIsStatusConfirmOpen(false);
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsStatusConfirmLoading(false);
        }
    }


    const handleSubmit = async (value) => {
        try {
            setIsAddSubadminLoading(true);
            const { data } = await AddSubadmin(value);
            if (data?.success) {
                toast.success(data?.message);
                form.resetFields();
                setIsAddSubadminLoading(false);
                setIsAddSubadmin(false);
                getSubadmin();
            }
            else {
                toast.error(data?.message);
                setIsAddSubadminLoading(false);
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsAddSubadminLoading(false);
        }
    }

    const handleSubadminChange = (modulePermissionId, haveModulePermission) => {
        setSubadminsAndPermissionData(prevModules =>
            prevModules.map(module =>
                module.modulePermissionId === modulePermissionId ? { ...module, haveModulePermission } : module
            )
        );
    };


    useEffect(() => {
        getSubadmin();
    }, []);

    useEffect(() => {
        if (!isAddSubadmin) {
            form.resetFields();
        }
    });

    const openEditHandler = (subadmin) => {
        getSubadminsAndPermissionById(subadmin?.userId);
        setSelectedSubadmin(subadmin);
        setIsEditSubadmin(true);
    }

    const getSubadminsAndPermissionById = async (subadminId) => {
        try {
            setIsSubadminsAndPermissionLoading(true);
            const { data } = await GetSubadminsAndPermissionApi(subadminId);
            if (data?.success) {
                setSubadminsAndPermissionData(data?.data);
            }
            setIsSubadminsAndPermissionLoading(false);
        } catch (error) {
            setIsSubadminsAndPermissionLoading(false);
        }
    }

    const getIcons = (title) => {
        switch (title) {
            case 'Account':
                return <Account />;
            case 'Ticket':
                return <Ticket />;
            case 'Deposit':
                return <Wallet />;
            case 'Inbox':
                return <Withdraw />;
            case 'KYC':
                return <Kyc />;
            case 'Withdraw':
                return <Inbox />;
            case 'IB Manager':
                return <IbApproved />;
        }
    }

    const updateSubadminsAndPermission = async () => {
        setIsUpdateSubadminLoading(true)
        try {
            let transformedArray = subadminsAndPermissionData.map(item => ({
                modulePermissionId: item.modulePermissionId,
                moduleId: item.moduleId,
                isActive: item.haveModulePermission
            }));
            const { data } = await updatePermissionBysubadmin(transformedArray);
            if (data.success) {
                setIsUpdateSubadminLoading(false)
                toast.success(data.message)
                setIsEditSubadmin(false)
            }
            // need to call api and pass -->> transformedArray

        } catch (error) {

        }
    }

    return (
        <div>
            <div className="flex flex-col sm:flex-row sm:justify-end">
                <div className="flex">

                    <div>
                        <button
                            className="export-btm flex bg-primary text-sm text-white font-medium uppercase px-9 py-2 rounded-lg themeHover  duration-400"
                            onClick={e => setIsAddSubadmin(true)}
                            size="large"
                        >
                            <img
                                src="assets/icons/add_icon.svg"
                                alt="icon"
                                className="mr-2 mt-1"
                                width={15}
                            />
                            Add Subadmin
                        </button>
                    </div>
                </div>
            </div>

            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">Sub Admins</p>
                </div>
                <div className="mt-6 mb-6 ">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
                </div>

                <ConfirmationModal
                    isOpen={isStatusConfirmOpen}
                    setIsOpen={setIsStatusConfirmOpen}
                    message='Are you sure to change the Subadmin status?'
                    onConfirm={statusChangeConfirm}
                    isLoading={isStatusConfirmLoading}
                    loadingLabel='Changing status...'
                />

                {/* Add subadmin */}
                <ModalComponent isOpen={isAddSubadmin} setIsOpen={setIsAddSubadmin} title={<div className="font-bold text-lg">Add User</div>} onCancel={() => setIsAddSubadmin(false)} width={800}>
                    <Form
                        className="w-full"
                        autoComplete="off"
                        form={form}
                        initialValues={initialValues}
                        onFinish={handleSubmit}
                    >
                        <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5">
                            <div className="input-white">
                                <label className="text-base text-[#2F2B3DCC] font-medium">
                                    First Name
                                </label>
                                <Form.Item
                                    name="firstName"
                                    rules={[
                                        { required: true, message: "Please enter first name." },
                                    ]}
                                >
                                    <Input placeholder="First name" className="mt-1 w-full py-2" />
                                </Form.Item>
                            </div>
                            <div className="input-white">
                                <label className="text-base text-[#2F2B3DCC] font-medium">
                                    Last Name
                                </label>
                                <Form.Item
                                    name="lastName"
                                    rules={[{ required: true, message: "Please enter last name." }]}
                                >
                                    <Input placeholder="Last name" className="mt-1 w-full py-2" />
                                </Form.Item>
                            </div>
                            <div className="input-white">
                                <label className="text-base text-[#2F2B3DCC] font-medium">
                                    Primary Email
                                </label>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                            message: "Please enter valid email.",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Primary email"
                                        className="mt-1 w-full py-2"
                                    />
                                </Form.Item>
                            </div>
                            <div className="input-white">
                                <label className="text-base text-[#2F2B3DCC] font-medium">
                                    Phone No.
                                </label>
                                <Form.Item
                                    name="phoneNo"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter valid phone number.",
                                        },
                                    ]}
                                >
                                    <Input
                                        type="number"
                                        placeholder="Phone no."
                                        className="mt-1 w-full py-2"
                                    />
                                </Form.Item>
                            </div>


                        </div>
                        <div className="flex justify-end mt-8">
                            <LoadableButton
                                className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
                                disabled={isAddSubadminLoading}
                                type="Submit"
                                lable="Submit"
                                isLoading={isAddSubadminLoading}
                                loadingLable="Adding subadmin..."
                            />
                        </div>
                    </Form>
                </ModalComponent>

                {/* Edit subadmin */}
                <ModalComponent isOpen={isEditSubadmin} setIsOpen={setIsEditSubadmin} title={<div className="font-bold text-lg">List of Permission</div>} onCancel={() => setIsEditSubadmin(false)} width={650}>
                    {
                        isSubadminsAndPermissionLoading ? (
                            <div className="flex justify-center flex-col my-[10%]">
                                <Spin size="large" />
                                <p className="primary text-center mt-2">Loading...</p>
                            </div>
                        ) : (
                            <div>
                                <div className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 mt-2 px-4">
                                    {subadminsAndPermissionData.map((item, index) => (
                                        <div key={index} className="h-[100px] relative" onClick={() => handleSubadminChange(item?.modulePermissionId, !item.haveModulePermission)} >
                                            <div className={`border rounded p-4 h-full flex flex-col justify-center items-center cursor-pointer duration-300 ${item?.haveModulePermission ? " border-primary" : ""}`}>
                                                {
                                                    item?.haveModulePermission &&
                                                    <div className="h-[10px] w-[10px] rounded-full absolute top-2 left-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="size-4" stroke={primaryColor} >
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                        </svg>

                                                    </div>
                                                }
                                                <div>{getIcons(item?.moduleName)}</div>
                                                <p className="text-center subadmins-title ">{item?.moduleName}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className=" text-center mt-6">
                                    <LoadableButton
                                        className="bg-primary text-base text-white font-medium uppercase px-9 py-2 rounded-lg themeHover duration-400"
                                        disabled={isUpdateSubadminLoading}
                                        type="Submit"
                                        lable="Save"
                                        isLoading={isUpdateSubadminLoading}
                                        loadingLable="Updating subadmin..."
                                        onClick={updateSubadminsAndPermission}
                                    />
                                </div>
                            </div>
                        )
                    }
                </ModalComponent>

            </div>
        </div>
    );
}