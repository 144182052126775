const columns = [
  {
    title: "Image",
    dataIndex: "image",
  },
  {
    title: "Height",
    dataIndex: "height",
  },
  {
    title: "Width",
    dataIndex: "width",
  },
  {
    title: "Created Date",
    dataIndex: "createdDate",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];

export default columns;
