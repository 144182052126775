import React, { useState, useEffect } from "react";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton";
import Pagination from "../../../components/global/pagination/Pagination";
import columns from "../../../columns/settings/notification";
import moment from "moment/moment.js";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal.jsx";
import toast from "react-hot-toast";
import ModalComponent from "../../../components/global/modal/ModalComponent";
import { Input, Form, Select } from "antd";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
import { GetAllUsersApi, DeleteNotificationsApi, GetAllNotificationsApi, AddNotificationApi } from "../../../api/request/settings/notifications.js";
const { TextArea } = Input;


export default function Notifications() {

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [initialValues, setInitialValues] = useState({ title: '', content: '', userId: [] });
  const [deleteNotificationModal, setDeleteNotificationModal] = useState(false);
  const [isDelNotificationLoading, setIsDelNotificationLoading] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({});
  const [data, setData] = useState([]);
  const [isAddNotificationLoading, setIsAddNotificationLoading] = useState(false);
  const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const filteredOptions = users.filter(option => !selectedUsers.map(item => item.value).includes(option.value));

  const handleChange = (values) => {
    setSelectedUsers(values.map(value => users.find(option => option.value === value)))
  };

  const tagRender = (props) => {
    const { value, closable, onClose } = props;
    const option = users.find(opt => opt.value === value);
    return (
      <span className="inline-flex items-center px-2 py-[1px] rounded-sm mr-1 my-1 bg-primary-light">
        {option?.label}
        {closable && (<span onClick={onClose} className="cursor-pointer ml-2">x</span>)}
      </span>
    );
  };

  const deleteNotificationsHandler = async () => {
    try {
      setIsDelNotificationLoading(true);
      const { data } = await DeleteNotificationsApi(selectedNotification?.notificationId);
      if (data?.success) {
        toast.success(data?.message);
        setDeleteNotificationModal(false);
        getAllAllNotifications();
      }
      setIsDelNotificationLoading(false);
    } catch (err) {
      setIsDelNotificationLoading(false);
    }
  }

  const getAllAllNotifications = async () => {
    try {
      setIsLoading(true);
      let params = {
        PageNumber: page,
        PageSize: pageSize,
      }
      const { data } = await GetAllNotificationsApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data?.data?.details?.map((item) => {
            return {
              ...item,
              actions: (
                <button onClick={() => { setSelectedNotification(item); setDeleteNotificationModal(true) }}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D91819" className="w-6 h-6 cursor-pointer hover:stroke-[#aa0001] duration-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                  </svg>
                </button>),
              content: (<p dangerouslySetInnerHTML={{ __html: item.message }}></p>),
              createdDate: item?.createdDate? moment(item?.createdDate).format('DD-MM-YYYY, hh:mm A') : null,
            };
          })
        );
        
        setTotalRecord(data?.data?.totalMessageCount);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAllAllNotifications();
  }, [page, pageSize]);

  const handleSubmit = async (values) => {
    setIsAddNotificationLoading(true);
    let params = {
      ...values,
      isAdmin: true
    };
    try {
      const { data } = await AddNotificationApi(params);
      if (data?.success) {
        toast.success(data?.message);
        getAllAllNotifications();
        form.resetFields();
        setSelectedUsers([]);
        setIsOpenNotificationModal(false);
      }
      setIsAddNotificationLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsAddNotificationLoading(false);
    }
  };

  const getAllUsers = async () => {
    try {
      setIsUsersLoading(true);
      let params = {
        ApplyPagination: false
      }
      const { data } = await GetAllUsersApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setUsers(data?.data?.map((item) => { return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}` }; }));
      }
      setIsUsersLoading(false);
    } catch (error) {
      setIsUsersLoading(false);
    }
  };

  const filterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  useEffect(() => {
    form.resetFields();
  }, [isOpenNotificationModal]);

  const openSendNotificationModal = () => {
    setIsOpenNotificationModal(true);
    getAllUsers();
  }

  return (
    <div>
      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full">
        <div className="px-4 lg:px-6 flex justify-between items-center">
          <p className="text-[#2F2B3D] text-xl font-semibold">All Notification</p>
          <button onClick={openSendNotificationModal} className="flex items-center bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover  duration-500">
            <svg width="14" height="14" viewBox="0 0 16 16" fill="none" className="mr-2" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.85714 9.14286H0V6.85714H6.85714V0H9.14286V6.85714H16V9.14286H9.14286V16H6.85714V9.14286Z" fill="white" />
            </svg>
            Add Notification
          </button>
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
          <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} /> 
        </div>
      </div>

      <ConfirmationModal
        isOpen={deleteNotificationModal}
        setIsOpen={setDeleteNotificationModal}
        message='Are you sure you want to remove this notification?'
        onConfirm={deleteNotificationsHandler}
        isLoading={isDelNotificationLoading}
        loadingLabel='Removing notification...'
      />

      {/* ---- Add Notification modal ---- */}
      <ModalComponent isOpen={isOpenNotificationModal} setIsOpen={setIsOpenNotificationModal} title="Add Notification" width={800}>
        <Form className='w-full' autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
          <div>
            <div className='input-white'>
              <label className='text-base text-[#2F2B3DCC] font-medium'>Title</label>
              <Form.Item name="title" rules={[{ required: true, message: 'Please enter title.' }]}>
                <Input placeholder="Notification title" className="w-full ps-4 py-2" />
              </Form.Item>
            </div>
          </div>
          <div className='input-white mt-4'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>Description</label>
            <Form.Item name="message" rules={[{ required: true, message: 'Please enter description.' }]}>
              <TextArea className="mt-1" placeholder="Description..." autoSize={{ minRows: 5, maxRows: 8 }} />
            </Form.Item>
          </div>
          <div className='ant-select-selector-white ant-multi-select-selector mt-4'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>Users</label>
            <Form.Item name="userId">
              <Select mode="multiple" placeholder="By default it will notify all users." value={selectedUsers.map(item => item.value)} onChange={handleChange} options={filteredOptions} tagRender={tagRender} filterOption={filterOption} />
            </Form.Item>
          </div>
          <div className='flex justify-end items-center gap-5 flex-wrap mt-6'>
            <div className="flex gap-5">
              <button type='button' onClick={() => setIsOpenNotificationModal(false)} className='bg-primary text-sm text-white font-semibold rounded-lg px-6 py-2 uppercase themeHover duration-500'>Cancel</button>
              <LoadableButton
                className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500'
                type="Submit"
                lable='Submit'
                isLoading={isAddNotificationLoading}
                loadingLable='Sending Notification...'
              />
            </div>
          </div>
        </Form>
      </ModalComponent>
    </div>
  );
}
