import React, { useState, useEffect } from "react";
import Pagination from "../../../../components/global/pagination/Pagination.jsx";
import columns from "../../../../columns/wallet/Withdraw/approved.js";
import ModalComponent from "../../../../components/global/modal/ModalComponent.jsx";
import TableWithSkeleton from "../../../../components/global/table/TableWithSkeleton.jsx";
import Eye from "../../../../assets/Eye.jsx";
import { GetAllAccountApi } from "../../../../api/request/wallet/withdraw.js";
import moment from "moment";
import { DatePicker } from "antd";
import Copytest from "../../../../components/copy/Copy.jsx"
import Copy from '../../../../components/copy/Copy.jsx';

const { RangePicker } = DatePicker;

export default function Withdraw({ search }) {

    const [pageSize, setPageSize] = useState(10);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [selectedIBId, setSelectedIBId] = useState(null);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [withdrawdetails, setWithdrawdetails] = useState({});
    const [dates, setDates] = useState([]);
    const [sorter, setSorter] = useState({ SortBy: 'actionDate', SortOrder: '' });


    const truncateString = (str) => {
        let string = str?.toString();
        return string?.substring(0, 6) + '...';
    };

    const handleClick = (item) => {
        setSelectedIBId({ userId: item?.userId, TransactionId: item?.requestId });
        setIsOpenModal(true);
    };

    const getAllWithdraw = async () => {
        try {
            let params = {
                PageNumber: page,
                PageSize: pageSize,
                SearchText: search,
                FilterText: "approved",
                FromDate: dates.length === 2 ? dates[0].format('MM-DD-YYYY') : '',
                ToDate: dates.length === 2 ? dates[1].format('MM-DD-YYYY') : '',
                ...sorter
            }
            setIsLoading(true);
            const { data } = await GetAllAccountApi(new URLSearchParams(params).toString());
            if (data?.success) {
                setData(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            name: `${item?.firstName} ${item?.lastName}`,
                            requestDate: moment(item?.requestDate).format('DD-MM-YYYY, hh:mm A'),
                            actionDate: moment(item?.actionDate).format('DD-MM-YYYY, hh:mm A'),
                            balance: `$ ${item?.balance}`,
                            amount: `$ ${item?.amount}`,
                            actions: (
                                <div className="flex">
                                    <button type="button" onClick={() => { handleClick(item); setWithdrawdetails(item) }}><Eye /></button>
                                </div>
                            ),
                            address: (
                                <div className="flex">
                                    <p>{truncateString(item?.thirdPartyWalletAddress)}</p>
                                    <Copytest text={item?.thirdPartyWalletAddress} />
                                </div>
                            )

                        };
                    })
                );
                setTotalRecord(data?.totalRecords);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }
    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter?.order) {
            setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
        } else {
            setSorter({ SortBy: 'actionDate', SortOrder: '' });
        }
    };

    const handleDateChange = (dates) => {
        setDates(dates);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        getAllWithdraw();
    };


    useEffect(() => {
        getAllWithdraw();
    }, [page, pageSize, dates,search, sorter]);

    useEffect(() => {
        setPage(1);
    }, [search]);


    return (
        <div>
            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="flex justify-between px-4 lg:px-6">
                    <div>
                        <p className="text-[#2F2B3D] text-xl font-semibold">Approved Withdraw</p>
                    </div>
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <div>
                            <RangePicker className='border-light border-2 w-full mt-1 py-2' format='DD-MM-YYYY' onChange={handleDateChange} />
                        </div>
                    </form>
                </div>
                <div className="my-6">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} onChange={handleTableChange}/>
                    <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
                </div>
            </div>

            {/* ---- Withdraw Information ---- */}
            <ModalComponent
                isOpen={isOpenModal}
                setIsOpen={setIsOpenModal}
                title='Withdraw Information'
                width={1300}
            >
                <div className='p-5'>
                    <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10 '>
                        {withdrawdetails?.firstName ? <div>
                            <p className='text-base font-normal'>First Name</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.firstName || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.lastName ? <div>
                            <p className='text-base font-normal'>Last Name</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.lastName || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.email ? <div>
                            <p className='text-base font-normal'>Email</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.email || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.withdrawType ? <div>
                            <p className='text-base font-normal'>withdraw Type</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.withdrawType || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.walletAccountNo ? <div>
                            <p className='text-base font-normal'>Wallet Account No.</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.walletAccountNo || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.bankAccountNumber ? <div>
                            <p className='text-base font-normal'>Bank Account Number</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.bankAccountNumber || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.balance ? <div>
                            <p className='text-base font-normal'>Wallet Balance</p>
                            <p className='text-xl primary font-semibold mt-2'>$ {withdrawdetails?.balance || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.amount ? <div>
                            <p className='text-base font-normal'>Amount</p>
                            <p className='text-xl primary font-semibold mt-2'>$ {withdrawdetails?.amount || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.requestDate ? <div>
                            <p className='text-base font-normal'>Created Time</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.requestDate ? moment(withdrawdetails?.requestDate).format('DD-MM-YYYY, hh:mm A') : '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.bankBranchAddress ? <div>
                            <p className='text-base font-normal'>Bank Branch Address</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.bankBranchAddress || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.bankName ? <div>
                            <p className='text-base font-normal'>Bank Name</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.bankName || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.iban ? <div>
                            <p className='text-base font-normal'>IBAN</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.iban || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.ifscCode ? <div>
                            <p className='text-base font-normal'>IFSC Code</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.ifscCode || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.swifT_BIC_Code ? <div>
                            <p className='text-base font-normal'>SwifT BIC Code</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.swifT_BIC_Code || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.cryptoWalletAddress ? <div>
                            <p className='text-base font-normal'>Crypto Wallet Address</p>
                            <div className="flex items-center gap-3">
                                <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.cryptoWalletAddress || '---'}</p>
                                <Copy text={withdrawdetails?.cryptoWalletAddress} />
                            </div>
                        </div> : ""}
                        {withdrawdetails?.paypalEmail ? <div>
                            <p className='text-base font-normal'>Paypal Email</p>
                            <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.paypalEmail || '---'}</p>
                        </div> : ""}
                        {withdrawdetails?.upiId ? <div>
                            <p className='text-base font-normal'>UPI Id</p>
                            <div className="flex items-center gap-3">
                                <p className='text-xl primary font-semibold mt-2'>{withdrawdetails?.upiId || '---'}</p>
                                <Copy text={withdrawdetails?.upiId} />
                            </div>
                        </div> : ""}

                    </div>
                </div>

            </ModalComponent>



        </div>
    )
}
