import moment from "moment";
const defaultRender = (value) => (value ? value : '--');
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        // sorter: (a, b) => a.name - b.name,
    },

    {
        title: 'Account Type',
        dataIndex: 'accountType',
        sorter: true,
        // sorter: (a, b) => a.accountType - b.accountType,
    },
    {
        title: 'Account Name',
        dataIndex: 'acType',
        sorter: true,
        // sorter: (a, b) => a.acType - b.acType,
    },
    {
        title: 'Leverage',
        dataIndex: 'leverage',
        sorter: true,
        // sorter: (a, b) => a.leverage - b.leverage,
    },
    {
        title: 'Balance',
        dataIndex: 'balance',
        sorter: true,
        // sorter: (a, b) => a.balance - b.balance,
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        sorter: true,
    },
    {
        title: 'Decline Date',
        dataIndex: 'actionDate',
        sorter: true,
        // render: (createdDate) => (moment(createdDate).format('DD-MM-YYYY, hh:mm A')),
        render: defaultRender,
    },
    {
        title: 'Action By',
        dataIndex: 'actionBy',
    },

    // {
    //     title: 'Actions',
    //     dataIndex: 'actions',
    // }

];

export default columns;