import Papa from 'papaparse';

export const exportAsCSV = (data, removableColumns, fileName = 'Download') => {
    let filteredData = [];
    if (removableColumns?.length > 0) {
        filteredData = data.map(item => {
            const filteredItem = { ...item };
            removableColumns.forEach(column => {
                delete filteredItem[column];
            });
            return filteredItem;
        });
    }
    const csv = Papa.unparse(removableColumns?.length > 0 ? filteredData : data);
    const csvData = new Blob([csv], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvData);

    const link = document.createElement('a');
    link.href = csvUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}