import React from 'react';

export default function StatisticsCard({ title, value, secondary, icon }) {
    return (
        <div className='p-2 cursor-pointer'>
            <div className='flex items-center gap-4'>
                <div className={`flex justify-center ${secondary ? 'bg-secondary' : 'bg-primary'} items-center w-[45px] h-[45px] rounded-full`}>
                    {icon}
                </div>
                <div>
                    <p className='text-sm font-semibold text-[#2F2B3D]'>{title}</p>
                    <div className='flex items-baseline'>
                        <p className={`text-lg font-bold ${secondary ? 'secondary' : 'primary'}`}>{value || 0}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}