import React, { useEffect, useState } from 'react'

const Wallet = ({active}) => {
    const [primaryColor, setPrimaryColor] = useState(active ? "#fff" : (getComputedStyle(document.documentElement).getPropertyValue('--theme'))?.trim())
    useEffect(() => {       
      setPrimaryColor(active ? "#fff" : (getComputedStyle(document.documentElement).getPropertyValue('--theme'))?.trim())      
    }, [active])
    return (
        <svg width="20" height="20" viewBox="0 0 25 25" fill={primaryColor} xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8903 1.95994C12.9911 1.82466 13.1787 1.80668 13.3009 1.92058L14.3464 2.89521L11.0543 7.24553H13.2348L15.6249 4.0871L17.5004 5.8354C17.6157 5.94293 17.6291 6.12801 17.5305 6.25277L16.7459 7.24553H18.9528C19.5135 6.38025 19.3856 5.19291 18.6265 4.48534L14.4271 0.57052C13.5718 -0.226808 12.2584 -0.100918 11.5528 0.846041L6.78379 7.24553H8.95145L12.8903 1.95994ZM15.0405 15.6455C14.5704 15.6455 14.1892 16.0485 14.1892 16.5455C14.1892 17.0426 14.5704 17.4455 15.0405 17.4455H17.3108C17.781 17.4455 18.1622 17.0426 18.1622 16.5455C18.1622 16.0485 17.781 15.6455 17.3108 15.6455H15.0405ZM1.7027 7.54553C1.7027 7.04848 2.08387 6.64553 2.55405 6.64553H6.10526L7.45784 4.84553H2.55405C1.14349 4.84553 0 6.05436 0 7.54553V20.1455C0 22.2994 1.6517 24.0455 3.68919 24.0455H17.3108C19.3483 24.0455 21 22.2994 21 20.1455V12.3455C21 10.1916 19.3483 8.44553 17.3108 8.44553H2.55405C2.08387 8.44553 1.7027 8.04258 1.7027 7.54553ZM1.7027 20.1455V10.0919C1.96898 10.1914 2.25554 10.2455 2.55405 10.2455H17.3108C18.4079 10.2455 19.2973 11.1857 19.2973 12.3455V20.1455C19.2973 21.3053 18.4079 22.2455 17.3108 22.2455H3.68919C2.59208 22.2455 1.7027 21.3053 1.7027 20.1455Z" />
        </svg>

    )
}

export default Wallet