const columns = [
    {
        title: 'No',
        dataIndex: 'no',
    },
    {
        title: 'Account Type',
        dataIndex: 'planName',
    },
    {
        title: 'Maximun Commission ($)',
        dataIndex: 'maxCommission',
    },
    {
        title: 'Commision Amount ($)',
        dataIndex: 'commisionamount',
    },
];

export default columns;