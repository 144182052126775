const columns = [
    {
        title: 'Activity',
        dataIndex: 'message',
    },
    {
        title: 'Update Date',
        dataIndex: 'createdDate',
    }
];

export default columns;