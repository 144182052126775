import React, { useState, useEffect } from "react";
import { Input, Tooltip, DatePicker } from "antd";
import Pagination from "../../../../components/global/pagination/Pagination.jsx";
import columns from "../../../../columns/ibCommission/generateCommission/IBCommission.js";
import TableWithSkeleton from '../../../../components/global/table/TableWithSkeleton.jsx';
import { GetGenerateCommissionCalculationApi, CommissionRollBackApi } from '../../../../api/request/ibCommission.js';
import moment from "moment";
import LoadableButton from '../../../../components/buttons/LoadableButton.jsx';
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import ConfirmationModal from "../../../../components/global/modal/ConfirmationModal.jsx";
const { RangePicker } = DatePicker;

export default function IBCommission() {

    const { id } = useParams();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [search, setSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isExportLoading, setIsExportLoading] = useState(false);
    const [isRollbackModalOpen, setIsRollbackModalOpen] = useState(false);
    const [isRollbackLoading, setIsRollbackLoading] = useState(false);
    const [selectedCommission, setSelectedCommission] = useState(null);
    const [textAreaValue, setTextAreaValue] = useState("");
    const [dateRange, setDateRange] = useState([]);
    const [adminCommission, setisAdminCommission] = useState();
    

    const getCommission = async () => {
        try {


            let apiParams = {
                PageNumber: page,
                PageSize: pageSize,
                searchText: searchText,
                FromDate: dateRange?.[0]?.format('MM/DD/YYYY') ?? '',
                ToDate: dateRange?.[1]?.format('MM/DD/YYYY') ?? '',
            }
            setIsLoading(true);
            const { data } = await GetGenerateCommissionCalculationApi(id, new URLSearchParams(apiParams).toString());
            setData(
                data?.data?.map((item) => {
                    return {
                        ...item,
                        commision: `$ ${item?.commision}`,
                        createdDate: (item?.createdDate ? moment.utc(item?.createdDate).format("DD-MM-YYYY, hh:mm A") : null),
                        openTime: (item?.openTime ? moment.utc(item?.openTime).format("hh:mm A") : null),
                        closeTime: (item?.closeTime ? moment.utc(item?.closeTime).format("hh:mm A") : null),
                        comments: (item?.comments || '--'),
                        commissionType: (item?.commissionType || '--'),
                        isAdminCommission: item?.isAdminCommission,
                        actions: (
                            <div className="flex justify-center">
                                <Tooltip title="Rollback"><button onClick={() => rollbackModalHandler(item)}><img src="/assets/icons/delete_icon.svg" alt="icon" className='min-w-6 max-w-6' /></button></Tooltip>
                            </div>)
                    };
                })
            );
            setTotalRecord(data?.totalRecords);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const exportHandler = async () => {
        try {
            setIsExportLoading(true);
            let apiParams = {
                ApplyPagination: false,
            };
            const { data } = await GetGenerateCommissionCalculationApi(id, new URLSearchParams(apiParams).toString());
            setIsExportLoading(false);
        } catch (error) { }
    };

    useEffect(() => {
        getCommission();
    }, [page, pageSize, dateRange,searchText]);

    useEffect(() => {
        setPage(1);
    }, [searchText]);

    const commissionRollback = async () => {
        try {
            if (!textAreaValue) {
                toast.error("Please enter a reason.");
                return;
            }
            setIsRollbackLoading(true);
            let apiParams = {
                ibUserId: id,
                comment: textAreaValue,
                loginId: selectedCommission?.accountNumber,
                commission: selectedCommission?.commision,
                dealId: selectedCommission?.dealId
            }
            const { data } = await CommissionRollBackApi(apiParams);
            if (data?.success) {
                getCommission();
                setTextAreaValue('');
                setIsRollbackModalOpen(false);
            }
            setIsRollbackLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsRollbackLoading(false);
        }
    }

    const rollbackModalHandler = (item) => {
        setSelectedCommission(item);
        setIsRollbackModalOpen(true);
    }

    useEffect(() => {
        setTextAreaValue('');
    }, [isRollbackModalOpen]);

    return (
        <div>
            <div className="flex justify-end flex-wrap gap-5">
                <div className="flex items-center gap-3">
                    <div>
                        <div className='ant-date-range-selector-white'>
                            <RangePicker className='border-primary  py-2' format='DD-MM-YYYY'
                                onChange={(dates) => { setDateRange(dates) }} />
                        </div>
                    </div>
                    <div className="mr-3">
                        <Input
                            size="large"
                            style={{ width: 220 }}
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onPressEnter={() => setSearchText(search)}
                            prefix={
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                                    <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                                </svg>
                            }
                            suffix={search &&
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(''); setSearch('') }} viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                                </svg>
                            }
                        />
                    </div>
                    <div>
                        <LoadableButton
                            onClick={exportHandler}
                            className='bg-primary text-sm text-white font-semibold px-4 py-2 rounded-lg themeHover  duration-500'
                            img={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                <path fill-rule="evenodd" d="M11.47 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06l-3.22-3.22V16.5a.75.75 0 0 1-1.5 0V4.81L8.03 8.03a.75.75 0 0 1-1.06-1.06l4.5-4.5ZM3 15.75a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
                            </svg>}
                            disabled={isExportLoading}
                            lable='Export'
                            isLoading={isExportLoading}
                            loadingLable='Exporting...'
                        />
                    </div>
                </div>
            </div>

            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">IB Commission</p>
                </div>
                <div className="my-6 w-auto">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
                    <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
                </div>
            </div>

            {/* ---- Reject-request ---- */}
            <ConfirmationModal
                isOpen={isRollbackModalOpen}
                setIsOpen={setIsRollbackModalOpen}
                message="Are you sure you want to rollback this commission?"
                onConfirm={commissionRollback}
                isLoading={isRollbackLoading}
                loadingLabel="rolling back commission..."
                textArea={true}
                textAreaValue={textAreaValue}
                setTextAreaValue={setTextAreaValue}
                placeholder="Reason of rollback..."
            />
        </div>
    )
}