import api from "../index";

export const GetAllActypeApi = (params) => api.get(`PlanAccountType/GetAll-PlanAccountType?${params}`);
export const GetAllActypeByIdApi = (ActypeId) => api.get(`PlanAccountType/Get-PlanAccountType-ById?PlanId=${ActypeId}`);
export const GetAllplanApi = (params) => api.get(`DropDown/GetAllMT5-GroupName?${params}`);
export const GetGroupDetaisApi = (groupId) => api.get(`MT5Security/Get-Group-ById/${groupId}`);
export const AddAccountTypeApi = (data, headers) => api.post('PlanAccountType/Add-Update-Plan-Account-Type', data, headers);
export const UpdateAccountApi = (data, headers) => api.post('PlanAccountType/Add-Update-Plan-Account-Type', data, headers);
export const UpdateAccountStatusApi = (data) => api.put('PlanAccountType/Active-Inactive-AccountType', data);
export const SyncGroupsApi = (params) => api.get(`MT5Security/GetGroupSync`);
