// import React, { useState, useEffect } from 'react';
// import {GetParentTreeApi } from "../../../api/request/client.js";
// import { useSearchParams } from 'react-router-dom';
// import { useParams } from 'react-router-dom';

// import { Table } from 'antd';
// const colors = [
//     '#539198',
//     '#c04e4e',
//     '#c79f34',
//     '#656d37',
//     '#a1bdbe',
//     '#ccc5c5',
//     '#af3429',
// ];

// const getColor = (level) => {
//     const colorIndex = (level - 1) % colors.length;
//     let color
//     if (level == 0) {
//         color = '#da856c'
//     } else {
//         color = colors[colorIndex];
//     }
//     return color;
// }
// const columns = [
//     {
//         title: 'Name',
//         dataIndex: 'name',
//         render: (_, record) => (<p className='f-bold' style={{ color: getColor(record?.level),fontWeight:'bold',fontSize:16 }} >{record?.name}</p>)
//     },
//     {
//         title: 'Level',
//         dataIndex: 'level',
//     },
//     {
//         title: 'Count',
//         dataIndex: 'count',
//     },
//     {
//         title: 'Email',
//         dataIndex: 'email',
//     },
//     {
//         title: 'Mobile No',
//         dataIndex: 'mobileNo',
//     },
// ];
// function ParentTree() {
//     const [isLoading, setIsLoading] = useState(false);
//     const [treeData, setTreeData] = useState();
//     const [searchParams] = useSearchParams();
//     const { id } = useParams();
//     const [data, setData] = useState();

//     const openUserDetails = (userId) => {
//         const url = `/ib_tree_user/${userId}`;
//         const win = window.open(url, "_blank");
//         if (win) {
//             win.focus();
//         }
//     };

//     const convertDataToTree = (data) => {
//         const traverse = (node) => {
//             return {
//                 name:node?.fullName,
//                 level:node?.level || 0,
//                 key: `${node?.userId}`,
//                 count:node?.userCount || 0,
//                 email:node?.email,
//                 MobileNo:node?.mobile,
//                 children: node.childrenUser.length ? node.childrenUser.map(traverse) : null,
//             };
//         };
//         return [traverse(data)];
//     };

//     const getAllTrader = async () => {
//         try {
//           setIsLoading(true);
         
//           const { data } = await GetParentTreeApi(id);
//           if (data?.success) {
//             setData(JSON.parse(data?.data));
//             setTreeData(convertDataToTree(JSON.parse(data?.data)));
//           }
//           setIsLoading(false);
//         } catch (error) {
//           setIsLoading(false);
//         }
//       }

//       useEffect(() => {
//         getAllTrader();
//     }, []);
    
//     return (
//     <div>
//             <div className='react-tree-wrapper'>
//                 <div className='react-flow-container p-4'>
//                     {isLoading ? (
//                         <div className='text-center text-muted'> Loading...</div>
//                     ) : (
//                         <div className='m-3'>
//                             {
//                                 data?.childrenUser?.length ? (
                                    
//                                     <>
//                                         <Table columns={columns} dataSource={treeData} pagination={false}>
//                                         </Table>
//                                     </>
//                                 ) : (
//                                     <>
//                                         <Table columns={columns} dataSource={treeData} pagination={false}>
//                                         </Table>
//                                     </>
//                                 )
//                             }
//                         </div>
//                     )}
//                 </div>
//             </div>
            
//             </div>
//     )
// }

// export default ParentTree;

import React, { useState, useEffect } from 'react';
import { GetParentTreeApi } from "../../../api/request/client.js";
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';

const colors = [
    '#539198',
    '#c04e4e',
    '#c79f34',
    '#656d37',
    '#a1bdbe',
    '#ccc5c5',
    '#af3429',
];

const getColor = (level) => {
    const colorIndex = (level - 1) % colors.length;
    let color;
    if (level === 0) {
        color = '#da856c';
    } else {
        color = colors[colorIndex];
    }
    return color;
};

const columns = (openUserDetails) => [
    {
        title: 'Name',
        dataIndex: 'name',
        render: (_, record) => (
            <p
                className='f-bold'
                style={{ color: getColor(record?.level), fontWeight: 'bold', fontSize: 16, cursor: 'pointer' }}
                onClick={() => openUserDetails(record?.key)}
            >
                {record?.name}
            </p>
        ),
    },
    {
        title: 'Level',
        dataIndex: 'level',
    },
    {
        title: 'Count',
        dataIndex: 'count',
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'Mobile No',
        dataIndex: 'mobileNo',
    },
];

function ParentTree() {
    const [isLoading, setIsLoading] = useState(false);
    const [treeData, setTreeData] = useState();
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const [data, setData] = useState();

    const openUserDetails = (userId) => {
        const url = `/client_details/${userId}`;
        const win = window.open(url, "_blank");
        if (win) {
            win.focus();
        }
    };

    const convertDataToTree = (data) => {
        const traverse = (node) => {
            return {
                name: node?.fullName,
                level: node?.level || 0,
                key: `${node?.userId}`,
                count: node?.userCount || 0,
                email: node?.email,
                mobileNo: node?.mobile,
                children: node.childrenUser.length ? node.childrenUser.map(traverse) : null,
            };
        };
        return [traverse(data)];
    };

    const getAllTrader = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetParentTreeApi(id);
            if (data?.success) {
                setData(JSON.parse(data?.data));
                setTreeData(convertDataToTree(JSON.parse(data?.data)));
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllTrader();
    }, []);

    return (
        <div>
            <div className='react-tree-wrapper'>
                <div className='react-flow-container p-4'>
                    {isLoading ? (
                        <div className='text-center text-muted'> Loading...</div>
                    ) : (
                        <div className='m-3'>
                            <Table columns={columns(openUserDetails)} dataSource={treeData} pagination={false} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ParentTree;

