import React, { useEffect, useState } from 'react';
import columns from '../../../columns/DepositMethod.js';
import TableWithSkeleton from '../../../components/global/table/TableWithSkeleton.jsx';
import Pagination from '../../../components/global/pagination/Pagination.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import { GetPaymentGatewayApi } from '../../../api/request/client.js';

function Deposit() {

  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [data, setData] = useState([]);

  const getPaymentGateway = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetPaymentGatewayApi();
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              status: (<button className='bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover duration-500' onClick={() => navigate(`/deposit_usdt/${item?.id}/${params?.id}`)}>Deposit</button>),
            };
          })
        );
        setTotalRecord(data?.totalItems);
      }
      setIsLoading(false);
    } catch (error) {
      setData([]);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getPaymentGateway();
  }, [])

  return (
    <div>
      <div className='border-[2px] border-light rounded-[30px] pt-4 lg:pt-6 w-full'>
        <div className='px-4 lg:px-6'>
          <p className='text-[#2F2B3D] text-xl font-semibold'>Deposit Method</p>
        </div>
        <div className='my-6'>
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
          <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div>
      </div>
    </div>
  )
}

export default Deposit
