const columns = [
  {
    title: 'Template Name',
    dataIndex: 'templateName',
    // sorter: (a, b) => a?.templateName?.localeCompare(b?.templateName),
  },
  {
    title: 'Email Subject',
    dataIndex: 'subject',
    // sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
  },
  {
    title: 'Created At',
    dataIndex: 'templateDetailsCreatedDate',
    // sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
  },
];

export default columns;  