const columns = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "Level",
        dataIndex: "level",
        key: "level",
    },
    {
        title: "Referral Code",
        dataIndex: "referralCode",
        key: "referralCode",
    },
    {
        title: "Affiliate Code",
        dataIndex: "affiliateCode",
        key: "affiliateCode",
    },
    {
        title: "User Type",
        dataIndex: "userType",
        key: "userType",
    },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
    }
];

export default columns;