const columns = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => ({
            children: text,
            props: {
                rowSpan: record.rowSpan,
            },
        }),
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (text, record) => ({
            children: text,
            props: {
                rowSpan: record.rowSpan,
            },
        }),
    },
    {
        title: "Accounts",
        children: [
            {
                title: "Plan Name",
                dataIndex: "planName",
                key: "planName",
            },
            {
                title: "Commission",
                dataIndex: "commission",
                key: "commission",
            },
            {
                title: "Group",
                dataIndex: "groupName",
                key: "groupName",
            },
        ]
    },
    {
        title: "Level",
        dataIndex: "level",
        key: "level",
        render: (text, record) => ({
            children: text,
            props: {
                rowSpan: record.rowSpan,
            },
        }),
    },
    {
        title: "Referral Code",
        dataIndex: "referralCode",
        key: "referralCode",
        render: (text, record) => ({
            children: text,
            props: {
                rowSpan: record.rowSpan,
            },
        }),
    },
    {
        title: "Affiliate Code",
        dataIndex: "affiliateCode",
        key: "affiliateCode",
        render: (text, record) => ({
            children: text,
            props: {
                rowSpan: record.rowSpan,
            },
        }),
    },
    {
        title: "IB Type",
        dataIndex: "ibType",
        key: "ibType",
        render: (text, record) => ({
            children: text,
            props: {
                rowSpan: record.rowSpan,
            },
        }),
    }
];

export default columns;