import React, { useState, useEffect } from 'react';
import { Select, Input, Checkbox, Form, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { DepositAmountApi, GetPaymentGatewayByIdApi, GetWalletApi } from '../../../api/request/client.js';
import LoadableButton from '../../../components/buttons/LoadableButton.jsx';

export default function DepositUSDT() {

    const [form] = Form.useForm();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({ amount: '', currency: null, conditions: false });
    const [wallet, setWallet] = useState({});
    const [paymentGateway, setPaymentGateway] = useState({});
    const [isWalletLoading, setIsWalletLoading] = useState(false);

    // const getWallet = async () => {
    //     try {
    //         setIsWalletLoading(true);
    //         let params = {
    //             RuleType: 'Deposit'
    //         }
    //         const { data } = await GetWalletApi(params?.id, new URLSearchParams(params).toString());
    //         if (data?.success) {
    //             setWallet(data?.data)
    //         }
    //         setIsWalletLoading(false);
    //     } catch (error) {
    //         setIsWalletLoading(false);
    //     }
    // }

    const getWallet = async () => {
        try {
          let apiParams = {
            RuleType: 'Deposit'
          }
          const { data } = await GetWalletApi(params?.id, new URLSearchParams(apiParams).toString());
    
          if (data?.success) {
            setWallet(data?.data)
          }
        } catch (error) { }
      }

    const getPaymentGateway = async () => {
        try {
            setIsWalletLoading(true);
            let apiParams = {
                PaymentGatewayId: params?.paymentId
            }
            const { data } = await GetPaymentGatewayByIdApi(new URLSearchParams(apiParams).toString());
            if (data?.success) {
                setPaymentGateway(data?.data);
            }
            setIsWalletLoading(false);
        } catch (error) {
            setIsWalletLoading(false);
        }
    }

    useEffect(() => {
        getWallet();
        getPaymentGateway();
    }, []);

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            const depositParams = {
                userId: params?.id,
                paymentGatwayId: params?.paymentId,
                amount: Number(values?.amount),
                currency: values?.currency,
            };

            const { data } = await DepositAmountApi(depositParams);
            if (data?.success) {
                toast.success(data?.message);
                form.resetFields();
                window.open(data?.data?.redirectUrl, '_blank', 'noreferrer');
            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.error?.message) {
                toast.error(error?.response?.data?.error?.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <div>
            <div className='border-[2px] border-light rounded-[20px] w-full'>
                {/* <div className='lg:p-6 p-4'>
                    <div className='p-4 grid sm:grid-cols-3 lg:grid-cols-5 gap-5 lg:divide-x'>
                        <div>
                            <div className='flex justify-center'>
                                <p className='text-base font-normal text-center pb-2'>Transaction fees(%)</p>
                            </div>
                            <p className='text-xl primary font-semibold text-center mt-2'>2</p>
                        </div>
                        <div>
                            <div className='flex justify-center'>
                                <p className='text-base font-normal text-center pb-2 '>Processing Time</p>
                            </div>
                            <p className='text-xl primary font-semibold text-center mt-2'>24 Hours</p>
                        </div>
                        <div>
                            <div className='flex justify-center'>
                                <p className='text-base font-normal text-center pb-2 '>Currencies</p>
                            </div>
                            <p className='text-xl primary font-semibold text-center mt-2'>USD</p>
                        </div>
                        <div>
                            <div className='flex justify-center'>
                                <p className='text-base font-normal text-center pb-2 '>Minimum Deposit</p>
                            </div>
                            <p className='text-xl primary font-semibold text-center mt-2'>$ 10</p>
                        </div>
                        <div>
                            <div className='flex justify-center'>
                                <p className='text-base font-normal text-center pb-2 '>Maximum Deposit</p>
                            </div>
                            <p className='text-xl primary font-semibold text-center mt-2'>$ 500</p>
                        </div>
                    </div>
                </div> */}
                {isWalletLoading ? (
                    <div className="flex justify-center flex-col my-6">
                        <Spin size="large" />
                        <p className="primary text-center mt-2">Loading...</p>
                    </div>
                ) : (
                    <div className='p-4 mb-3 grid sm:grid-cols-3 lg:grid-cols-5 gap-5 lg:divide-x'>
                        <div>
                            <div className='flex justify-center'>
                                <p className='text-base font-normal text-center pb-2'>Transaction fees(%)</p>
                            </div>
                            <p className='text-xl primary font-semibold text-center mt-2'>{paymentGateway?.fees}</p>
                        </div>
                        <div>
                            <div className='flex justify-center'>
                                <p className='text-base font-normal text-center pb-2 '>Processing Time</p>
                            </div>
                            <p className='text-xl primary font-semibold text-center mt-2'>{paymentGateway?.processing_Time}</p>
                        </div>
                        <div>
                            <div className='flex justify-center'>
                                <p className='text-base font-normal text-center pb-2 '>Currencies</p>
                            </div>
                            <p className='text-xl primary font-semibold text-center mt-2'>{paymentGateway?.currency}</p>
                        </div>
                        <div>
                            <div className='flex justify-center'>
                                <p className='text-base font-normal text-center pb-2 '>Minimum Deposit</p>
                            </div>
                            <p className='text-xl primary font-semibold text-center mt-2'>$ {wallet?.minAmount || '0'}</p>
                        </div>
                        <div>
                            <div className='flex justify-center'>
                                <p className='text-base font-normal text-center pb-2 '>Maximum Deposit</p>
                            </div>
                            <p className='text-xl primary font-semibold text-center mt-2'>$ {wallet?.maxAmount || '0'}</p>
                        </div>
                    </div>
                )}
            </div>

            <div className='border-[2px] border-light rounded-[20px] pt-4 lg:pt-6 w-full mt-5'>
                <div className='flex items-center border-b border-[#8D5AE233] px-4 lg:px-6 pb-4'>
                    <img src='/assets/icons/zaro-pay.svg' alt='icon' />
                    <p className='text-[#2F2B3D] text-xl font-semibold ml-3'>Zaro Pay</p>
                </div>

                <Form className='w-full lg:p-6 p-4' autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
                    <div className='md:flex md:flex-wrap gap-5'>
                        <div className="md:w-1/3 input-white">
                            <label className='text-lg text-[#2F2B3DCC] font-medium'>Amount In USD</label>
                            <Form.Item name='amount' rules={[{ required: true, message: 'Please enter amount.' }, { validator: (_, value) => Number.isInteger(Number(value)) ? Promise.resolve() : Promise.reject(new Error('Please enter a valid amount.')) }]}>
                                <Input type='number' placeholder="Amount in USD" className="mt-1 w-full ps-4 py-2" />
                            </Form.Item>
                        </div>
                        <div className="md:w-1/3 ant-select-selector-white">
                            <label className='text-lg text-[#2F2B3DCC] font-medium'>Crypto Currency</label>
                            <Form.Item name='currency' rules={[{ required: true, message: 'Please select a currency.' }]}>
                                <Select className='w-full mt-1 border-primary' placeholder='Select a currency' options={[{ value: 'USDT_TRC20', label: 'USDT' }]} />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item name='conditions' valuePropName="checked" rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Please accept the terms and conditions.')), }]} >
                        <Checkbox className='mt-4'>Yes, I agreed to the <Link to={'/terms_&_conditions'} className='primary hover:underline duration-300' >Terms and conditions</Link></Checkbox>
                    </Form.Item>
                    <div className="md:w-1/4 mt-4">
                        <LoadableButton
                            className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover duration-500'
                            disabled={isLoading}
                            type="Submit"
                            lable='Submit'
                            isLoading={isLoading}
                            loadingLable='Adding request...'
                        />
                    </div>
                </Form>
            </div>
        </div>
    )
}
