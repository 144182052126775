import React, { useState, useEffect } from "react";
import { Form, Input, Spin } from "antd";
import toast from "react-hot-toast";
import { OtpApi, SendOtpApi } from "../../api/request/users";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setUserDetails, setloggedIn } from "../../store/Slices/userSlice";
import { setCookies } from "../../services/Cookies.js";
import LoadableButton from "../../components/buttons/LoadableButton";
import ConfirmationModal from "../../components/global/modal/ConfirmationModal.jsx";
import {IpProvider} from '../../services/IpProvider.js';

function Otp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [timer, setTimer] = useState(120);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOtp, setIsLoadingotp] = useState(false);
  const [FormData, setFormData] = useState({ otp: "" });
  const [ipAddress, setIpAddress] = useState('');

  const formatTime = (timer) => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const getIpAddress = async () => {
    try {
      const { data } = await IpProvider();
      setIpAddress(data?.ip);
    } catch (error) { }
  }

  useEffect(() => {
    setInterval(() => {
      setTimer(current => current !== 0 ? current - 1 : 0);
    }, 1000);
    getIpAddress();
  }, []);

  const multipleLoginConfirm = () => {
    setIsOpen(false);
    handleSubmit(null, true);
  }

  const handleSubmit = async (values, allowMutiLogin) => {
    const { otp } = FormData;
    if (!otp) {
      toast.error("OTP can not be empty");
      return;
    }
    try {
      setIsLoading(true)
      let params = {
        userId: localStorage.getItem('userId'),
        otp: (FormData.otp).toString(),
        loginType: "web",
        allowMutiLogin: allowMutiLogin ? allowMutiLogin : false,
        ipAddress: ipAddress
      }
      const { data } = await OtpApi(params);
      if (data?.error?.foundMultipleLogin) {
        setIsOpen(true);
      } else {
        setCookies('token', data?.data?.access_token);
        dispatch(setloggedIn(true));
        let user = data?.data;
        delete user?.access_token;
        dispatch(setUserDetails(user));
        localStorage.clear('userId');
        navigate("/dashboard");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false)
      toast.error(error?.response?.data?.error?.message || "Something went wrong");
    }
  };

  const onChange = (e) => {
    const newValue = e.target ? e.target.value : e;
    setFormData({ ...FormData, otp: newValue });
  };

  const sharedProps = {
    onChange,
  };

  const resendOTPHandler = async () => {
    try {
      setIsLoadingotp(true)
      const { data } = await SendOtpApi(localStorage.getItem('userId'));
      if (data?.success) {
        toast.success(data?.message);
        setTimer(120);
      }
      setIsLoadingotp(false)
    } catch (error) {
      setIsLoadingotp(false)
      toast.error(error?.response?.data?.error?.message);
    }
  }

  return (
    <>
      <div className='sm:grid grid-cols-12 justify-items-center min-h-screen'>
        <div className='col-span-7 h-full'>
          <div className="flex h-full">
            <img src="/assets/images/login_banner.png" alt="login" className='object-contain p-5 my-auto' />
          </div>
        </div>
        <div className='md:col-span-4 col-span-5 flex flex-col bg-[#FFFFFF] justify-center items-center px-5 lg:px-10 py-10 gap-6 w-full'>
          <div>
            <img src='/assets/logo/logo.png' alt='logo' className='h-10 w-48 object-contain ' />
          </div>
          <div className="text-center">
            <p className="text-[#2F2B3D] text-2xl font-semibold auth-heading">
              Welcome to Neptune Admin!
            </p>
            <p className="text-[silver] text-sm font-medium auth-com mt-2  ">
              Please enter the OTP and verify yourself
            </p>
          </div>
          <div className="w-full">
            <Form autoComplete="off" onFinish={handleSubmit} >
              <div className="w-full text-sm py-1">
                <div className="relative">
                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input.OTP
                      variant="filled"
                      {...sharedProps}
                      value={FormData.otp}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>

                </div>
              </div>
              <div className="mt-8">
                <LoadableButton type='submit' className='bg-primary text-[#FFFFFF] font-semibold text-lg rounded-lg w-full py-2' lable='Verify' isLoading={isLoading} loadingLable='Verifying...' />
              </div>
            </Form>
            <div>
              {isLoadingOtp ? (
                <div className="flex justify-center flex-col my-[8%]">
                  <Spin size="large" />
                  <p className="primary text-center mt-2">Loading...</p>
                </div>
              ) : (
                <div className="resend mt-3">

                  {
                    timer == 0 ? (<button onClick={resendOTPHandler}>Resend OTP</button>) : (<p>Resend OTP in {formatTime(timer)}</p>)
                  }
                </div>
              )}
            </div>

          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        message='User is already logged in on the website. Please log out before attempting to log in again.'
        onConfirm={multipleLoginConfirm}
      />
    </>
  );
}

export default Otp;
