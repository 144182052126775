import React, { useState, useEffect } from "react";
import { Form, ColorPicker, Input, Upload, Image } from "antd";
import columns from "../../../columns/salesDashboard/source.js";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
import { GetAllSourceApi, AddSourceApi, DeleteSourceApi } from "../../../api/request/salesDashboard/source.js";
import toast from "react-hot-toast";
import moment from "moment";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal.jsx";
import Edit from "../../../assets/Edit.jsx";

export default function Source() {

  const [form] = Form.useForm();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isAddSourceLoading, setIsAddSourceLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const [deleteSourceModal, setDeleteSourceModal] = useState(false);
  const [isDeleteSourceLoading, setIsDeleteSourceLoading] = useState(false);
  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState({ Name: '', ColorCode: '', UploadImage: [] });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);

  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    form.setFieldsValue({ UploadImage: newFileList });
    if (newFileList?.length == 0) {
      form.setFieldsValue({ UploadImage: [] });
    }
  }

  const UploadButton = (
    <button type="button">
      <div className="flex justify-center"><img src="assets/icons/Upload_Document_icon.svg" alt="icon" /></div>
      Upload
    </button>
  );

  const openEditModal = (source) => {
    console.log(source);
    setIsUpdate(true);
    setSelectedSource(source);
    form.setFieldsValue({ Name: source?.name, ColorCode: source?.colorCode, UploadImage: [source?.sourceUrl] });
    setFileList([{ uid: '1', url: source?.sourceUrl, status: 'done' }]);
    setIsOpenModal(true);
  }

  const getAllSource = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetAllSourceApi();
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              createdDate: (item?.createdDate ? moment(item?.createdDate).format("DD-MM-YYYY, hh:mm A") : null),
              actions: (<div className="flex items-center gap-3">
                <button onClick={() => openEditModal(item)}><Edit /></button>
                <button onClick={() => { setSelectedSource(item); setDeleteSourceModal(true) }}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D91819" className="w-6 h-6 cursor-pointer hover:stroke-[#aa0001] duration-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                  </svg>
                </button>
              </div>)
            };
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAllSource();
  }, []);

  const handleSubmit = async (values) => {
    const extractOriginFileObjs = (fileArray) => {
      if (fileArray.map(file => file.originFileObj)) {
        return fileArray.map(file => file.originFileObj);
      } else {
        return null;
      }
    };
    const rgbToHex = (r, g, b) => {
      const toHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      };
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };
    const isValidHexColor = (color) => {
      const hexColorPattern = /^#([0-9A-Fa-f]{3}){1,2}$/;
      return hexColorPattern.test(color);
    };
    try {
      setIsAddSourceLoading(true);
      console.log(values);
      let formData = new FormData();
      let colorCode;
      if (isValidHexColor(values?.ColorCode)) {
        colorCode = values?.ColorCode;
      } else {
        let { r, g, b } = values?.ColorCode?.metaColor;
        colorCode = rgbToHex(r, g, b);
      }
      if (isUpdate) {
        formData.append("Id", selectedSource?.id);
        formData.append("Name", values?.Name);
        formData.append("ColorCode", colorCode);
        extractOriginFileObjs(values?.UploadImage)?.map((file) => {
          if (file) {
            formData.append("UploadImage", file);
          }
        })
      } else {
        formData.append("Name", values?.Name);
        formData.append("ColorCode", colorCode);
        extractOriginFileObjs(values?.UploadImage)?.map((file) => {
          formData.append("UploadImage", file);
        })
      }
      let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
      const { data } = await AddSourceApi(formData, apiHeader);
      if (data?.success) {
        toast.success(data?.message);
        form.resetFields();
        getAllSource();
        setFileList([]);
        setIsAddSourceLoading(false);
        setIsOpenModal(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error?.message);
      setIsAddSourceLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpenModal) {
      form.resetFields();
      setFileList([]);
      setInitialValues({ name: '' });
    }
  }, [isOpenModal]);

  const deleteSourceHandler = async () => {
    try {
      setIsDeleteSourceLoading(true);
      const { data } = await DeleteSourceApi(selectedSource?.id);
      if (data?.success) {
        toast.success(data?.message);
        setDeleteSourceModal(false);
        getAllSource();
      }
      setIsDeleteSourceLoading(false);
    } catch (err) {
      setIsDeleteSourceLoading(false);
    }
  }

  return (
    <div className="max-w-full">
      <div className="flex justify-end flex-wrap gap-5">
        <div className="flex items-center gap-4">
          <button
            className="flex items-center bg-primary text-sm text-white font-semibold px-4 py-2 rounded-lg themeHover  duration-500"
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                fill-rule="evenodd"
                d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                clip-rule="evenodd"
              />
            </svg>
            Add Source
          </button>
        </div>
      </div>

      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">All Sources</p>
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
        </div>
      </div>

      {/* ---- Add source modal ---- */}
      <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} title={`${isUpdate ? 'Update' : 'Add'} source`}>
        <Form className="w-full" autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
          <div className="input-white">
            <label className="text-base text-[#2F2B3DCC] font-medium">Source Name</label>
            <Form.Item name="Name" rules={[{ required: true, message: "Please enter source name." }]}>
              <Input placeholder="Source name" className="mt-1 w-full py-2" value={initialValues.Name} />
            </Form.Item>
          </div>
          <div className="input-white mt-4">
            <label className="text-base text-[#2F2B3DCC] font-medium">Color</label>
            <Form.Item name="ColorCode" rules={[{ required: true, message: "Please enter color code." }]}>
              <ColorPicker showText className="w-full text-left py-2" value={initialValues.ColorCode} />
            </Form.Item>
          </div>
          <div className="mt-4">
            <label className="text-base text-[#2F2B3DCC] font-medium">Upload Icon</label>
            <Form.Item name="UploadImage" rules={[{ required: true, message: 'Please upload icon.' }]}>
              <Upload
                beforeUpload={() => false}
                listType="picture-card"
                className="mt-1"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : UploadButton}
              </Upload>
            </Form.Item>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </div>
          <div className="flex justify-end mt-8">
            <LoadableButton
              className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
              disabled={isAddSourceLoading}
              type="Submit"
              lable="Submit"
              isLoading={isAddSourceLoading}
              loadingLable={`${isUpdate ? 'Updating' : 'Adding'} source...`}
            />
          </div>
        </Form>
      </ModalComponent>

      {/* ---- Delete source modal ---- */}
      <ConfirmationModal
        isOpen={deleteSourceModal}
        setIsOpen={setDeleteSourceModal}
        message='Are you sure you want to remove this source?'
        onConfirm={deleteSourceHandler}
        isLoading={isDeleteSourceLoading}
        loadingLabel='Removing tutorial...'
      />
    </div>
  )
}