import React, { useState, useEffect } from "react";
import { Form, Select, Input, Switch } from "antd";
import columns from "../../../columns/settings/teams.js";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import { GetAllUserApi, AddUserApi, UpdateUserStatusApi } from "../../../api/request/settings/teams.js";
import { GetAllRole } from "../../../api/request/settings/role.js"
import { GetAllCountryApi } from "../../../api/request/common.js";
import toast from "react-hot-toast";
import moment from "moment";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal.jsx";
import ExportHandler from "../../../components/global/export/ExportHandler.jsx";

export default function Teams() {

    const [form] = Form.useForm();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isAddTraderLoading, setIsAddTraderLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [search, setSearch] = useState("");
    const [searchText, setSearchText] = useState("");
    const [countryFilter, setCountryFilter] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [countriesForFilter, setCountriesForFilter] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
    const [isStatusConfirmLoading, setIsStatusConfirmLoading] = useState(false);
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [initialValues, setInitialValues] = useState({ firstName: "", lastName: "", email: "", phoneNo: "", countryId: null, role: null });

    let aditionColumns = [
        {
            title: 'Country',
            dataIndex: 'countryName',
            filters: countriesForFilter,
            filterSearch: true,
            onFilter: (value, record) => { return record.countryName },
            filteredValue: countryFilter ? countryFilter : null,
            render: (text) => text || '--',
        },
    ];

    const handleFilterChange = (pagination, filters, sorter) => {
        setCountryFilter(filters.countryName ? filters.countryName : []);
    };

    const getRoles = async () => {
        try {
            const { data } = await GetAllRole();
            if (data?.success) {
                setRoles(
                    data?.data?.map((item) => ({
                        ...item,
                        value: item?.id,
                        label: item?.name,
                    }))
                );
            }
        } catch (error) { }
    };

    const exportHandler = async () => {
        try {
            let params = {
                ApplyPagination: false,
                CountryName: countryFilter,
                SearchText: searchText.toLowerCase().replace(/\s/g, '')
            };
            const { data } = await GetAllUserApi(new URLSearchParams(params).toString());
            if (data?.success) {
                return { data: data?.data, removableColumns: ['userId'], fileName: 'Teams' };
            } else {
                return { data: [], removableColumns: [] };
            }
        } catch (error) { }
    };

    const getContries = async () => {
        try {
            const { data } = await GetAllCountryApi();
            if (data?.success) {
                setCountries(
                    data?.data?.map((item) => ({
                        ...item,
                        value: item?.id,
                        label: item?.countryName,
                    }))
                );
                setCountriesForFilter(
                    data?.data?.map((item) => ({
                        text: item?.countryName,
                        value: item?.countryName,
                    }))
                );
            }
        } catch (error) { }
    };

    const getAllUsers = async () => {
        try {
            setIsLoading(true);
            let params = {
                PageNumber: page,
                PageSize: pageSize,
                CountryName: countryFilter,
                SearchText: searchText.toLowerCase().replace(/\s/g, '')
            };
            const { data } = await GetAllUserApi(new URLSearchParams(params).toString());
            if (data?.success) {
                setData(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            name: `${item?.firstName} ${item?.lastName}`,
                            createdDate: (item?.createdDate ? moment(item?.createdDate).format("DD-MM-YYYY, hh:mm A") : null),
                            isActive: (item?.isActive ? (item?.user ? item?.user : 'Yes') : 'No'),
                            status: (<Switch onChange={() => statusHandler(item)} checkedChildren="On" unCheckedChildren="Off" checked={item?.isActive} />),
                        };
                    })
                );
                setTotalRecord(data?.totalRecords);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAllUsers();
    }, [page, pageSize, searchText, countryFilter]);

    useEffect(() => {
        setPage(1);
    }, [searchText, countryFilter]);

    useEffect(() => {
        getContries();
    }, []);

    const statusHandler = async (user) => {
        setSelectedUser(user);
        setIsStatusConfirmOpen(true);
    }

    const joinStrings = (array) => {
        return array.filter(item => item).join(', ');
    }

    const handleSubmit = async (value) => {
        try {
            setIsAddTraderLoading(true);
            const { data } = await AddUserApi(value);
            if (data?.success) {
                toast.success(data?.message);
                form.resetFields();
                getAllUsers();
                setIsAddTraderLoading(false);
                setIsOpenModal(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsAddTraderLoading(false);
        }
    };

    const filterOption = (input, option) => {
        return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    };

    const statusChangeConfirm = async () => {
        try {
            setIsStatusConfirmLoading(true);
            let params = {
                userId: selectedUser?.userId,
                isActive: !selectedUser?.isActive
            }

            const { data } = await UpdateUserStatusApi(params);
            if (data?.success) {
                getAllUsers();
                toast.success(data?.message);
            }

            setIsStatusConfirmLoading(false);
            setIsStatusConfirmOpen(false);
        } catch (error) {
            setIsStatusConfirmLoading(false);
        }
    }

    return (
        <div className="max-w-full">
            <div className="flex justify-end flex-wrap gap-5">
                <div className="flex items-center flex-wrap gap-4">
                    <Input
                        size="large"
                        style={{ width: 220 }}
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onPressEnter={() => setSearchText(search)}
                        prefix={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                class="w-5 h-5"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        }
                        suffix={
                            search && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => {
                                        setSearchText("");
                                        setSearch("");
                                    }}
                                    viewBox="0 0 24 24"
                                    fill="#bebebe"
                                    className="size-5 cursor-pointer duration-300 hover:fill-[#969595]"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            )
                        }
                    />
                    <div className="flex items-center gap-3">
                        <button
                            className="flex items-center bg-primary text-sm text-white font-semibold px-4 py-2 rounded-lg themeHover  duration-500"
                            onClick={() => {
                                getRoles();
                                setIsOpenModal(true);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-5 h-5 mr-2"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                            Add Team Member
                        </button>
                        <ExportHandler exportHandler={exportHandler} />
                    </div>
                </div>
            </div>

            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">All Users</p>
                </div>
                <div className="my-6">
                    <TableWithSkeleton columns={[...columns, ...aditionColumns]} data={data} loading={isLoading} onChange={handleFilterChange} />
                    <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
                </div>
            </div>

            {/* ---- Add user modal ---- */}
            <ModalComponent
                isOpen={isOpenModal}
                setIsOpen={setIsOpenModal}
                title="Add User"
                width={1200}
            >
                <Form
                    className="w-full"
                    autoComplete="off"
                    form={form}
                    initialValues={initialValues}
                    onFinish={handleSubmit}
                >
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">
                                First Name
                            </label>
                            <Form.Item
                                name="firstName"
                                rules={[
                                    { required: true, message: "Please enter first name." },
                                ]}
                            >
                                <Input placeholder="First name" className="mt-1 w-full py-2" />
                            </Form.Item>
                        </div>
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">
                                Last Name
                            </label>
                            <Form.Item
                                name="lastName"
                                rules={[{ required: true, message: "Please enter last name." }]}
                            >
                                <Input placeholder="Last name" className="mt-1 w-full py-2" />
                            </Form.Item>
                        </div>
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">
                                Primary Email
                            </label>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: "Please enter valid email.",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Primary email"
                                    className="mt-1 w-full py-2"
                                />
                            </Form.Item>
                        </div>
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">
                                Phone No.
                            </label>
                            <Form.Item
                                name="phoneNo"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter valid phone number.",
                                    },
                                ]}
                            >
                                <Input
                                    type="number"
                                    placeholder="Phone no."
                                    className="mt-1 w-full py-2"
                                />
                            </Form.Item>
                        </div>
                        <div className="ant-select-selector-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">
                                Country
                            </label>
                            <Form.Item
                                name="countryId"
                                rules={[
                                    { required: true, message: "Please select a country." },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a country"
                                    className="w-full mt-1"
                                    filterOption={filterOption}
                                    options={countries}
                                />
                            </Form.Item>
                        </div>
                        <div className="ant-select-selector-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">
                                Role
                            </label>
                            <Form.Item
                                name="roleId"
                                rules={[{ required: true, message: "Please select a role." }]}
                            >
                                <Select
                                    placeholder="Select a role"
                                    className="w-full mt-1"
                                    options={roles}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="flex justify-end mt-8">
                        <LoadableButton
                            className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
                            disabled={isAddTraderLoading}
                            type="Submit"
                            lable="Submit"
                            isLoading={isAddTraderLoading}
                            loadingLable="Adding trader..."
                        />
                    </div>
                </Form>
            </ModalComponent>

            <ConfirmationModal
                isOpen={isStatusConfirmOpen}
                setIsOpen={setIsStatusConfirmOpen}
                message='Are you sure to change the user status?'
                onConfirm={statusChangeConfirm}
                isLoading={isStatusConfirmLoading}
                loadingLabel='Changing status...'
            />
        </div>
    )
}