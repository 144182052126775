import React, { useState, useEffect } from "react";
import { Switch, Input, Form, Spin, Select, Avatar, Tabs, Menu } from "antd";
import columns from "../../../columns/AccountDetails.js";
import assignRulesColumns from "../../../columns/AssignRules.js";
import assignableRuleListColumns from "../../../columns/AssignableRuleList.js";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx";
import { DownOutlined } from "@ant-design/icons";
import { GetTraderByIdApi, GetTraderAccountsByIdApi, GetuserpasswordApi, UpdateTraderApi, UnassignUserRuleApi, SendIBRequestApi, GetAssignableRulesApi, AssignUserRuleApi, GetIBUserDetailsApi, Verificationemaillink, UpdateMT5AccountLeverageApi, UpdateMT5AccountPasswordApi, VerifyLoginPasswordApi, AddMT5AccountbalanceApi } from '../../../api/request/client.js';
import { GetAllKycByIdApi, } from '../../../api/request/kyc.js'
import moment from "moment/moment.js";
import { useSelector } from 'react-redux';
import toast from "react-hot-toast";
import Eye from "../../../assets/Eye.jsx";
import { useParams } from 'react-router-dom';
import { GetAllCountryApi } from "../../../api/request/common.js";
import { UpdateIBStatusApi } from "../../../api/request/client.js";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
import Lock from "../../../assets/auth/Lock";
import MySegmented from "../../../components/segmented/Segmented.jsx";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal.jsx";
import Redirect from "../../../assets/Redirect.jsx";
import { Dropdown, Space } from 'antd';
import SendCredentials from "../components/SendCredentials";
import SendNotification from "../components/SendNotification";
import MT5LoginDetails from "../components/MT5LoginDetails";
import ChangePassword from "../components/ChangePassword";
import Copy from '../../../components/copy/Copy.jsx';
import UpdateIB from "./UpdateIB.jsx";
import AssignNewPlanToIB from "./AssignNewPlanToIB.jsx"

export default function ClientDetails() {

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [leverageForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [balanceForm] = Form.useForm();
  const [loginPasswordForm] = Form.useForm();
  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--theme'))?.trim();
  const { userDetails } = useSelector(state => state.user);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { id } = useParams();
  const [isAccTableLoading, setIsAccTableLoading] = useState(false);
  const [isRulesTableLoading, setIsRulesTableLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isKycLoading, setIsKycLoading] = useState(false);
  const [trader, setTrader] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isEditTraderLoading, setIsEditTraderLoading] = useState(false);
  const [data, setData] = useState([]);
  const [kycDetails, setKycDetails] = useState({})
  const [formData, setFormData] = useState({ password: '' });
  const [newpassword, setNewpassword] = useState('');
  const [countries, setCountries] = useState([]);
  const [initialValues, setInitialValues] = useState({ firstName: "", lastName: "", email: "", phoneNo: "", countryId: null, roleId: '', });
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [ruleData, setRuleData] = useState([]);
  const [selectedRule, setSelectedRule] = useState();
  const [isLoadingPassModal, setIsLoadingPassModal] = useState(false);
  const [isOpenUnassignModal, setIsOpenUnassignModal] = useState(false);
  const [isRuleUnassignLoading, setIsRuleUnassignLoading] = useState(false);
  const [isOpenRuleListModal, setIsOpenRuleListModal] = useState(false);
  const [assignableRuleList, setAssignableRuleList] = useState([]);
  const [isLoadingRuleListModal, setIsLoadingRuleListModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isRuleAssignLoading, setIsRuleAssignLoading] = useState(false);
  const [filter, setFilter] = useState('Live Account');
  const [accounts, setAccounts] = useState([]);
  const [isLoadingIbDetails, setIsLoadingIbDetails] = useState(false);
  const [ibDetails, setIbDetails] = useState(null);
  const [isOpenModalnew, setIsOpenModalnew] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [mT5LoginDetails, setmT5LoginDetails] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [isOpenIBModal, setisOpenIBModal] = useState(false);
  const [isAssignIBPlan, setIsAssignIBPlan] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
  const [isStatusConfirmLoading, setIsStatusConfirmLoading] = useState(false);
  const [Isverification, setIsverification] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [leverageInitialValues, setLeverageInitialValues] = useState({ leverage: null });
  const [balanceInitialValues, setBalanceInitialValues] = useState({ balance: null });
  const [isOpenLeverageForm, setIsOpenLeverageForm] = useState(false);
  const [selectedLeverage, setSelectedLeverage] = useState(null);
  const [isOpenLeverageConfirm, setIsOpenLeverageConfirm] = useState(false);
  const [isLeverageFormLoading, setIsLeverageFormLoading] = useState(false);
  const [isbalanceFormLoading, setIsbalanceFormLoading] = useState(false);
  const [leverageOptions, setLeverageOptions] = useState([{ value: 20, label: '20' }, { value: 50, label: '50' }, { value: 100, label: '100' }, { value: 200, label: '200' }, { value: 300, label: '300' }, { value: 400, label: '400' }, { value: 500, label: '500' }]);
  const [BalanceOptions, setBalanceOptions] = useState([{ value: 20, label: '20' }, { value: 50, label: '50' }, { value: 100, label: '100' }, { value: 200, label: '200' }, { value: 300, label: '300' }, { value: 400, label: '400' }, { value: 500, label: '500' }]);
  const [isOpenPasswordForm, setIsOpenPasswordForm] = useState(false);
  const [passwordInitialValues, setPasswordInitialValues] = useState({ pType: null, newPassword: '', confirmPassword: '' });
  const [loginPasswordInitialValues, setLoginPasswordInitialValues] = useState({ currentPassword: '' });
  const [isPasswordFormLoading, setIsPasswordFormLoading] = useState(false);
  const [isOpenPasswordConfirmationModal, setIsOpenPasswordConfirmationModal] = useState(false);
  const [isLoadingPasswordConfirmation, setIsLoadingPasswordConfirmation] = useState(false);
  const [passwordValue, setPasswordValue] = useState(null);
  const [isOpenAddbalanceForm, setIsOpenAddbalanceForm] = useState(false);
  const [isOpenDocModal, setIsOpenDocModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIbReqLoading, setIsIbReqLoading] = useState(false);

  const items = [
    {
      key: '2',
      label: <button className="w-full text-left" onClick={() => navigate(`/parent_tree/${id}`)}> Parent Tree</button>
    },
    {
      key: '3',
      label: <button className="w-full text-left" onClick={() => navigate(`/mt5_account/${id}`)}>Create MT5 Account</button>
    },
    {
      key: '4',
      label: <button className="w-full text-left" onClick={() => navigate(`/deposit/${id}`)}>Deposit</button>
    },
    {
      key: '5',
      label: <button className="w-full text-left" onClick={() => navigate(`/withdraw/${id}`)}>Withdraw</button>
    },
    {
      key: '6',
      label: <button className="w-full text-left" onClick={() => { setmT5LoginDetails(true) }}> Send & Change MT5 Credentials</button>
    },
    {
      key: '7',
      label: <button className="w-full text-left" onClick={() => { setIsOpenModalnew(true) }}>Send CRM Credentials</button>
    },
    {
      key: '8',
      label: <button className="w-full text-left" onClick={() => { setIsNotification(true) }}>Send Notification </button>
    },
    {
      key: '9',
      label: <button className="w-full text-left" onClick={() => { setChangePassword(true) }}>Change CRM Password</button>
    },
    {
      key: '10',
      label: <button className="w-full text-left" onClick={() => navigate(`/logs/${id}`)}>Logs</button>
    },


  ];

  const actionItemForuser = {
    key: '1',
    label: <button className="w-full text-left" onClick={() => { setisOpenIBModal(true) }}>Update IB</button>
  }

  const actionItemForIB =
    [
      {
        key: '1',
        label: <button className="w-full text-left" onClick={() => navigate(`/ib_tree/${id}`)}>IB Tree</button>
      },

      {
        key: '11',
        label: <button className="w-full text-left" onClick={() => { setIsAssignIBPlan(true) }}>Assign New Plan To IB</button>
      }

    ];

  const demoAccItems = [
    { key: '1', label: (<div onClick={() => setIsOpenLeverageForm(true)}>Leverage Management</div>) },
    { key: '2', label: (<div onClick={() => setIsOpenPasswordForm(true)}>Change Password</div>) },
    { key: '3', label: (<div onClick={() => setIsOpenAddbalanceForm(true)}>Add Balance</div>) }
  ];

  const liveAccItem = [
    { key: '1', label: (<div onClick={() => setIsOpenLeverageForm(true)}>Leverage Management</div>) },
    { key: '2', label: (<div onClick={() => setIsOpenPasswordForm(true)}>Change Password</div>) },
  ];

  const openUnassignModal = async (rule) => {
    setSelectedRule(rule);
    setIsOpenUnassignModal(true);
  }

  const unassignUserRule = async () => {
    try {
      setIsRuleUnassignLoading(true);
      const { data } = await UnassignUserRuleApi([selectedRule?.ruleAssignId]);
      if (data?.success) {
        toast.success(data?.message);
        setIsOpenUnassignModal(false);
        setRuleData((prevRecords) =>
          prevRecords.filter((record) => record.ruleAssignId !== selectedRule?.ruleAssignId)
        );
      }
      setIsRuleUnassignLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsRuleUnassignLoading(false);
    }
  }

  const openRuleList = async () => {
    try {
      setIsOpenRuleListModal(true);
      setIsLoadingRuleListModal(true);
      const { data } = await GetAssignableRulesApi(id);
      if (data?.success) {
        setAssignableRuleList(
          data?.data?.ruleMastersList?.map((item) => {
            return {
              key: JSON.stringify({ ruleId: item?.ruleId, ruleTypeName: item?.ruleTypeName, level: item?.level }),
              ...item,
            };
          })
        );
      }
      setIsLoadingRuleListModal(false);
    } catch (error) {
      setIsLoadingRuleListModal(false);
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const assignUserRule = async () => {
    try {
      setIsRuleAssignLoading(true);
      let params = selectedRowKeys.map((rule) => ({ userId: id, ...(JSON.parse(rule)), }))
      const { data } = await AssignUserRuleApi(params);
      if (data?.success) {
        getTraderById();
        toast.success(data?.message);
        setIsOpenRuleListModal(false);
      }
      setIsRuleAssignLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsRuleAssignLoading(false);
    }
  }

  const handleFieldsChange = (_, allFields) => {
    const isChanged = allFields.some(field => field.touched);
    setIsFormChanged(isChanged);
  };

  const getTraderById = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetTraderByIdApi(id);
      if (data?.success) {
        setInitialValues({ firstName: data?.data?.firstName, lastName: data?.data?.lastName, email: data?.data?.email, phoneNo: data?.data?.phoneNo, countryId: data?.data?.countryName, roleId: data?.data?.isIBUser == 'Yes' ? 'IB' : 'Trader' });
        setTrader(data?.data);
        setRuleData(
          data?.data?.assignRuleList?.map((item) => {
            return {
              ...item,
              actions: (<button className="font-bold text-red-500" onClick={() => openUnassignModal(item)}>Unassign</button>),
            };
          })
        );

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  const getTraderAccounts = async () => {
    try {
      setIsAccTableLoading(true);
      const { data } = await GetTraderAccountsByIdApi(id, filter === "Live Account");
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              balance: `$ ${item?.balance?.toFixed(2)}`,
              createdDate: item?.createdDate ? moment(item?.createdDate).format('DD-MM-YYYY LT') : null,
              actions: (<div className="flex items-center gap-3">
                <Dropdown
                  onClick={() => setSelectedAccount(item)}
                  menu={{ items: item.isDemo === true ? demoAccItems : liveAccItem }}
                  placement="bottomLeft"
                  arrow
                  trigger={['click']}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={primaryColor} className="size-6 cursor-pointer">
                    <path fillRule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 0 0-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 0 0-2.282.819l-.922 1.597a1.875 1.875 0 0 0 .432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 0 0 0 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 0 0-.432 2.385l.922 1.597a1.875 1.875 0 0 0 2.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 0 0 2.28-.819l.923-1.597a1.875 1.875 0 0 0-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 0 0 0-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 0 0-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 0 0-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 0 0-1.85-1.567h-1.843ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" clipRule="evenodd" />
                  </svg>
                </Dropdown>
                {item.isLive === true ? <button type="button" onClick={() => navigate(`/account_details/${item?.login}/${id}`)}><Eye /></button> : null}
              </div>),
            };
          })
        );
      }
      setIsAccTableLoading(false);
    } catch (error) {
      setIsAccTableLoading(false);
    }
  }

  useEffect(() => {
    getTraderAccounts();
  }, [filter])


  const filterAccounts = (type) => {
    const isDemo = type === 'Demo Account';
    setAccounts(data.filter(account => account.isDemo === isDemo));
  };

  useEffect(() => {
    filterAccounts(filter);
  }, [data]);

  useEffect(() => {
    filterAccounts(filter);
  }, [filter]);

  const getContries = async () => {
    try {
      const { data } = await GetAllCountryApi();
      if (data?.success) {
        setCountries(data?.data?.map((item) => ({ ...item, value: item?.id, label: item?.countryName, })));
      }
    } catch (error) { }
  };

  useEffect(() => {
    // getContries();
    getTraderById();
    getKycById();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoadingPassModal(true);
      let params = {
        LoginUserId: userDetails._id,
        UserId: id,
        Paasword: values.password,
      };
      const { data } = await GetuserpasswordApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setNewpassword(data.data)
        setIsOpenModal(false);
      }
      setIsLoadingPassModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsLoadingPassModal(false);
    }
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const getKycById = async () => {
    try {
      setIsKycLoading(true);
      const { data } = await GetAllKycByIdApi(id);
      setKycDetails(data?.data);
      setIsKycLoading(false);
    } catch (error) {
      setIsKycLoading(false);
    }
  }

  const handleEditSubmit = async (value) => {
    try {
      delete value.email;
      delete value.countryId;
      let apiParams = {
        userId: id,
        ...value,
        roleId: value?.roleId == 'IB' ? '4' : '2'
      };
      setIsEditTraderLoading(true);
      const { data } = await UpdateTraderApi(apiParams);
      if (data?.success) {
        toast.success(data?.message);
        setIsFormChanged(false);
        setIsDrawerOpen(false);
        setIsEditTraderLoading(false);
        setIsOpenEditModal(false);
        getTraderById();
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsEditTraderLoading(false);
    }
  };

  const filterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [isOpenRuleListModal]);

  const getIBDetails = async () => {
    if (!trader?.isIBUser == 'Yes') {
      return;
    }
    try {
      setIsLoadingIbDetails(true);
      const { data } = await GetIBUserDetailsApi(id);
      if (data?.data) {
        setIbDetails(data?.data[0]);
      }
      setIsLoadingIbDetails(false);
    } catch (error) {
      setIsLoadingIbDetails(false);
    }
  }

  const statusChangeConfirm = async () => {
    try {
      setIsStatusConfirmLoading(true);
      let params = {
        Status: !selectedUser?.isEnabled
      }

      const { data } = await UpdateIBStatusApi(id, new URLSearchParams(params).toString());
      if (data?.success) {
        toast.success(data?.message);
        getIBDetails();
      }

      setIsStatusConfirmLoading(false);
      setIsStatusConfirmOpen(false);
    } catch (error) {
      setIsStatusConfirmLoading(false);
    }
  }

  const statusHandler = async (user) => {
    setSelectedUser(user);
    setIsStatusConfirmOpen(true);
  }

  const Verificationemail = async () => {
    try {
      setIsverification(true)
      const { data } = await Verificationemaillink(id);
      if (data?.success) {
        toast.success(data?.message);
        setIsverification(false)
      }
    } catch (error) {
      setIsverification(false)
    }
  }

  const getDucumentType = (passport, nationalId, drivingLicense, utilityBill, bankStatement) => {
    if (passport) {
      return 'Passport';
    } else if (nationalId) {
      return 'National Id';
    } else if (drivingLicense) {
      return 'Driving License';
    } else if (utilityBill) {
      return 'Utility Bill';
    } else if (bankStatement) {
      return 'Bank Statement';
    }
  }

  const sendIbRequest = async () => {
    try {
      setIsIbReqLoading(true);
      let params = {
        userId: id,
        isIBUser: true,
      }
      const { data } = await SendIBRequestApi(params);
      if (data?.success) {
        toast.success(data?.message);
        getTraderById();
        setIsIbReqLoading(false);
        setIsModalOpen(false);
      }
      setIsIbReqLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsIbReqLoading(false);
      setIsModalOpen(false);
    }
  }

  const RenderPersonalDetails = () => {
    return (
      <div className="m-6">
        {(isKycLoading) ? (
          <div className="flex justify-center flex-col my-[8%]">
            <Spin size="large" />
            <p className="primary text-center mt-2">Loading...</p>
          </div>
        ) : (
          <div className="w-full grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
            <div className="my-2">
              <p className="text-[#1E232CC7] text-lg">Clients</p>
              <p className="primary text-lg font-semibold mt-2">{trader?.numberofClients || 0}</p>
            </div>
            <div className="my-2">
              <p className="text-[#1E232CC7] text-lg">Working Experience</p>
              <p className="primary text-lg font-semibold mt-2">{trader?.workingExp || 0}</p>
            </div>
            <div className="my-2">
              <p className="text-[#1E232CC7] text-lg">Experience as IB</p>
              <p className="primary text-lg font-semibold mt-2">{trader?.expAsIb || 0}</p>
            </div>
            <div className="my-2">
              <p className="text-[#1E232CC7] text-lg">Date of Birth</p>
              <p className="primary text-lg font-semibold mt-2">{trader?.dob ? moment(trader?.dob).format('ll') : '--'}</p>
            </div>
            <div className="my-2">
              <p className="text-[#1E232CC7] text-lg">Fax</p>
              <p className="primary text-lg font-semibold mt-2">{trader?.fax || '--'}</p>
            </div>
            <div className="my-2">
              <p className="text-[#1E232CC7] text-lg">Telephone</p>
              <p className="primary text-lg font-semibold mt-2">{trader?.telephone || '--'}</p>
            </div>
            <div className="my-2">
              <p className="text-[#1E232CC7] text-lg">Country of Birth</p>
              <p className="primary text-lg font-semibold mt-2">{trader?.countryOfBirthName || '--'}</p>
            </div>
            <div className="my-2">
              <p className="text-[#1E232CC7] text-lg">Country of Citizenship</p>
              <p className="primary text-lg font-semibold mt-2">{trader?.countryOfCitizenshipName || '--'}</p>
            </div>
            <div className="my-2">
              <p className="text-[#1E232CC7] text-lg">City</p>
              <p className="primary text-lg font-semibold mt-2">{trader?.city || '--'}</p>
            </div>
            <div className="my-2">
              <p className="text-[#1E232CC7] text-lg">Recovery Contact</p>
              <p className="primary text-lg font-semibold mt-2">{trader?.recoveryContact || '--'}</p>
            </div>
            <div className="my-2">
              <p className="text-[#1E232CC7] text-lg">Family Name</p>
              <p className="primary text-lg font-semibold mt-2">{trader?.familyName || '--'}</p>
            </div>
            {trader?.skype &&
              <div>
                <p className="text-[#848484] font-normal text-base">Skype</p>
                <div className="flex items-center gap-3">
                  <p className="primary font-semibold text-base mt-1 cursor-pointer hover:underline" onClick={() => openInNewTab(trader?.skype)}>Open Profile</p>
                  <Copy text={trader?.skype} />
                </div>
              </div>
            }
            {trader?.telegram &&
              <div>
                <p className="text-[#848484] font-normal text-base">Telegram</p>
                <div className="flex items-center gap-3">
                  <p className="primary font-semibold text-base mt-1 cursor-pointer hover:underline" onClick={() => openInNewTab(trader?.telegram)}>Open Profile</p>
                  <Copy text={trader?.telegram} />
                </div>
              </div>
            }
            {trader?.facebook &&
              <div>
                <p className="text-[#848484] font-normal text-base">Facebook</p>
                <div className="flex items-center gap-3">
                  <p className="primary font-semibold text-base mt-1 cursor-pointer hover:underline" onClick={() => openInNewTab(trader?.facebook)}>Open Profile</p>
                  <Copy text={trader?.facebook} />
                </div>
              </div>
            }
            {trader?.linkedIn &&
              <div>
                <p className="text-[#848484] font-normal text-base">LinkedIn</p>
                <div className="flex items-center gap-3">
                  <p className="primary font-semibold text-base mt-1 cursor-pointer hover:underline" onClick={() => openInNewTab(trader?.linkedIn)}>Open Profile</p>
                  <Copy text={trader?.linkedIn} />
                </div>
              </div>
            }
          </div>
        )}
      </div>
    );
  }

  const RenderKYCDetails = () => {
    return (
      <div>
        {(isKycLoading) ? (
          <div className="flex justify-center flex-col my-[8%]">
            <Spin size="large" />
            <p className="primary text-center mt-2">Loading...</p>
          </div>
        ) : (
          <div className="m-6 flex items-center justify-center h-full">
            {
              kycDetails?.isKyc ? (
                <div className="w-full grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 px-4">
                  <div>
                    <p className="text-[#1E232CC7] text-lg">KYC Status</p>
                    <p className="primary text-lg font-semibold mt-2">{kycDetails?.kycStatus}</p>
                  </div>
                  <div>
                    <p className="text-[#1E232CC7] text-lg">KYC Type</p>
                    <p className="primary text-lg font-semibold mt-2 capitalize">{kycDetails?.kycType}</p>
                  </div>
                  {
                    kycDetails?.kycType == 'manual' &&
                    <div>
                      <p className="text-[#1E232CC7] text-lg">Document Type</p>
                      <p className="primary text-lg font-semibold mt-2">{getDucumentType(kycDetails?.passport, kycDetails?.nationalId, kycDetails?.drivingLicense, kycDetails?.utilityBill, kycDetails?.bankStatement)}</p>
                    </div>
                  }
                  {
                    kycDetails?.kycType == 'manual' &&
                    <div>
                      <p className="text-[#1E232CC7] text-lg">Document</p>
                      <div className="cursor-pointer" onClick={() => setIsOpenDocModal(true)}>
                        <p className="primary text-lg font-semibold mt-2 underline mr-2">View Document</p>
                      </div>
                    </div>
                  }
                </div>
              ) : (
                <p className="text-lg text-gray-500">KYC is pending...</p>
              )
            }
          </div >
        )}
      </div>
    )
  }

  const RenderIBDetails = () => {
    return (
      <div className="m-6">
        {isLoadingIbDetails ? (
          <div className="flex justify-center flex-col my-[8%]">
            <Spin size="large" />
            <p className="primary text-center mt-2">Loading...</p>
          </div>
        ) : (
          <div>
            {
              ibDetails != null ? (
                <div className="w-full grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
                  <div className="my-2">
                    <p className="text-[#1E232CC7] text-lg">IB User</p>
                    <p className="primary text-lg font-semibold mt-2">{ibDetails?.ibRequestStatus}</p>
                  </div>
                  <div className="my-2">
                    <p className="text-[#1E232CC7] text-lg">Platform Type</p>
                    <p className="primary text-lg font-semibold mt-2">{ibDetails.platformType}</p>
                  </div>
                  <div className="my-2">
                    <p className="text-[#1E232CC7] text-lg">Account Type & Commission Amount</p>
                    <p className="primary text-lg font-semibold mt-2">{ibDetails?.accountTypeCommissionAmount}</p>
                  </div>
                  <div className="my-2">
                    <p className="text-[#1E232CC7] text-lg">IB Active / Inactive</p>
                    <Switch onChange={() => statusHandler(ibDetails)} checkedChildren="On" unCheckedChildren="Off" checked={ibDetails?.isEnabled} />
                  </div>
                  <div className="my-2">

                    <p className="text-[#1E232CC7] text-lg">
                      Total Generate Commission
                    </p>
                    <p className="primary text-lg font-semibold mt-2">$ {ibDetails?.totalGenerateCommission}</p>
                  </div>
                  <div className="my-2">
                    <p className="text-[#1E232CC7] text-lg">
                      Total Withdraw Commission
                    </p>
                    <p className="primary text-lg font-semibold mt-2">$ {ibDetails?.totalWithdrawCommission}</p>
                  </div>
                  <div className="my-2">
                    <p className="text-[#1E232CC7] text-lg">
                      Total Available Commission
                    </p>
                    <p className="primary text-lg font-semibold mt-2">$ {ibDetails?.totalAvailableCommission}</p>
                  </div>
                  <div className="my-2">
                    <p className="text-[#1E232CC7] text-lg">Create Date</p>
                    <p className="primary text-lg font-semibold mt-2">{moment(ibDetails?.ibCreatedDate).format('LLL')}</p>
                  </div>
                  {/* <div className="my-2">
                    <p className="text-[#1E232CC7] text-lg">
                      Stop Generate Commission
                    </p>
                    <Switch defaultChecked />
                  </div> */}
                </div>
              ) : (
                <p className="text-lg text-gray-500">Current user is not a ib user.</p>
              )
            }
          </div>
        )}
      </div>
    )
  }

  const RenderRules = () => {
    return (
      <div className="m-6">
        <div className="flex justify-end items-center">
          <button onClick={openRuleList} className="bg-primary text-sm text-white font-semibold rounded-lg px-8 py-2 themeHover duration-500">Assign Rule</button>
        </div>
        <div className="border border-b-0 mt-4">
          <TableWithSkeleton columns={assignRulesColumns} data={ruleData} loading={isRulesTableLoading} />
        </div>
      </div>
    )
  }

  const RenderAccounts = () => {
    return (
      <div className="m-6">
        <div className="mb-4">
          <MySegmented options={["Live Account", "Demo Account"]} value={filter} setValue={setFilter} />
        </div>
        <div className="border border-b-0">
          <TableWithSkeleton columns={columns} data={accounts} loading={isAccTableLoading} length={3} />
        </div>
      </div>
    )
  }

  let tabsItem = [
    { label: 'KYC Details', key: 1, children: RenderKYCDetails() },
    { label: 'Personal Details', key: 3, children: RenderPersonalDetails() },
    { label: 'Client Rules', key: 4, children: RenderRules() },
    { label: 'Accounts', key: 5, children: RenderAccounts() }
  ];

  const onTabsChange = (key) => {
    if (key == 2) {
      getIBDetails();
    } else if (key == 5) {
      getTraderAccounts();
    }
  };

  const menu = (
    <Menu>
      {(trader?.isIBUser == 'Yes' ? [...actionItemForIB, ...items] : [actionItemForuser, ...items]).map(item => (
        <Menu.Item key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleLeverageManagement = (values) => {
    setSelectedLeverage(values?.leverage)
    setIsOpenLeverageConfirm(true);
  }

  const updateLeverage = async () => {
    try {
      setIsLeverageFormLoading(true);
      let params = {
        leverage: selectedLeverage,
        loginId: selectedAccount?.login
      }
      const { data } = await UpdateMT5AccountLeverageApi(params);
      if (data?.success) {
        leverageForm.resetFields();
        getTraderAccounts();
        setIsOpenLeverageForm(false);
        setIsOpenLeverageConfirm(false);
      }
      setIsLeverageFormLoading(false);
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsLeverageFormLoading(false);
    }
  }

  const handlePasswordUpdate = (values) => {
    setIsPasswordFormLoading(true);
    let params = {
      loginId: selectedAccount?.login,
      newPassword: values?.newPassword,
      pType: values?.pType
    }
    setPasswordValue(params);
    setIsOpenPasswordConfirmationModal(true);
  }

  const verifyPassword = async (values) => {
    try {
      setIsLoadingPasswordConfirmation(true);
      const { data } = await VerifyLoginPasswordApi(values);
      if (data?.success) {
        setIsOpenPasswordConfirmationModal(false);
        setIsLoadingPasswordConfirmation(false);
        loginPasswordForm.resetFields();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsLoadingPasswordConfirmation(false);
      return false;
    }
  }

  const handleLoginPassword = async (values) => {
    try {
      setIsPasswordFormLoading(true);
      if (await verifyPassword(values)) {
        const { data } = await UpdateMT5AccountPasswordApi(passwordValue);
        if (data?.success) {
          toast.success(data?.message);
          passwordForm.resetFields();
          setIsOpenPasswordForm(false);
        }
        setIsPasswordFormLoading(false);
      }
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsPasswordFormLoading(false);
    }
  }

  const AddbalanceHandler = async (values) => {
    try {
      setIsbalanceFormLoading(true);
      let params = {
        ...values,
        loginId: selectedAccount?.login
      }
      const { data } = await AddMT5AccountbalanceApi(params);
      if (data?.success) {
        balanceForm.resetFields();
        toast.success(data?.message);
        getTraderAccounts();
        setIsOpenAddbalanceForm(false);
      }
      setIsbalanceFormLoading(false);
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsbalanceFormLoading(false);
    }
  }

  return (
    <div>
      <div className="border-2 border-light rounded-xl p-4 lg:p-6">
        {(isLoading) ? (
          <div className="flex justify-center flex-col my-[5%]">
            <Spin size="large" />
            <p className="primary text-center mt-2">Loading...</p>
          </div>
        ) : (
          <div>
            <div className="flex flex-wrap justify-between items-center gap-5 ps-3">
              <div className="flex items-center gap-3">
                <Avatar size={50} className="uppercase bg-primary" src={trader?.profileImage}>{trader?.firstName?.charAt(0)}{trader?.lastName?.charAt(0)}</Avatar>
                <p className="font-bold text-lg capitalize">{trader?.firstName} {trader?.lastName}</p>
                {trader?.isIBUser == 'Yes' &&
                  <div className="bg-primary-light rounded-md px-2"><p className="primary text-sm font-semibold">IB User</p></div>
                }
              </div>

              <Space direction="vertical">
                <Space wrap>
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                    placement="bottom"
                    arrow
                  >
                    <div className="primary font-bold cursor-pointer">Actions <span className="ms-0.5"><DownOutlined /></span></div>
                  </Dropdown>
                </Space>
              </Space>
              {/* <button type="button" className="primary font-semibold">Actions</button> */}
            </div>
            <div className="w-full grid md:grid-cols-2 xl:grid-cols-4 gap-5 mt-5 px-4">
              <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Email</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.email}</p>
              </div>
              <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Mobile No.</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.phoneNo}</p>
              </div>
              <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Country</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.countryName}</p>
              </div>
              <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Register Date</p>
                <p className="primary text-lg font-semibold mt-2">{moment(trader?.createdDate).format('DD-MM-YYYY, hh:mm A')}</p>
              </div>
              <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Wallet Account Number</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.walletAcNumber}</p>
              </div>
              <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Wallet Balance</p>
                <p className="primary text-lg font-semibold mt-2">$ {trader?.walletBalance}</p>
              </div>
              {trader?.referralCode && <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Referral Code</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.referralCode}</p>
              </div>}
              {!trader?.isActive && <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Verification Link</p>
                {Isverification ? (
                  <div className="flex items-center gap-3 mt-2">
                    <Spin size="small" />
                    <p className="primary text-lg font-semibold" >Sending email...</p>
                  </div>
                ) : (
                  <div className="flex items-center cursor-pointer mt-2" onClick={() => Verificationemail()}>
                    <p className="primary text-lg font-semibold underline mr-2" >Send E-mail</p>
                    <Redirect />
                  </div>
                )}
              </div>}
              <div>
                <p className="text-[#1E232CC7] text-lg">Password</p>
                <div className="flex">
                  <p className="primary text-lg font-semibold mt-2">{!newpassword ? trader?.password : newpassword}</p>
                  {!newpassword && <button type="button" className="ml-2" onClick={() => setIsOpenModal(true)}><Eye /></button>}
                </div>
              </div>
              <div>
                {
                  (trader?.ibRequestStatus === 'Rejected' || trader?.ibRequestStatus === 'Not Applied') &&
                  <button className='bg-primary text-white font-medium px-4 py-2 rounded-lg themeHover duration-500' onClick={() => setIsModalOpen(true)}>Request to become a IB</button>
                }
                {
                  (trader?.ibRequestStatus === 'Pending') &&
                  <p className="primary text-lg font-semibold mt-2">IB Request is pending </p>
                }
              </div>
              {trader?.dob && <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Date of birth</p>
                <p className="primary text-lg font-semibold mt-2">{moment(trader?.dob).format('DD-MM-YYYY')}</p>
              </div>}
              {trader?.skype && <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">skype</p>
                <div className="flex items-center gap-3">
                  <p className="primary font-semibold text-base mt-1 cursor-pointer hover:underline" onClick={() => openInNewTab(trader?.skype)}>Open Profile</p>
                  <Copy text={trader?.skype} />
                </div>
                {/* <p className="primary text-lg font-semibold mt-2">{trader?.skype}</p> */}
              </div>}
              {trader?.Telegram && <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Telegram</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.Telegram}</p>
              </div>}
              {trader?.facebook && (
                <div className="my-2">

                  <p className="text-[#1E232CC7] text-lg ">Facebook</p>
                  <div className="flex items-center gap-3">
                    <p className="primary font-semibold text-base mt-1 cursor-pointer hover:underline" onClick={() => openInNewTab(trader?.facebook)}>Open Profile</p>
                    <Copy text={trader?.facebook} />
                  </div>

                </div>
              )}
              {trader?.linkedIn && <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">LinkedIn</p>
                <div className="flex items-center gap-3">
                  <p className="primary font-semibold text-base mt-1 cursor-pointer hover:underline" onClick={() => openInNewTab(trader?.linkedIn)}>Open Profile</p>
                  <Copy text={trader?.linkedIn} />
                </div>
              </div>}
              {trader?.telephone && <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Telephone</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.telephone}</p>
              </div>}
              {trader?.fax && <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Fax</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.fax}</p>
              </div>}
              {trader?.recoveryContact && <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Recovery Contact</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.recoveryContact}</p>
              </div>}
              {trader?.familyName && <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Family Name</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.familyName}</p>
              </div>}
              {trader?.expAsIb == 0 ? ' ' : <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Experience As Ib</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.expAsIb}</p>
              </div>}
              {trader?.numberofClients == 0 ? ' ' : <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Number of Clients</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.numberofClients}</p>
              </div>}
              {trader?.workingExp == 0 ? ' ' : <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Working Experience</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.workingExp}</p>
              </div>}
              {trader?.countryOfBirth == 0 ? ' ' : <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Country Of Birth</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.countryOfBirth}</p>
              </div>}
              {trader?.countryOfCitizenship == 0 ? ' ' : <div className="my-2">
                <p className="text-[#1E232CC7] text-lg">Country Of Citizenship</p>
                <p className="primary text-lg font-semibold mt-2">{trader?.countryOfCitizenship}</p>
              </div>}
            </div>
          </div>
        )}
      </div>

      <div className="border-2 border-light rounded-xl mt-5">
        <Tabs
          tabPosition='left'
          onChange={onTabsChange}
          items={trader?.isIBUser == 'Yes' ?
            [...tabsItem.slice(0, 1), { label: 'IB Details', key: 2, children: RenderIBDetails() }, ...tabsItem.slice(1)]
            : tabsItem
          }
        />
      </div>

      <ConfirmationModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        type='success'
        message='Are you sure you want to send IB Request?'
        onConfirm={sendIbRequest}
        isLoading={isIbReqLoading}
        loadingLabel='Sending Request...'
      />

      {/* User rule unassign confirmation modal */}
      <ConfirmationModal
        isOpen={isOpenUnassignModal}
        setIsOpen={setIsOpenUnassignModal}
        message='Are you sure you want to unassign this rule?'
        onConfirm={unassignUserRule}
        isLoading={isRuleUnassignLoading}
        loadingLabel='Unassigning rule...'
      />

      <ConfirmationModal
        isOpen={isStatusConfirmOpen}
        setIsOpen={setIsStatusConfirmOpen}
        message='Are you sure to change the IB status?'
        onConfirm={statusChangeConfirm}
        isLoading={isStatusConfirmLoading}
        loadingLabel='Changing status...'
      />

      {/* View KYC Document */}
      <ModalComponent isOpen={isOpenDocModal} setIsOpen={setIsOpenDocModal} title="KYC Documents" width={1000}>
        <div>
          {kycDetails?.fileUrlPath && kycDetails.fileUrlPath.length > 0 ? (
            <div className='p-5'>
              <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10'>
                {kycDetails.fileUrlPath.map((url, index) => (
                  <div key={index}>
                    <div className="border border-gray-300 rounded-lg">
                      {url.endsWith('.pdf') ? (
                        <iframe
                          src={url}
                          title={`KYC Document ${index + 1}`}
                          className="w-full h-64 rounded-lg cursor-pointer"
                          onClick={() => openInNewTab(url)}
                        />
                      ) : (
                        <img
                          src={url}
                          alt={`KYC Document ${index + 1}`}
                          className="w-full h-auto rounded-lg cursor-pointer"
                          onClick={() => openInNewTab(url)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (<p>No documents available</p>)}
        </div>
      </ModalComponent>

      {/* Update password modal */}
      <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} title="Password" width={600}>
        <Form className="w-full" autoComplete="off" initialValues={{ password: '' }} onFinish={handleSubmit}>
          <div className="input-white">
            <label className="text-base text-[#2F2B3DCC] font-medium">Password</label>
            <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password.' }]}>
              <Input.Password
                placeholder="Enter your password"
                className="border rounded-lg w-full ps-4 py-2"
                prefix={<Lock />}
                // iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                name="password"
                onChange={handleChange}
                value={formData.password}
              />
            </Form.Item>
          </div>
          <div className="flex justify-center mt-8">
            <LoadableButton
              className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
              type="Submit"
              lable="Submit"
              isLoading={isLoadingPassModal}
              loadingLable="Submitting..."
            />
          </div>
        </Form>
      </ModalComponent>

      {/* Update user modal */}
      <ModalComponent isOpen={isOpenEditModal} setIsOpen={setIsOpenEditModal} title="Update User" width={1200}>
        <Form className="w-full" autoComplete="off" form={form} initialValues={initialValues} onFinish={handleEditSubmit} onFieldsChange={handleFieldsChange}>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">First Name</label>
              <Form.Item name="firstName" rules={[{ required: true, message: "Please enter first name." }]}>
                <Input placeholder="First name" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Last Name</label>
              <Form.Item name="lastName" rules={[{ required: true, message: "Please enter last name." }]}>
                <Input placeholder="Last name" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Primary Email</label>
              <Form.Item name="email" rules={[{ required: true, type: "email", message: "Please enter valid email.", },]}>
                <Input disabled placeholder="Primary email" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Phone No.</label>
              <Form.Item name="phoneNo" rules={[{ required: true, message: "Please enter valid phone number.", }]}>
                <Input type="number" placeholder="Phone no." className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
            <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Country</label>
              <Form.Item name="countryId">
                <Select disabled showSearch placeholder="Select a country" className="w-full mt-1" filterOption={filterOption} options={countries} />
              </Form.Item>
            </div>
            <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Role</label>
              <Form.Item name="roleId" rules={[{ required: true, message: "Please select a role." }]}>
                <Select placeholder="Select a role" className="w-full mt-1" options={[{ value: "2", label: "Trader" }, { value: "4", label: "IB" },]} />
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-end mt-8">
            <LoadableButton
              className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
              disabled={!isFormChanged || isEditTraderLoading}
              type="Submit"
              lable="Update"
              isLoading={isEditTraderLoading}
              loadingLable="Updating trader..."
            />
          </div>
        </Form>
      </ModalComponent>

      {/* Assign rules modal */}
      <ModalComponent isOpen={isOpenRuleListModal} setIsOpen={setIsOpenRuleListModal} title="Assign new rules" width={1200}>
        {
          isLoadingRuleListModal ? (
            <div className="flex justify-center flex-col my-[10%]">
              <Spin size="large" />
              <p className="primary text-center mt-2">Loading...</p>
            </div>
          ) : (
            <div>
              <div className="border border-b-0">
                <TableWithSkeleton rowSelection={rowSelection} columns={assignableRuleListColumns} data={assignableRuleList} loading={isLoadingRuleListModal} />
              </div>
              <div className="mt-5 flex justify-end">
                <LoadableButton type='button' onClick={assignUserRule} className='bg-primary text-sm text-white font-medium rounded-lg px-8 py-2 themeHover duration-500' lable='Assign' disabled={selectedRowKeys.length == 0} isLoading={isRuleAssignLoading} loadingLable='Assigning rule...' />
              </div>
            </div>
          )
        }
      </ModalComponent>

      {/* Leverage management form modal */}
      <ModalComponent isOpen={isOpenLeverageForm} setIsOpen={setIsOpenLeverageForm} title='Leverage Management'>
        <Form className='w-full' autoComplete="off" form={leverageForm} initialValues={leverageInitialValues} onFinish={handleLeverageManagement}>
          <div className='ant-select-selector-white'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>Leverage</label>
            <Form.Item name='leverage' rules={[{ required: true, message: 'Please select leverage.' }]}>
              <Select className='border-primary w-full mt-1' placeholder='Select leverage' options={leverageOptions} />
            </Form.Item>
          </div>
          <div className='flex justify-center items-center gap-5 mt-10'>
            <button type='submit' className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500'>Proceed</button>
            <button type='button' className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500' onClick={() => setIsOpenLeverageForm(false)}>Cancel</button>
          </div>
        </Form>
      </ModalComponent>

      {/* Leverage confirmation modal */}
      <ConfirmationModal
        isOpen={isOpenLeverageConfirm}
        setIsOpen={setIsOpenLeverageConfirm}
        message={`${selectedLeverage > selectedAccount?.leverage ? `You have chosen 1:${selectedLeverage} leverage with a high-risk level.` : ''} Leverage will affect the required margin. Are you sure you want to continue?`}
        onConfirm={updateLeverage}
        isLoading={isLeverageFormLoading}
        loadingLabel='Changing Leverage...'
      />

      {/* Update password form modal */}
      <ModalComponent isOpen={isOpenPasswordForm} setIsOpen={setIsOpenPasswordForm} title='Change Password'>
        <Form className='w-full' autoComplete="off" form={passwordForm} initialValues={passwordInitialValues} onFinish={handlePasswordUpdate}>
          <div className='ant-select-selector-white'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>Password Type</label>
            <Form.Item name='pType' rules={[{ required: true, message: 'Please select password type.' }]}>
              <Select className='border-primary w-full mt-1' placeholder='Select password type' options={[{ value: 1, label: 'Change Investor Password' }, { value: 0, label: 'Change Trading Password' }]} />
            </Form.Item>
          </div>
          <div className='input-white mt-5'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>New Password</label>
            <Form.Item name='newPassword' hasFeedback rules={[{ required: true, message: 'Please enter new password.' }, {
              pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
              message: 'Please enter strong password.',
            },]}>
              <Input.Password type='password' placeholder="New password" className="mt-1 w-full ps-4 py-2" />
            </Form.Item>
          </div>
          <div className='input-white mt-5'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>Confirm Password</label>
            <Form.Item name='confirmPassword' dependencies={['newPassword']} hasFeedback rules={[{ required: true, message: 'Please enter confirm password.' }, ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The new password that you entered do not match!'));
              },
            }),]}>
              <Input.Password type='password' placeholder="Confirm password" className="mt-1 w-full ps-4 py-2" />
            </Form.Item>
          </div>
          <div className='flex justify-center items-center gap-5 mt-10'>
            <LoadableButton
              className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover duration-500'
              disabled={isPasswordFormLoading}
              type="Submit"
              lable='Proceed'
              isLoading={isPasswordFormLoading}
              loadingLable='Updating password...'
            />
            <button type='button' className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500' onClick={() => setIsOpenPasswordForm(false)} disabled={isPasswordFormLoading}>Cancel</button>
          </div>
        </Form>
      </ModalComponent>

      {/* Login password confirmation form modal */}
      <ModalComponent isOpen={isOpenPasswordConfirmationModal} setIsOpen={setIsOpenPasswordConfirmationModal} title='Verify login Password'>
        <Form className='w-full' autoComplete="off" form={loginPasswordForm} initialValues={loginPasswordInitialValues} onFinish={handleLoginPassword}>
          <div className='input-white mt-5'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>Login Password</label>
            <Form.Item name='currentPassword' rules={[{ required: true, message: 'Please enter login password.' }]}>
              <Input.Password type='password' placeholder="Login password" className="mt-1 w-full ps-4 py-2" />
            </Form.Item>
          </div>
          <div className='flex justify-center items-center gap-5 mt-10'>
            <LoadableButton
              className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover duration-500'
              disabled={isLoadingPasswordConfirmation}
              type="Submit"
              lable='Verify'
              isLoading={isLoadingPasswordConfirmation}
              loadingLable='Verifying password...'
            />
            <button type='button' className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500' onClick={() => setIsOpenPasswordConfirmationModal(false)} disabled={isLoadingPasswordConfirmation}>Cancel</button>
          </div>
        </Form>
      </ModalComponent>

      {/* Add balance form modal */}
      <ModalComponent isOpen={isOpenAddbalanceForm} setIsOpen={setIsOpenAddbalanceForm} title='Add balance'>
        <Form className='w-full' autoComplete="off" form={balanceForm} initialValues={balanceInitialValues} onFinish={AddbalanceHandler}>
          <div className='ant-select-selector-white'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>Balance</label>
            <Form.Item name='amount' rules={[{ required: true, message: 'Please select balance.' }]}>
              <Select className='border-primary w-full mt-1' placeholder='Select balance' options={BalanceOptions} />
            </Form.Item>
          </div>
          <div className='flex justify-center items-center gap-5 mt-10'>
            <LoadableButton
              className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover duration-500'
              disabled={isbalanceFormLoading}
              type="Submit"
              lable='Proceed'
              isLoading={isbalanceFormLoading}
              loadingLable='Updating amount...'
            />
            <button type='button' className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500' onClick={() => setIsOpenAddbalanceForm(false)}>Cancel</button>
          </div>
        </Form>
      </ModalComponent>

      <SendCredentials isOpenModal={isOpenModalnew} setIsOpenModal={setIsOpenModalnew} id={id} />
      <SendNotification isNotification={isNotification} setIsNotification={setIsNotification} id={id} />
      <MT5LoginDetails mT5LoginDetails={mT5LoginDetails} setmT5LoginDetails={setmT5LoginDetails} id={id} />
      <ChangePassword changePasswor={changePassword} setChangePassword={setChangePassword} id={id} />
      <UpdateIB isOpenIBModal={isOpenIBModal} setisOpenIBModal={setisOpenIBModal} id={id} ib={trader?.isIBUser} />
      <AssignNewPlanToIB isAssignIBPlan={isAssignIBPlan} setIsAssignIBPlan={setIsAssignIBPlan} id={id} />
      

    </div >
  );
}
