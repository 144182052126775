const columns = [
    {
      title: "Question",
      dataIndex: "question",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
        title: "Category",
        dataIndex: "categoryName",
      },
    {
      title: "Created Date",
      dataIndex: "createdDate",
    },
    
  
    {
      title: "Actions",
      dataIndex: "actions",
    },
  ];
  
  export default columns;
  