const columns = [
  {
    swapFreeIslamic: "YES",

    title: 'Rule Name',
    dataIndex: 'ruleName',

  },
  {
    title: 'Rule Level',
    dataIndex: 'ruleLevel',

  },
  {
    title: 'Assign Name',
    dataIndex: 'assignName',

  },
  {
    title: 'Auto/Manual',
    dataIndex: 'autoManual',
  },
  {
    title: 'CreatedDate',
    dataIndex: 'createdDate',
  },

  {
    title: 'Details',
    dataIndex: 'action',
  }

];

export default columns;


