import React, { useState, useEffect } from "react";
import { Input, Form, Switch, Button } from "antd";
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import { GetAllSymbolDetailApi, UpdateUserStatusApi } from "../../../api/request/settings/Symbol.js";
import toast from "react-hot-toast";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal.jsx";
import { useLocation } from 'react-router-dom';
import Edit from "../../../assets/Edit.jsx";


export default function Symbol() {
    const [form] = Form.useForm();
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [isEditTraderLoading, setIsEditTraderLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [editingKey, setEditingKey] = useState(null);
    const [search, setSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const [editedValue, setEditedValue] = useState("");
    const [neweditValue, setNeweditValue] = useState({})
    const [data, setData] = useState([]);
    const location = useLocation();
    const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
    const queryString = location.pathname.split('/').pop();
    const nameParam = decodeURIComponent(queryString.split('=')[1]);

    const statusHandler = (item) => {
        setInitialValues(item);
        setIsStatusConfirmOpen(true);
    }

    const getAllSymbol = async () => {
        try {
            let params = {
                groupName: nameParam,
                PageNumber:page,
                PageSize: pageSize,
                searchText: searchText,
            }
            setIsLoading(true);
            const { data } = await GetAllSymbolDetailApi(new URLSearchParams(params)?.toString());
            if (data?.success) {

                setData(
                    data?.data?.map((item, index) => {
                        return {
                            key: index,
                            ...item,
                            isGenerateCommission: (item?.isGenerateCommission === true ? "Yes" : 'No'),
                            status: (<Switch onChange={() => statusHandler(item)} checkedChildren="On" unCheckedChildren="Off" checked={item?.isGenerateCommission} />),
                        };
                    })
                );
                setTotalRecord(data?.totalRecords);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllSymbol();
    }, [page, pageSize,searchText]);

    useEffect(() => {
        setPage(1)
    }, [searchText]);


    const statusChangeConfirm = async () => {
        try {
            let apiParams = {
                symbolId: initialValues?.symbolId,
                pip: initialValues?.pip,
                isGenerateCommission: !initialValues?.isGenerateCommission
            };
            setIsEditTraderLoading(true);
            const { data } = await UpdateUserStatusApi(apiParams);
            if (data?.success) {
                toast.success(data?.message);
                form?.resetFields();
                getAllSymbol();
                setIsEditTraderLoading(false);
                setIsStatusConfirmOpen(false);

            }
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsEditTraderLoading(false);
        }
    };

    const Updatepip = async (value) => {
        try {
            let apiParams = {
                symbolId: neweditValue?.symbolId,
                pip: Number(editedValue),
                isGenerateCommission: neweditValue?.isGenerateCommission === "Yes" ? true : false
            };
            setIsEditTraderLoading(true);
            const { data } = await UpdateUserStatusApi(apiParams);
            if (data?.success) {
                toast.success(data?.message);
                form?.resetFields();
                getAllSymbol();
                setNeweditValue(null)
                setEditingKey(null);
                setIsEditTraderLoading(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsEditTraderLoading(false);
        }
    };

    const handleEditClick = (key, pipValue, record) => {
        setEditingKey(key);
        setEditedValue(pipValue);
        setNeweditValue(record);
    };

    const handleSaveClick = (key) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.key === key ? { ...item, pip: editedValue } : item
            )
        );
        setEditingKey(null);
    };

    const handleCancelClick = () => {
        setEditingKey(null);
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "symbolName",
        },
        {
            title: "Pip",
            dataIndex: "pip",
            render: (value, record) =>
                editingKey === record.key ? (
                    <>
                        <Input
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            style={{ width: 100, marginRight: 8 }}
                        />
                        <Button
                            type="primary"
                            style={{ marginRight: 8 }}
                            onClick={() => Updatepip()}
                        >
                            Update
                        </Button>
                        <Button onClick={handleCancelClick}>Cancel</Button>
                    </>
                ) : (
                    <>
                        <span style={{ marginRight: 16 }}>{value}</span>
                        <button
                            onClick={() => handleEditClick(record.key, record.pip, record)}
                            className="me-3"
                        >
                            <Edit />
                        </button>
                    </>
                ),
        },
        {
            title: "Generate Commission",
            dataIndex: "isGenerateCommission",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
    ];


    return (
        <div>
            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="flex justify-between flex-wrap gap-5">
                    <div className="px-4 lg:px-6">
                        <p className="text-[#2F2B3D] text-xl font-semibold">All Symbol</p>
                    </div>
                    <div className="mr-3">
                        <Input
                            size="large"
                            style={{ width: 220 }}
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onPressEnter={() => setSearchText(search)}
                            prefix={
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                                    <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                                </svg>
                            }
                            suffix={search &&
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(''); setSearch('') }} viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                                </svg>
                            }
                        />
                    </div>
                </div>
                <div className="mt-6">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
                    <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
                </div>
            </div>

            {/* Rule status change confirmation modal */}
            <ConfirmationModal
                isOpen={isStatusConfirmOpen}
                setIsOpen={setIsStatusConfirmOpen}
                message='Are you sure to change the Status?'
                onConfirm={statusChangeConfirm}
                isLoading={isEditTraderLoading}
                loadingLabel='Changing status...'
            />

        </div>
    );
}


