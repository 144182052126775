const columns = [
    {
        title: 'A/C No.',
        dataIndex: 'accountNumber',
        // sorter: (a, b) => a?.accountNumber?.localeCompare(b?.accountNumber),
    },
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        // sorter: (a, b) => a?.symbol?.localeCompare(b?.symbol),
    },
  
    {
        title: 'Lots',
        dataIndex: 'actualLot',
        // sorter: (a, b) => a.actualLot - b.actualLot ,
    },
    {
        title: 'User Distribution Count',
        dataIndex: 'userDistributionCount',
        // sorter: (a, b) => a.userDistributionCount - b.userDistributionCount ,
    },
    {
        title: 'Commission',
        dataIndex: 'commision',
        // sorter: (a, b) => a?.commision?.localeCompare(b?.commision),
    },
    {
        title: 'Created Date',
        dataIndex: 'createdDate',
        // sorter: (a, b) => new Date(a.transactionDate) - new Date(b.transactionDate),
    },
    {
        title: 'Rollback',
        dataIndex: 'actions',
        fixed:"right"
    }
];

export default columns;