const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        render: defaultRender,
    },
    {
        title: 'Created Date',
        dataIndex: 'createdDate',
        render: defaultRender,
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        render: defaultRender,
    },
];

export default columns;