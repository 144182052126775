import api from "../index";

export const LoginApi = (data) => api.post('Authentication/User-Login', data);
export const OtpApi = (data) => api.post('Authentication/LoginOtp-Verify', data);
export const SendOtpApi = (userId) => api.put(`Authentication/Resend-Otp/${userId}`);
export const LogoutApi = (data) => api.post('Authentication/Logout', data);
export const ForgetOtpApi = (data) => api.post('Authentication/Forgot-Password-VerifyOtp', data);
export const GetUserPermissionApi = (id) => api.get(`RolesAndPermission/GetModulesPermissionByUserId/${id}`);
export const GetNotificationApi = () => api.get('Notification/GetUserMessages');

export const ForgetPasswordApi = (data) => api.post('Authentication/SendOtp-Email', data);
export const ResetApi = (data) => api.post('Authentication/Forgot-Password', data);
export const ClearNotificationApi = () => api.delete(`Notification/admin/ClearAllNotifications`);
export const MarkAsReadNotificationApi = (notificationId, params) => api.put(`Notification/UpdateNotificationStatus/${notificationId}?${params}`);