const columns = [
  {
    swapFreeIslamic: "YES",

    title: 'Plan Name',
    dataIndex: 'planName',

  },
  {
    title: 'Group Name',
    dataIndex: 'groupName',

  },
  {
    title: 'Minimum Deposit',
    dataIndex: 'minimumDeposit',

  },
  {
    title: 'IB Comission',
    dataIndex: 'commission',
  },
  {
    title: 'Trade Comission',
    dataIndex: 'groupCommission',
  },
  {
    title: 'Leverage',
    dataIndex: 'leverage',
  },
  {
    title: 'Swap Free/Islamic',
    dataIndex: 'swapFree',
  },
  {
    title: 'Account Type',
    dataIndex: 'accType',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
  }

];

export default columns;


