const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        // sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
        title: 'Aaccount No.',
        dataIndex: 'aaccountNo',
        sorter: true,
        // sorter: (a, b) => a?.aaccountNo?.localeCompare(b?.aaccountNo),
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        sorter: true,
        // sorter: (a, b) => a?.amount - b?.amount,
    },
    {
        title: 'Wallet Address',
        dataIndex: 'walletAddress',
        sorter: true,
        // sorter: (a, b) => a?.walletAddress?.localeCompare(b?.walletAddress),
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        sorter: true,
        // sorter: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
    },
];

export default columns;