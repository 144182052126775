import React, { useState, useEffect } from 'react'
import { Select, Form } from "antd";
import toast from "react-hot-toast";
import ModalComponent from "../../../components/global/modal/ModalComponent";
import { UpdateIBApi } from "../../../api/request/client"
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
import { GetUsersApi } from "../../../api/request/settings/kycRules.js";

function UpdateIB({ isOpenIBModal, setisOpenIBModal, id, ib }) {

    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState();

    const getUsers = async () => {
        try {
            let params = {
                UserType: 'ib'
            }
            const { data } = await GetUsersApi(new URLSearchParams(params).toString());
            if (data?.success) {
                setUsers(data?.data?.map((item) => ({ value: item?.userId, label: item?.name })));
            }
        } catch (error) { }
    }

    useEffect(() => {
        getUsers();
    }, []);

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true)
            let params = {
                IsIB: ib === "No" ? false : true,
                NewIBUserId: values?.userId,
                userId: id
            };
            const { data } = await UpdateIBApi(new URLSearchParams(params).toString());
            if (data?.success) {
                toast.success(data?.message);
                setisOpenIBModal(false)
                form.resetFields();
            }
            setIsLoading(false)
        } catch (error) {
            if (error?.response?.data?.error?.message) {
                toast.error(error?.response?.data?.error?.message);
            }
            setIsLoading(false)
        }
    };
    return (
        <div>
            <ModalComponent isOpen={isOpenIBModal} setIsOpen={setisOpenIBModal} title="Update IB" width={600}>
                <Form className='w-full' autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
                    <div className="ant-multi-select-selector ant-select-selector-white">
                        <label className="text-base text-[#2F2B3DCC] font-medium">IB User</label>
                        <Form.Item name="userId" rules={[{ required: true, message: 'Please select a user.' }]}>
                            <Select
                                className="hidden lg:block w-full mt-2"
                                showSearch
                                placeholder="Select Ib user"
                                options={users}
                                filterOption={(input, option) =>
                                    option?.label.toLowerCase().includes(input.toLowerCase())
                                }
                                onSelect={() => {
                                    document.activeElement.blur();
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="flex justify-between items-center gap-5 flex-wrap mt-6">
                        <button type='button' onClick={() => setisOpenIBModal(false)} className='bg-primary text-sm text-white font-semibold rounded-lg px-6 py-2 uppercase themeHover duration-500'>Cancel</button>
                        <LoadableButton
                            type='submit' className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover duration-500'
                            disabled={isLoading}
                            lable="Submit"
                            isLoading={isLoading}
                            loadingLable="Update IB..."
                        />
                    </div>
                </Form>
            </ModalComponent>
        </div>
    )
}

export default UpdateIB
