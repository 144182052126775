const columns = [
    {
        title: 'Message',
        dataIndex: 'message',
       
    },
 
    {
        title: 'Created Date',
        dataIndex: 'createdDate',
       
    },
   
];


export default columns;