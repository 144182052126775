import React, { useState, useEffect } from "react";
import { Input, Form, Select,Switch} from "antd";
import columns from "../../../columns/Configuration.js";
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal.jsx";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import moment from "moment";
import { GetAllConfigurationApi,UpdateUserStatusApi} from "../../../api/request/settings/configuration.js";
import toast from "react-hot-toast";
import Edit from "../../../assets/Edit.jsx";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";


export default function Configuration() {
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedRulesId, setSelectedRulesId] = useState("");
  const [totalRecord, setTotalRecord] = useState(0);
  const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
  const [isStatusConfirmLoading, setIsStatusConfirmLoading] = useState(false);
  const [rulesall,setrulesall] = useState()
  const [isEditTraderLoading, setIsEditTraderLoading] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [initialValues, setInitialValues] = useState({ demoAccount: "", liveAccount: "", description: "", ruleName: "",isDisable: ""});
  const [data, setData] = useState([]);

  const getAllConfiguration = async () => {
    try {
      let params = {
        PageNumber: page,
        PageSize: pageSize,
      }
      setIsLoading(true);
      const { data } = await GetAllConfigurationApi(new URLSearchParams(params)?.toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              value:`${item.value} Second`,
              lastModifiedDate: (item?.modifiedDate ? moment(item?.modifiedDate).format('DD-MM-YYYY, hh:mm A') : '--'),
              actions: (<button type="button" onClick={() => { setSelectedRulesId({id:item?.id,isEnable: item?.isEnable === "False"? false : true,}); setIsOpenEditModal(true); }}><Edit /></button>),
              isDefault: (<Switch 
                onChange={() => statusHandler({ isEnable: item?.isEnable === "False"? true : false, id: item?.id,thresHoldTime:item?.value, })} 
                checkedChildren="Enable" 
                unCheckedChildren="Disable" 
                checked={item?.isEnable === "True"} 
            />)
             
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllConfiguration();
  }, [page, pageSize]);

  const statusHandler = async (params) => {
    setIsStatusConfirmOpen(true);
    setrulesall(params)
  }

  const statusChangeConfirm = async () => {
    try {
      setIsStatusConfirmLoading(true);

      const { data } = await UpdateUserStatusApi(rulesall);
      if (data?.success) {
        getAllConfiguration();
        toast.success(data?.message);
      }

      setIsStatusConfirmLoading(false);
      setIsStatusConfirmOpen(false);
    } catch (error) {
      setIsStatusConfirmLoading(false);
    }
  }

  const handleFieldsChange = (_, allFields) => {
    const isChanged = allFields.some(field => field.touched);
  };

  const handleEditSubmit = async (value) => {
    try {
     
      let apiParams = {
        ...value,
        ...selectedRulesId
      };
      setIsEditTraderLoading(true);
      const { data } = await UpdateUserStatusApi(apiParams);
      if (data?.success) {
        toast.success(data?.message);
        form?.resetFields();
        setIsEditTraderLoading(false);
        setIsOpenEditModal(false);
        getAllConfiguration();
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
      setIsEditTraderLoading(false);
    }
  };

  return (
    <div>
      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">Configuration</p>
        </div>
        <div className="mt-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
          <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} />
        </div>

        <ConfirmationModal
        isOpen={isStatusConfirmOpen}
        setIsOpen={setIsStatusConfirmOpen}
        message='Are you sure you want to Enable/Disable it?'
        onConfirm={statusChangeConfirm}
        isLoading={isStatusConfirmLoading}
        loadingLabel='Changing status...'
      />

      </div>
       {/* Update user modal */}
       <ModalComponent isOpen={isOpenEditModal} setIsOpen={setIsOpenEditModal} title="Update Threshold Value" width={500}>
        <Form className="w-full" autoComplete="off" form={form} initialValues={initialValues} onFinish={handleEditSubmit} onFieldsChange={handleFieldsChange}>
          
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Threshold Value (in seconds)</label>
              <Form.Item name="thresHoldTime" rules={[{ required: true, message: 'Please enter minimum value.' }, { validator: (_, value) => Number.isInteger(Number(value)) ? Promise.resolve() : Promise.reject(new Error('Please enter minimum value.')) }]} >
                <Input type='number' placeholder="Threshold Value" className="mt-1 w-full py-2" />
              </Form.Item>
            </div>
          <div className="flex justify-end mt-8">
            <LoadableButton
              className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
              type="Submit"
              lable="Update"
              isLoading={isEditTraderLoading}
              loadingLable="Updating rules..."
            />
          </div>
        </Form>
      </ModalComponent>
    </div>
  );
}

