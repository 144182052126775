import React, { useState, useEffect } from "react";
import { Input, Tooltip, DatePicker } from "antd";
import moment from "moment";
import toast from "react-hot-toast";
import { CommissionRollBackApi, GetGenerateCommissionCalculationApi,GetGenerateCommissionCalculationrollbackApi } from "../../../api/request/ibCommission";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal";
import MyPagination from "../../../components/global/pagination/Pagination";
import columns from "../../../columns/ibCommission/rolback.jsx";
import ExportHandler from "../../../components/global/export/ExportHandler.jsx";
const { RangePicker } = DatePicker;

export default function Reports() {

    // const { id } = useParams();
    const id = '00000000-0000-0000-0000-000000000000';
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [search, setSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isRollbackModalOpen, setIsRollbackModalOpen] = useState(false);
    const [isRollbackLoading, setIsRollbackLoading] = useState(false);
    const [selectedCommission, setSelectedCommission] = useState(null);
    const [textAreaValue, setTextAreaValue] = useState("");
    const [dateRange, setDateRange] = useState([]);

    const getCommission = async () => {
        try {
            let apiParams = {
                PageNumber: page,
                PageSize: pageSize,
                searchText: searchText,
                FromDate: dateRange?.[0]?.format('MM/DD/YYYY') ?? '',
                ToDate: dateRange?.[1]?.format('MM/DD/YYYY') ?? '',
            }
            setIsLoading(true);
            const { data } = await GetGenerateCommissionCalculationrollbackApi(id, new URLSearchParams(apiParams).toString());
            setData(
                data?.data?.map((item) => {
                    return {
                        ...item,
                        commision: `$ ${item?.commision}`,
                        createdDate: (item?.createdDate ? moment(item?.createdDate).format("DD-MM-YYYY") : null),
                        closeTime: (item?.closeTime ? moment(item?.closeTime).format("hh:mm A") : null),
                        comments: (item?.comments || '---'),
                        commissionType: (item?.commissionType || '---'),
                        actions: (
                            <div className="flex justify-center">
                                <Tooltip title="Rollback"><button onClick={() => rollbackModalHandler(item)}><img src="/assets/icons/delete_icon.svg" alt="icon" className='min-w-6 max-w-6' /></button></Tooltip>
                            </div>)
                    };
                })
            );
            setTotalRecord(data?.totalRecords);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const exportHandler = async () => {
        try {
            let apiParams = {
                ApplyPagination: false,
                searchText: searchText,
                FromDate: dateRange?.[0]?.format('MM/DD/YYYY') ?? '',
                ToDate: dateRange?.[1]?.format('MM/DD/YYYY') ?? '',
            };
            const { data } = await GetGenerateCommissionCalculationApi(id, new URLSearchParams(apiParams).toString());
            if (data?.success) {
                return { data: data?.data, removableColumns: ['userId'], fileName: 'Generated Commission List' };
            } else {
                return { data: [], removableColumns: [] };
            }
        } catch (error) {
            return { data: [], removableColumns: [] };
        }
    };

    useEffect(() => {
        getCommission();
    }, [page, pageSize, dateRange,searchText]);

    useEffect(() => {
        setPage(1);
    }, [searchText]);

    const commissionRollback = async () => {
        try {
            if (!textAreaValue) {
                toast.error("Please enter a reason.");
                return;
            }
            setIsRollbackLoading(true);
            let apiParams = {
                ibUserId: id,
                comment: textAreaValue,
                loginId: selectedCommission?.accountNumber,
                commission: selectedCommission?.commision,
                dealId: selectedCommission?.dealId
            }
            const { data } = await CommissionRollBackApi(apiParams);
            if (data?.success) {
                getCommission();
                setTextAreaValue('');
                setIsRollbackModalOpen(false);
            }
            setIsRollbackLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.error?.message);
            setIsRollbackLoading(false);
        }
    }

    const rollbackModalHandler = (item) => {
        setSelectedCommission(item);
        setIsRollbackModalOpen(true);
    }

    useEffect(() => {
        setTextAreaValue('');
    }, [isRollbackModalOpen]);

    return (
        <div>
            <div className="flex justify-end flex-wrap gap-5">
                <div className="flex items-center gap-3">
                    <div>
                        <div className='ant-date-range-selector-white'>
                            <RangePicker className='border-primary  py-2' format='DD-MM-YYYY'
                                onChange={(dates) => { setDateRange(dates) }} />
                        </div>
                    </div>
                    <div>
                        <Input
                            size="large"
                            style={{ width: 220 }}
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onPressEnter={() => setSearchText(search)}
                            prefix={
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                                    <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                                </svg>
                            }
                            suffix={search &&
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(''); setSearch('') }} viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                                </svg>
                            }
                        />
                    </div>
                    <div>
                        <ExportHandler exportHandler={exportHandler} />
                    </div>
                </div>
            </div>

            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">IB Commission Rollback</p>
                </div>
                <div className="my-6 w-auto">
                    <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
                    <MyPagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
                </div>
            </div>

            {/* ---- Reject-request ---- */}
            <ConfirmationModal
                isOpen={isRollbackModalOpen}
                setIsOpen={setIsRollbackModalOpen}
                message="Are you sure you want to rollback this commission?"
                onConfirm={commissionRollback}
                isLoading={isRollbackLoading}
                loadingLabel="rolling back commission..."
                textArea={true}
                textAreaValue={textAreaValue}
                setTextAreaValue={setTextAreaValue}
                placeholder="Reason of rollback..."
            />
        </div>
    )
}