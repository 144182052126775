const defaultRender = (value) => (value ? value : '--');

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    // sorter: (a, b) => a?.name?.localeCompare(b?.name),
    // render: (text) => <a>{text}</a>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: defaultRender,
    sorter: true,
    // sorter: (a, b) => a?.email?.localeCompare(b?.email),
  },
  {
    title: 'Mobile No',
    dataIndex: 'phoneNo',
    render: defaultRender,
    sorter: true,
    // sorter: (a, b) => a?.phoneNo?.localeCompare(b?.phoneNo),
  },
  {
    title: ' KYC type',
    dataIndex: 'kycType',
    render: defaultRender,
    sorter: true,
    // sorter: (a, b) => a?.kycType?.localeCompare(b?.kycType),
  },
 
  {
    title: 'Request Date',
    dataIndex: 'requestDate',
    render: defaultRender,
    sorter: true,
    // sorter: (a, b) => ((new Date(a.requestDate)) - (new Date(b.requestDate))),
  },
  {
    title: 'Action Date',
    dataIndex: 'actionDate',
    render: defaultRender,
    sorter: true,
    // sorter: (a, b) => {
    //   if (!a.actionDate && !b.actionDate) return 0;
    //   if (!a.actionDate) return 1;
    //   if (!b.actionDate) return -1;
    //   return new Date(a.actionDate) - new Date(b.actionDate);
    // },
  },
  {
    title: 'Action By',
    dataIndex: 'actionBy',
    sorter: true,
    render: defaultRender
  },
  {
    title: 'User Type',
    dataIndex: 'userType',
    render: defaultRender,
    sorter: true,
    // sorter: (a, b) => a?.userType?.localeCompare(b?.userType),
  },
  {
    title: 'Status',
    dataIndex: 'kycStatus',
    render: defaultRender,
    sorter: true,
    // sorter: (a, b) => a.kycStatus - b.kycStatus,
  },
 
  {
    title: 'Actions',
    dataIndex: 'actions',
  }

];

export default columns;