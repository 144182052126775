const columns = [
    {
        title: 'Name',
        dataIndex: 'contactName',
        // sorter: (a, b) => a?.contactName?.localeCompare(b?.contactName),
        fixed: "left"
    },
    {
        title: 'Email',
        dataIndex: 'email',
        // sorter: (a, b) => a?.email?.localeCompare(b?.email),
    },
    {
        title: 'Phone No',
        dataIndex: 'mobileNo',
       
    },
    {
        title: 'Commission User Name',
        dataIndex: 'commissionUserName',
       
    },
    
    {
        title: 'Affiliate Id',
        dataIndex: 'affiliateId',
        // sorter: (a, b) => a?.affiliateId?.localeCompare(b?.affiliateId),
    },
    {
        title: 'Level',
        dataIndex: 'level',
        // sorter: (a, b) => a?.level?.localeCompare(b?.level),
    },
    {
        title: 'A/C Type',
        dataIndex: 'planName',
        // sorter: (a, b) => a?.planName?.localeCompare(b?.planName),
    },
    {
        title: 'A/C No.',
        dataIndex: 'accountNumber',
        // sorter: (a, b) => a?.accountNumber?.localeCompare(b?.accountNumber),
    },
    {
        title: 'lots',
        dataIndex: 'lots',
    },
    {
        title: 'Position Id',
        dataIndex: 'positionId',
        // sorter: (a, b) => a?.lotSize?.localeCompare(b?.lotSize),
    },
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        // sorter: (a, b) => a?.symbol?.localeCompare(b?.symbol),
    },
    {
        title: 'Open Price',
        dataIndex: 'openPrice',
        // sorter: (a, b) => a?.openPrice?.localeCompare(b?.openPrice),
    },
    {
        title: 'Close Price',
        dataIndex: 'closePrice',
        // sorter: (a, b) => a?.closePrice?.localeCompare(b?.closePrice),
    },
    // {
    //     title: 'Open Time',
    //     dataIndex: 'openTime',
    //     sorter: (a, b) => new Date(a.openTime) - new Date(b.openTime),
    // },
    {
        title: 'Close Time',
        dataIndex: 'closeTime',
        // sorter: (a, b) => new Date(a.closeTime) - new Date(b.closeTime),
    },
    {
        title: 'Profit',
        dataIndex: 'profit',
        // sorter: (a, b) => a.profit - b.profit,
    },
    {
        title: 'Commission',
        dataIndex: 'commision',
        // sorter: (a, b) => a?.commision?.localeCompare(b?.commision),
    },
    // {
    //     title: 'Comments',
    //     dataIndex: 'comments',
    //     sorter: (a, b) => a?.comments?.localeCompare(b?.comments),
    // },
    // {
    //     title: 'Commission Type',
    //     dataIndex: 'commissionType',
    //     sorter: (a, b) => a?.commissionType?.localeCompare(b?.commissionType),
    // },
    // {
    //     title: 'PIP Value',
    //     dataIndex: 'pipValue',
    //     sorter: (a, b) => a?.pipValue?.localeCompare(b?.pipValue),
    // },
    // {
    //     title: 'Brokerage',
    //     dataIndex: 'brokerage',
    //     sorter: (a, b) => a?.brokerage?.localeCompare(b?.brokerage),
    // },
    {
        title: 'Created Date',
        dataIndex: 'createdDate',
        // sorter: (a, b) => new Date(a.transactionDate) - new Date(b.transactionDate),
    },
    // {
    //     title: 'Rollback',
    //     dataIndex: 'actions',
    //     fixed:"right"
    // }
];

export default columns;