import React, { useState, useEffect } from 'react';
import { Form, Select, Spin } from 'antd';
import { CreateMT5AccountApi, GetAccountTypesApi } from '../../../api/request/client';
import { useNavigate, useParams } from 'react-router-dom';
import AccountCard from './AccountCard.jsx';
import LoadableButton from '../../../components/buttons/LoadableButton.jsx';
import ModalComponent from '../../../components/global/modal/ModalComponent.jsx';
import toast from 'react-hot-toast';

export default function MT5Account() {

  const params = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [accountTypes, setAccountTypes] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [liveInitialValues, setLiveInitialValues] = useState({ leverage: null });
  const [isOpenLiveForm, setIsOpenLiveForm] = useState(false);
  const [isLiveFormLoading, setIsLiveFormLoading] = useState(false);
  const [leverageOptions, setLeverageOptions] = useState([{ value: 20, label: '20' }, { value: 50, label: '50' }, { value: 100, label: '100' }, { value: 200, label: '200' }, { value: 300, label: '300' }, { value: 400, label: '400' }, { value: 500, label: '500' }]);

  const getAcounts = async () => {
    try {
      setIsLoading(true);
      let apiParams = {
        UserId: params.id,
        AccountType: true
      }
      const { data } = await GetAccountTypesApi(new URLSearchParams(apiParams).toString());
      if (data?.success) {
        setAccountTypes(data?.data);
      }
      setIsLoading(false);
    } catch (error) { }
  }

  useEffect(() => {
    getAcounts();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [isOpenLiveForm]);

  const createAccount = (account) => {
    setSelectedAccount(account);
    setIsOpenLiveForm(true);
  }

  const handleLiveAccount = async (values) => {
    try {
      setIsLiveFormLoading(true);
      let apiParams = {
        depositAmount: 0,
        groupId: selectedAccount?.groupId,
        groupName: selectedAccount?.groupName,
        isDemoAccount: false,
        isLiveAccount: true,
        leverage: values?.leverage,
        planId: selectedAccount?.planId,
        userId: params?.id
      }
      const { data } = await CreateMT5AccountApi(apiParams);
      if (data?.success) {
        setIsLiveFormLoading(false);
        toast?.success(data?.message);
        navigate(`/client_details/${params?.id}`);
      }
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsLiveFormLoading(false);
    }
  }

  return (
    <div>
      <div className='border rounded p-4 lg:p-6'>
        {isLoading ? (
          <div className="flex justify-center flex-col my-[20%]">
            <Spin size="large" />
            <p className="primary text-center mt-2">Loading...</p>
          </div>
        ) : (
          <div className='flex flex-wrap justify-center items-center gap-8 mx-auto' >
            {
              accountTypes?.map((account, index) => (
                <AccountCard key={index} account={account} handleSubmit={createAccount} type='live' />
              ))
            }
          </div>
        )}
      </div>

      {/* Open live account form modal */}
      <ModalComponent isOpen={isOpenLiveForm} setIsOpen={setIsOpenLiveForm} title='Open Live Account'>
        <Form className='w-full' autoComplete="off" form={form} initialValues={liveInitialValues} onFinish={handleLiveAccount}>
          <div className='ant-select-selector-white'>
            <label className='text-base text-[#2F2B3DCC] font-medium'>Leverage</label>
            <Form.Item name='leverage' rules={[{ required: true, message: 'Please select leverage.' }]}>
              <Select className='border-primary w-full mt-1' placeholder='Select leverage' options={leverageOptions} />
            </Form.Item>
          </div>
          <div className='flex justify-center items-center gap-5 mt-10'>
            <button type='button' disabled={isLiveFormLoading} className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500' onClick={() => setIsOpenLiveForm(false)}>Cancel</button>
            <LoadableButton
              className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover duration-500'
              disabled={isLiveFormLoading}
              type="Submit"
              lable='Proceed'
              isLoading={isLiveFormLoading}
              loadingLable='Opening Live Account...'
            />
          </div>
        </Form>
      </ModalComponent>
    </div>
  )
}