import React,{useState,useEffect} from 'react'
import { Input, Form,Select } from "antd";
import ModalComponent from "../../../components/global/modal/ModalComponent";
import toast from "react-hot-toast";
import { mt5detailsApi } from "../../../api/request/client.js";
import { GetTraderAccountsByIdApi} from '../../../api/request/client.js'
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";


function MT5LoginDetails({mT5LoginDetails,setmT5LoginDetails,id}) {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState()
  const [isFaqLoading, setIsFaqLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isAccTableLoading, setIsAccTableLoading] = useState(false);

  const getTraderAccounts = async () => {
    try {
      setIsAccTableLoading(true);
      const { data } = await GetTraderAccountsByIdApi(id,true);
      if (data?.success) {
        setData(data?.data?.map((item) => ({ ...item, value: item?.login, label: item?.login })));
      }
      setIsAccTableLoading(false);
    } catch (error) {
      setIsAccTableLoading(false);
    }
  }

  useEffect(() => {
    getTraderAccounts();
  }, []);
  

  const handleSubmit = async (values) => {
    setIsFaqLoading(true);
    let params = {
      ...values,
      userId : id
    };

    try {
      
        const { data } = await mt5detailsApi(params);
        if (data?.success) {
          toast.success(data?.message);
          setmT5LoginDetails(false);
          form.resetFields();
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message || 'An error occurred');
    } finally {
      setIsFaqLoading(false);
    }
  };

  useEffect(() => {
    if (!mT5LoginDetails) {
      form.resetFields();
      setmT5LoginDetails(false);
    };
  }, [mT5LoginDetails]);

  return (
    <div>
      <ModalComponent isOpen={mT5LoginDetails} setIsOpen={setmT5LoginDetails} title="MT5 Login Details" width={500}>
        <Form className='w-full' autoComplete="off" form={form} initialValues={initialValues} onFinish={handleSubmit}>
          
            <div>
              <div className='input-white'>
                <label className='text-base text-[#2F2B3DCC] font-medium'>All Account</label>
                <Form.Item name="login" rules={[{ required: true, message: 'Please select a account.' }]}>
                <Select placeholder="Select account" className="w-full mt-1" options={data} />
              </Form.Item>
              </div>
            </div>
            <div className='input-white mt-5'>
              <label className='text-base text-[#2F2B3DCC] font-medium'>Select Password Type</label>
              <Form.Item name="passType" rules={[{ required: true, message: 'Please select a password type.' }]}>
                <Select placeholder="Select password type" className="w-full mt-1" options={[{ value: "resend", label: "Resend" }, { value: "change", label: "Change" }]} />
              </Form.Item>
            </div>
         
          <div className='flex justify-center gap-5 flex-wrap mt-6'>
           
            <div className="flex gap-5">
              <button type='button' onClick={() => setmT5LoginDetails(false)} className='bg-primary text-sm text-white font-semibold rounded-lg px-6 py-2 uppercase themeHover duration-500'>Cancel</button>
              <LoadableButton
              type='submit' className='bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover duration-500'
              disabled={isFaqLoading}
              lable="Submit"
              isLoading={isFaqLoading}
              loadingLable="Send MT5 Login Details..."
            />
            </div>
          </div>
        </Form>
      </ModalComponent>
    </div>
  )
}

export default MT5LoginDetails
