const columns = [
  {
    title: "Transfer Method",
    dataIndex: "name",
  },
  {
    title: "Currency",
    dataIndex: "currency",
  },
  {
    title: "Fees / Commission(%)",
    dataIndex: "fees",
  },
  {
    title: "Processing Time",
    dataIndex: "processingTime",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
   {
    title: "Action",
    dataIndex: "action",
  }

];

export default columns;
