import React, { useState, useEffect } from "react";
import Pagination from "../../components/global/pagination/Pagination.jsx";
import columns from "../../columns/DepositReminderLog.js";
import TableWithSkeleton from '../../components/global/table/TableWithSkeleton.jsx';
import { DepositReminderLogApi } from '../../api/request/ibCommission.js';
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function DepositReminderLog() {

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getDepositReminder = async () => {
    try {
      let params = {
        PageNumber: page,
        PageSize: pageSize, 
      }
      setIsLoading(true);
      const { data } = await DepositReminderLogApi(new URLSearchParams(params).toString());
      setData(
        data?.data?.map((item) => {
          return {
            ...item,
            createdDate: moment(item?.createdDate).format('DD-MM-YYYY, hh:mm A'),
          };
        })
      );
      setTotalRecord(data?.totalRecords);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getDepositReminder();
  }, [page, pageSize]);

  return (
    <div>
      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">Logs</p>
        </div>
        <div className="my-6 w-auto">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
          <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div>
      </div>
    </div>
  )
}
