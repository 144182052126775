import React from 'react';

export default function Pin() {
    const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--theme')?.trim()

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={primaryColor} className="size-5 cursor-pointer">
            <path fill-rule="evenodd" d="M18.97 3.659a2.25 2.25 0 0 0-3.182 0l-10.94 10.94a3.75 3.75 0 1 0 5.304 5.303l7.693-7.693a.75.75 0 0 1 1.06 1.06l-7.693 7.693a5.25 5.25 0 1 1-7.424-7.424l10.939-10.94a3.75 3.75 0 1 1 5.303 5.304L9.097 18.835l-.008.008-.007.007-.002.002-.003.002A2.25 2.25 0 0 1 5.91 15.66l7.81-7.81a.75.75 0 0 1 1.061 1.06l-7.81 7.81a.75.75 0 0 0 1.054 1.068L18.97 6.84a2.25 2.25 0 0 0 0-3.182Z" clip-rule="evenodd" />
        </svg>
    )
}
