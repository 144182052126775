import React, { useEffect, useState } from 'react';
import columns from '../../../columns/ibApproved/pending';
import ibColumns from '../../../columns/ibApproved/IbAccept.js';
import TableWithSkeleton from '../../../components/global/table/TableWithSkeleton';
import ModalComponent from '../../../components/global/modal/ModalComponent';
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import { GetAllAccountTypeApi, GetAllibApi, GetAllIBByIdApi, IBRejectApproveApi } from "../../../api/request/ibapproved.js";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { Form, Spin, Input } from 'antd';
import LoadableButton from '../../../components/buttons/LoadableButton.jsx';
import toast from 'react-hot-toast';
import Eye from '../../../assets/Eye.jsx';
const { TextArea } = Input;

export default function IbPending({ dates }) {

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [rejectForm] = Form.useForm();
  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--theme'))?.trim();
  const lightPrimaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--theme-light'))?.trim();
  const [isOpenAcceptModal, setIsOpenAcceptModal] = useState(false);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  const [isLoadingIBDetails, setIsLoadingIBDetails] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [data, setData] = useState([]);
  const [ibdata, setIbData] = useState([]);
  const [selectedIBId, setSelectedIBId] = useState('');
  const [iBDetails, setIBDetails] = useState({});
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [initialValues, setInitialValues] = useState({ comment: '' });
  const [sorter, setSorter] = useState({ SortBy: 'requestDate', SortOrder: '' });

  const getAllIB = async () => {
    try {
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        SearchText: dates,
        FilterText: "Pending",
        ...sorter
      }
      setIsLoading(true);
      const { data } = await GetAllibApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              name: `${item?.firstName} ${item?.lastName}`,
              requestDate: (item?.requestDate ? moment(item?.requestDate).format("DD-MM-YYYY, hh:mm A") : null),
              action: (
                <div className="flex">
                  <button type="button" onClick={() => { setSelectedIBId(item?.userId); setIsPending(true) }}><Eye /></button>
                  <button className="mr-3 ml-3" onClick={() => openAcceptModal(item?.userId)} ><img src="assets/icons/accept_icon.svg" className='min-w-6 max-w-6' alt="icon" /></button>
                  <button onClick={() => openRejectModal(item?.userId)}><img src="assets/icons/delete_icon.svg" alt="icon" className='min-w-6 max-w-6' /></button>
                </div>
              ),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const getAccountTypes = async (id) => {
    try {
      setIsLoadingAccounts(true);
      let apiParams = {
        UserId: id,
        AccountType: true
      }
      const { data } = await GetAllAccountTypeApi(new URLSearchParams(apiParams).toString());
      if (data?.success) {
        setAccounts(data?.data);
      }
      setIsLoadingAccounts(false);
    } catch (error) {
      setIsLoadingAccounts(false);
    }
  }
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
    } else {
      setSorter({ SortBy: 'requestDate', SortOrder: '' });
    }
  };

  useEffect(() => {
    getAllIB();
  }, [page, pageSize, sorter,dates]);

  useEffect(() => {
    setPage(1);
}, [dates]);

  const getIBById = async () => {
    try {
      setIsLoadingIBDetails(true);
      const { data } = await GetAllIBByIdApi(selectedIBId);
      setIBDetails(data?.data);
      setIsLoadingIBDetails(false);
    } catch (error) {
      setIsLoadingIBDetails(false);
    }
  }

  useEffect(() => {
    if (selectedIBId) {
      getIBById();
    }
  }, [selectedIBId]);

  useEffect(() => {
    setSelectedAccounts([]);
    setSelectedIndex([]);
    setIbData([]);
    form.resetFields();
  }, [isOpenAcceptModal]);

  useEffect(() => {
    setInitialValues({ comment: '' });
    rejectForm.resetFields();
  }, [isOpenRejectModal]);

  const openAcceptModal = (id) => {
    setSelectedId(id);
    setIsOpenAcceptModal(true);
    getAccountTypes(id);
  }

  const openRejectModal = (id) => {
    setSelectedId(id);
    setIsOpenRejectModal(true);
  }

  const accountSelectionHandler = () => {
    setIsLoading(true);
    let array = [];
    selectedAccounts?.map((account, index) => (
      array.push({ no: index + 1, planName: account?.planName, maxCommission: account?.maxCommission, commisionamount: '', groupId: account?.groupId })
    ));
    setIbData(array?.map((item) => {
      return {
        ...item,
        commisionamount: (
          <Form.Item name={`commission-${item.no}`} rules={[
            { required: true, message: 'Please enter commission.' },
            { validator: (_, value) => value > item.maxCommission ? Promise.reject(new Error(`Maximum commission should be ${item.maxCommission}.`)) : Promise.resolve(), },
          ]} >
            <Input
              prefix={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={primaryColor} class="size-6">
                <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z" />
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z" clip-rule="evenodd" />
              </svg>}
              type='number'
              className='py-2'
              placeholder='Enter commission'
            />
          </Form.Item>
        )
      };
    }));
    setIsLoading(false);
  }

  const addAccount = (account, index) => {
    const updatePlansArray = (plansArray, newPlan) => {
      const index = plansArray.findIndex(plan => plan.planId === newPlan.planId);
      if (index !== -1) {
        plansArray.splice(index, 1);
      } else {
        plansArray.push(newPlan);
      }
      return plansArray;
    }
    setSelectedIndex((prevNumbers) => {
      if (prevNumbers.includes(index)) {
        return prevNumbers.filter((n) => n !== index);
      } else {
        return [...prevNumbers, index];
      }
    });
    setSelectedAccounts(updatePlansArray(selectedAccounts, account));
  }

  const onFinish = async (values) => {
    let commissionArray = [];
    let groupIdArray = [];
    ibdata?.map((item, index) => (
      commissionArray.push(values?.[`commission-${index + 1}`]),
      groupIdArray.push(item?.groupId)
    ))
    try {
      setIsLoadingSubmit(true);
      let apiParams = {
        userId: selectedId,
        comment: '',
        isApproved: true,
        isRejected: false,
        commission: commissionArray,
        groupId: groupIdArray,
        isNewRecord: true
      }
      const { data } = await IBRejectApproveApi(apiParams);
      if (data?.success) {
        toast.success(data?.message);
        setSelectedAccounts([]);
        setSelectedIndex([]);
        setData([]);
        form.resetFields();
        getAllIB();
        setIsOpenAcceptModal(false);
      }
      setIsLoadingSubmit(false);
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsLoadingSubmit(false);
    }
  };

  const requestRejectHandler = async (values) => {
    try {
      setIsLoadingSubmit(true);
      let apiParams = {
        ...values,
        userId: selectedId,
        isApproved: false,
        isRejected: true,
      }
      const { data } = await IBRejectApproveApi(apiParams);
      if (data?.success) {
        toast.success(data?.message);
        rejectForm.resetFields();
        getAllIB();
        setIsOpenRejectModal(false);
      }
      setIsLoadingSubmit(false);
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsLoadingSubmit(false);
    }
  }

  const editHandler = () => {
    setIbData([]);
    form.resetFields();
  }

  return (
    <>
      {/* pending table */}
      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">IB Pending Request</p>
        </div>
        <div className="my-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} onChange={handleTableChange}/>
          <Pagination total={totalRecord} pageSize={pageSize} setPageSize={setPageSize} page={page} setPage={setPage} />
        </div>
      </div>

      {/* pending modal */}
      <ModalComponent isOpen={isPending} setIsOpen={setIsPending} title='IB Details' width={1300}>
        {
          isLoadingIBDetails ? (
            <div className="flex justify-center flex-col my-[5%]">
              <Spin size="large" />
              <p className="primary text-center mt-2">Loading...</p>
            </div>
          ) : (
            <div className='p-5'>
              <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10 '>
                <div>
                  <p className='text-base font-normal '>First Name</p>
                  <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.firstName}</p>
                </div>
                <div>
                  <p className='text-base font-normal '>Last Name</p>
                  <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.lastName}</p>
                </div>
                <div>
                  <p className='text-base font-normal '>Mobile No.</p>
                  <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.phoneNo}</p>
                </div>
                <div>
                  <p className='text-base font-normal '>Email</p>
                  <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.email}</p>
                </div>
                <div>
                  <p className='text-base font-normal '>Request Time</p>
                  <p className='text-xl primary font-semibold  mt-2'>{iBDetails?.requestDate ? moment(iBDetails?.requestDate).format('DD-MM-YYYY, hh:mm A') : '--'}</p>
                </div>
              </div>
            </div>
          )
        }
      </ModalComponent>

      {/* Accept ib request modal */}
      <ModalComponent isOpen={isOpenAcceptModal} setIsOpen={setIsOpenAcceptModal} title={'Accept IB Request'} width={1200}>
        {
          isLoadingAccounts ? (
            <div className="flex justify-center flex-col my-[10%]">
              <Spin size="large" />
              <p className="primary text-center mt-2">Loading...</p>
            </div>
          ) : (
            <div>
              {ibdata?.length > 0 ? (
                <div>
                  <div className='flex justify-end'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={primaryColor} onClick={editHandler} className="size-6 cursor-pointer">
                      <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                      <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
                    </svg>
                  </div>
                  <div className="my-6">
                    <Form form={form} onFinish={onFinish}>
                      <TableWithSkeleton data={ibdata} columns={ibColumns} />
                      {
                        data?.length > 0 &&
                        <div className='mt-6 mx-6 flex justify-end'>
                          <Form.Item>
                            <div className='flex items-center justify-end gap-5'>
                              <button type='button' onClick={() => setIsOpenAcceptModal(false)} className='bg-secondary text-sm text-white font-semibold px-6 py-2 rounded-lg themeSecondaryHover duration-500'>Cancel</button>
                              <LoadableButton
                                type='submit'
                                className='bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover duration-500'
                                lable='Approve'
                                loadingLable='Approving request...'
                                isLoading={isLoadingSubmit}
                              />
                            </div>
                          </Form.Item>
                        </div>
                      }
                    </Form>
                  </div>
                </div>
              ) : (
                <div>
                  <div className='grid grid-cols-4'>
                    {accounts?.map((account, index) => (
                      <div className='px-2' key={index}>
                        <div className='cursor-pointer border rounded-md p-2' style={{ borderColor: `${selectedIndex.includes(index) ? primaryColor : lightPrimaryColor}` }} onClick={() => addAccount(account, index)}>
                          <p className='text-center font-semibold text-lg'>{account?.planName}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='flex justify-center mt-6'>
                    <button onClick={accountSelectionHandler} className='bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover duration-500'>Proceed</button>
                  </div>
                </div>
              )}
            </div>
          )
        }
      </ModalComponent>

      {/* Reject ib request modal */}
      <ModalComponent isOpen={isOpenRejectModal} setIsOpen={setIsOpenRejectModal} title={'Reject IB Request'} width={600}>
        <Form form={rejectForm} initialValues={initialValues} onFinish={requestRejectHandler}>
          <label className='text-base text-[#2F2B3DCC] font-medium'>Reason of rejection</label>
          <Form.Item name="comment" rules={[{ required: true, message: 'Please enter comment.' }]}>
            <TextArea rows={4} placeholder='Describe reason...' />
          </Form.Item>
          <div className='flex items-center justify-end gap-5 mt-5'>
            <button type='button' onClick={() => setIsOpenRejectModal(false)} className='bg-secondary text-sm text-white font-semibold px-6 py-2 rounded-lg themeSecondaryHover duration-500'>Cancel</button>
            <LoadableButton
              type='submit'
              className='bg-primary text-sm text-white font-semibold px-6 py-2 rounded-lg themeHover duration-500'
              lable='Reject'
              loadingLable='Rejecting request...'
              isLoading={isLoadingSubmit}
            />
          </div>
        </Form>
      </ModalComponent>
    </>
  )
}
