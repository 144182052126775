const columns = [
    {
        title: 'Transfer Method',
        dataIndex: 'name',
        // sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        // sorter: (a, b) => a?.currency?.localeCompare(b?.currency),
    },
    {
        title: 'Fees / Commission(%)',
        dataIndex: 'fees',
        sorter: (a, b) => a?.fees - b?.fees,
    },
    {
        title: 'Processing Time',
        dataIndex: 'processingTime',
        // sorter: (a, b) => a?.processingTime?.localeCompare(b?.processingTime),
    },
    {
        title: '',
        dataIndex: 'status',
    }
];

export default columns;