import React, { useState, useEffect } from 'react';
import { Avatar, Spin } from 'antd';
import columns from "../../../columns/AccountHistory.js";
import TableWithSkeleton from '../../../components/global/table/TableWithSkeleton';
import { useParams } from 'react-router-dom';
import { GetTraderaccountByIdApi, GetTraderaccountDetailsApi } from '../../../api/request/client.js';

export default function LiveAccountDetails() {

    const { loginId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingHistory, setIsLoadingHistory] = useState(false);
    const [trader, setTrader] = useState({});
    const [accountdetails, setAccountdetails] = useState([]);

    const getTraderaccountById = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetTraderaccountByIdApi(loginId);
            if (data?.success) {
                setTrader(data?.data);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getallaccountdetails = async () => {
        try {
            setIsLoadingHistory(true);
            const { data } = await GetTraderaccountDetailsApi(loginId);
            if (data?.success) {
                setAccountdetails(data?.data?.map(item => {
                    return {
                        ...item,
                        price: item.price?.toFixed(2),
                        priceSL:item?.priceSL?.toFixed(2),
                        priceTP: item?.priceTP?.toFixed(2),
                        volume: item?.volume?.toFixed(2),
                        marketAsk:item?.marketAsk?.toFixed(3),
                        marketBid:item?.marketBid?.toFixed(3),
                        rateProfit:item?.rateProfit?.toFixed(3),
                        rateMargin:item?.rateMargin?.toFixed(3),
                    }
                }));
            }
            setIsLoadingHistory(false);
        } catch (error) {
            setIsLoadingHistory(false);
        }
    }

    useEffect(() => {
        getTraderaccountById();
        getallaccountdetails()
    }, []);

    return (
        <div>
            <div className="border-2 border-light rounded-xl p-4 lg:p-6">
                {(isLoading) ? (
                    <div className="flex justify-center flex-col my-[12%]">
                        <Spin size="large" />
                        <p className="primary text-center mt-2">Loading...</p>
                    </div>
                ) : (
                    <div>
                        <div className="flex flex-wrap justify-between items-center gap-5">
                            <div className="flex items-center gap-3">
                                <Avatar size={50} className="uppercase bg-primary" src={trader?.profileImage}>{trader?.firstName?.charAt(0)}{trader?.lastName?.charAt(0)}</Avatar>
                                <p className="font-bold text-lg capitalize">{trader?.firstName} {trader?.lastName}</p>
                                {trader?.isIBUser == 'Yes' &&
                                    <div className="bg-primary-light rounded-md px-2"><p className="primary text-sm font-semibold">IB User</p></div>
                                }
                            </div>
                        </div>
                        <div className="w-full grid md:grid-cols-2 xl:grid-cols-4 gap-5 mt-5">
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>First Name</p>
                                <p className='primary text-lg font-semibold mt-2'>{trader?.firstName}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Last Name</p>
                                <p className='primary text-lg font-semibold mt-2'>{trader?.lastName}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Country</p>
                                <p className='primary text-lg font-semibold mt-2'>{trader?.country}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Account Number</p>
                                <p className='primary text-lg font-semibold mt-2'>{trader?.login}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Group Name</p>
                                <p className='primary text-lg font-semibold mt-2'>{trader?.group}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Balance</p>
                                <p className='primary text-lg font-semibold mt-2'>$ {trader?.balance}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Leverage</p>
                                <p className='primary text-lg font-semibold mt-2'>{trader?.leverage}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Profit</p>
                                <p className='primary text-lg font-semibold mt-2'>$ {trader?.profit}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Credit</p>
                                <p className='primary text-lg font-semibold mt-2'>$ {trader?.credit}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Margin</p>
                                <p className='primary text-lg font-semibold mt-2'>${trader?.margin}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Free Margin</p>
                                <p className='primary text-lg font-semibold mt-2'>$ {trader?.marginFree}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Commission</p>
                                <p className='primary text-lg font-semibold mt-2'>$ {trader?.commission}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Equity</p>
                                <p className='primary text-lg font-semibold mt-2'>{trader?.equity}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Previous Day Balance</p>
                                <p className='primary text-lg font-semibold mt-2'>$ {trader?.balancePrevDay}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Previous Day Equity</p>
                                <p className='primary text-lg font-semibold mt-2'>$ {trader?.equityPrevDay}</p>
                            </div>
                            <div>
                                <p className='text-[#1E232CC7] text-lg'>Account Type</p>
                                <p className='primary text-lg font-semibold mt-2'>{trader?.acType}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
                <div className="px-4 lg:px-6">
                    <p className="text-[#2F2B3D] text-xl font-semibold">History of {loginId}</p>
                </div>
                <div className="my-6">
                    <TableWithSkeleton columns={columns} data={accountdetails} loading={isLoadingHistory} />
                    {/* <Pagination total={100} pageSize={pageSize} setPageSize={setPageSize} /> */}
                </div>
            </div>
        </div>
    )
}
