const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        // sorter: (a, b) => a.name - b.name,
    },

    {
        title: 'Wallet Account No.',
        dataIndex: 'walletAccountNo',
        sorter: true,
        // sorter: (a, b) => a.walletAccountNo - b. walletAccountNo,
    },
    {
        title: 'Wallet Balance',
        dataIndex: 'balance',
        sorter: true,
        // sorter: (a, b) => a.balance - b.balance,
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        sorter: true,
        // sorter: (a, b) => a.amount - b.amount,
    },
    {
        title: 'Withdraw Type',
        dataIndex: 'withdrawType',
        sorter: true,
        // sorter: (a, b) => a.withdrawType - b.withdrawType,
    },
    {
        title: 'Request Date',
        dataIndex: 'requestDate',
        sorter: true,
        // sorter: (a, b) => a.requestDate - b.requestDate,
    },
    {
        title: 'Accepted Date',
        dataIndex: 'actionDate',
        sorter: true,
        // sorter: (a, b) => a.actionDate - b.actionDate,
    },
    {
        title: 'Action By',
        dataIndex: 'actionBy',
        sorter: true,
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        
    }

];

export default columns;