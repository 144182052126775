import React, { useState, useEffect } from "react";
import { Form, Input, Upload, Image, Switch } from "antd";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
import { AddUpdatePromotionKitApi} from "../../../api/request/promotionKit.js";
import toast from "react-hot-toast";
import ImgCrop from 'antd-img-crop';

export default function Addpromotionkit({ isOpen, setIsOpen,getAllKits }) {
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState({ height: '', width: '', uploadPromotionKit: null });
    const [isLoadingAddKitModal, setIsLoadingAddKitModal] = useState(false);
    const [file, setFile] = useState(null);
    const [newformValue, setFormValueNew] = useState({ height: '', width: '' });
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);

    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const onChange = (e) => {
        setFormValueNew({ ...newformValue, [e.target.name]: e.target.value });
    }

    const handleChange = ({ file: newFile }) => {
        if (newFile.status === 'removed') {
            setFile(null);
            form.setFieldsValue({ UploadPromotionKit: null });
        } else {
            setFile(newFile);
            form.setFieldsValue({ UploadPromotionKit: newFile });
        }
    };

    const UploadButton = (
        <button type="button">
            <div className="flex justify-center"><img src="assets/icons/Upload_Document_icon.svg" alt="icon" /></div>
            Upload
        </button>
    );

     const addNewKitHandler = async (values) => {
        try {
            setIsLoadingAddKitModal(true);
            let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };

            let params = {
                width: Number(values?.width),
                height: Number(values?.height),
                IsActive: true,
                UploadPromotionKit: values?.UploadPromotionKit?.originFileObj
            }
            const { data } = await AddUpdatePromotionKitApi(params, apiHeader);
            if (data?.success) {
                getAllKits();
                toast.success(data?.message);
                setFormValueNew({ height: '', width: '' })
                setIsOpen(false);
                setFile(null);
                form.resetFields();
            }
            setIsLoadingAddKitModal(false);
        } catch (error) {
            if (error?.response?.data?.error?.message) {
                toast.error(error?.response?.data?.error?.message);
            }
            setIsLoadingAddKitModal(false);
        }
    }

    useEffect(() => {
        if (setIsOpen) {
            form.resetFields();
            setInitialValues({ height: '', width: '' })
            setFormValueNew({ height: '', width: '' })
        }
    }, [setIsOpen])
  return (
    <div>
          <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} title={"Add Promotion Kit"} width={600}>
                <Form className='w-full' autoComplete="off" form={form} initialValues={initialValues} onFinish={addNewKitHandler}>
                    <div className="grid sm:grid-cols-2 gap-5">
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">Width</label>
                            <Form.Item name="width" rules={[{ validator: (_, value) => value > 0 ? Promise.resolve() : Promise.reject('Width must be greater than 0.') }]}>
                                <Input type="number" placeholder="Width" className="mt-1 w-full py-2" name="width" onChange={(e) => onChange(e)} />
                            </Form.Item>
                        </div>
                        <div className="input-white">
                            <label className="text-base text-[#2F2B3DCC] font-medium">Height</label>
                            <Form.Item name="height" rules={[{ validator: (_, value) => value > 0 ? Promise.resolve() : Promise.reject('Height must be greater than 0.') }]}>
                                <Input type="number" placeholder="Height" className="mt-1 w-full py-2" name="height" onChange={(e) => onChange(e)} />
                            </Form.Item>
                        </div>
                        <div className="sm:col-span-2">
                            {Number(newformValue.height) && Number(newformValue.width) ? (
                                <div>
                                    <label className="text-base text-[#2F2B3DCC] font-medium">Promotion Kit Image ({newformValue.width}x{newformValue.height})</label>
                                    <Form.Item name="UploadPromotionKit" rules={[{ required: true, message: "Please upload a image." }]}>
                                        <ImgCrop rotationSlider showGrid aspect={newformValue?.width / newformValue?.height}>
                                            <Upload
                                                customRequest={({ file, onSuccess }) => { onSuccess("ok") }}
                                                className="mt-1"
                                                listType="picture-card"
                                                fileList={file ? [file] : []}
                                                onPreview={handlePreview}
                                                onChange={handleChange}
                                            >
                                                {file ? null : UploadButton}
                                            </Upload>
                                        </ImgCrop>
                                    </Form.Item>
                                    {previewImage && (
                                        <Image
                                            wrapperStyle={{ display: 'none' }}
                                            preview={{
                                                visible: previewOpen,
                                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            ) : (null)}
                        </div>
                    </div>
                    <div className="flex justify-end mt-5">
                        <LoadableButton
                            className="bg-primary text-sm text-white font-semibold rounded-lg px-12 py-2 uppercase themeHover  duration-500"
                            type="Submit"
                            lable="Submit"
                            isLoading={isLoadingAddKitModal}
                            loadingLable="Adding kit..."
                        />
                    </div>
                </Form>
            </ModalComponent>
    </div>
  )
}
