import api from "../index";

export const GetAllTraderApi = (params) => api.get(`Admin/GetAll-UserList?${params}`);
export const GetTraderByIdApi = (traderId) => api.get(`Admin/GetSingleUser/${traderId}`);
export const GetTraderAccountsByIdApi = (traderId,params) => api.get(`Admin/MT5Account-ByUserId/${traderId}/${params}`);
export const Downloadclientdetails = (traderId) => api.get(`Admin/Get-User-Profile-Pdf/${traderId}`);
export const AddTraderApi = (data) => api.post(`Admin/User-Register`, data);
export const GetTraderaccountByIdApi = (loginId) => api.get(`MTFiveAccount/UserGetSingle-LiveAccount/${loginId}`);
export const GetTraderaccountDetailsApi = (loginId) => api.get(`MTFiveAccount/TradingHistory-LiveAccount/${loginId}`);
export const GetuserpasswordApi = (params) => api.get(`Admin/GetUserPassword?${params}`);
export const GetParentTreeApi = (params) => api.get(`UserMaster/IBParentTree-UserById/${params}`);
export const GetIBTreeApi = (params) => api.get(`UserMaster/IBTree-GetAllUserById/${params}`);
export const UpdateUserStatusApi = (data) => api.put('Admin/Active-DeactiveUser', data);
export const UpdateTraderApi = (data) => api.put('Admin/Update-UserProfile', data);
export const UnassignUserRuleApi = (data) => api.put('RuleMaster/UnAssignRule', data);
export const AssignUserRuleApi = (data) => api.post('RuleMaster/AssignRule', data);
export const GetAssignableRulesApi = (userId) => api.get(`RuleMaster/GetRuleForAssign/${userId}`);
export const GetIBUserDetailsApi = (userId) => api.get(`Admin/IB-User-Details-ByUserId/${userId}`);
export const Verificationemaillink = (userId) => api.get(`Admin/Send-User-Verification-Email/${userId}`);
export const UpdateIBStatusApi = (params,query) => api.patch(`Admin/UpdateIBUserStatus/${params}?${query}`);

// MT5 account
export const GetAccountTypesApi = (params) => api.get(`DropDown/GetAll-AccountType?${params}`);
export const CreateMT5AccountApi = (data) => api.post('MTFiveAccount/CreateLive-Account', data);
export const UpdateMT5AccountLeverageApi = (data) => api.post('MTFiveAccount/LiveAccount-LeverageUpdate', data);
export const UpdateMT5AccountPasswordApi = (data) => api.post('MTFiveAccount/LiveAccount-MasterInvestor-PasswordChange', data);
export const VerifyLoginPasswordApi = (data) => api.post('UserMaster/Verify-Password', data);
export const AddMT5AccountbalanceApi = (data) => api.post('MTFiveAccount/DemoMT5-Deposit-Balance', data);

// Deposit
export const GetPaymentGatewayApi = () => api.get('PaymentGateway/GetActivePaymentGatways');
export const DepositAmountApi = (data) => api.post('WalletMaster/deposit-amount-trader-wallet', data);
export const GetPaymentGatewayByIdApi = (params) => api.get(`PaymentGateway/PaymentGatwayDetails?${params}`);

// Withdraw
export const GetWithdrawRequestApi = (params) => api.get(`Withdraw/GetWithdraw-RequestByUserId?${params}`);
export const GetWalletApi = (userId, params) => api.get(`WalletMaster/Get-WalletDetailsByRule/${userId}?${params}`);
export const AddWithdrawRequestApi = (data) => api.post('Withdraw/Create-Withdraw-Request', data);

// Internal-Transfer
export const WalletToAccountTransferApi = (data) => api.post('WalletMaster/Internal-Transfer-Wallet-to-Terminal', data);
export const AccountToWalletTransferApi = (data) => api.post('WalletMaster/Internal-Transfer-Terminal-to-Wallet', data);
export const GetMT5AccountsApi = (userId) => api.get(`DropDown/GetUserLive-MTFiveAccount/${userId}`);
export const sendCredentialsApi = (Id) => api.get(`Admin/SendUser-PwdDetails/${Id}`);
export const NotificationApi = (data, headers) => api.post('notification/sendNotification', data, headers);
export const ChangepasswordApi = (data) => api.put('Admin/Change-Password', data);
export const GetAlllogsApi = (Id, params) => api.get(`Admin/AuditLog-ByUserId/${Id}?${params}`);
export const mt5detailsApi = (data, headers) => api.post('MTFiveAccount/LiveAccount-ChangePassword', data, headers);
export const SendIBRequestApi = (data) => api.post('UserMaster/User-IBRequest', data);
export const UpdateIBApi = (params) => api.post(`IBMaster/Update-User-Level?${params}`);
export const GetGetIBAccountTypeApi = (traderId) => api.get(`Admin/Get-IB-Account-Type/${traderId}`);
