import { render } from "@testing-library/react";

const defaultRender = (value) => (value ? value : '--');
const columns = [
    {
        title: 'INR/USD',
        dataIndex: 'oldRate',
        render:defaultRender
    },
    // {
    //     title: 'Min coins per USD',
    //     dataIndex: 'email',
    // },
    {
        title: 'Date',
        dataIndex: 'modifiedDate',
    },
    
];


export default columns;