const columns = [
    {
        title: "Plan Name",
        dataIndex: "planName",
    },
    {
        title: "IB Commission",
        dataIndex: "commissionAmount",
    },
    {
        title: "Group",
        dataIndex: "groupName",
    }
];

export default columns;