const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Type',
        dataIndex: 'tutorialType',
    },
    {
        title: 'Created Date',
        dataIndex: 'createdDate',
    },
    {
        title: 'Status',
        dataIndex: 'status',
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
    },
];

export default columns;