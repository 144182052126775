const columns = [
    {
        title: 'Deal',
        dataIndex: 'deal',
    },
    {
        title: 'Position Id',
        dataIndex: 'positionId',
    },
    {
        title: 'Order',
        dataIndex: 'order',
    },
    {
        title: 'Symbol',
        dataIndex: 'symbol',
    },
    {
        title: 'Price',
        dataIndex: 'price',
    },
    {
        title: 'Price SL',
        dataIndex: 'priceSL',
    },
    {
        title: 'Price TP',
        dataIndex: 'priceTP',
    },
    {
        title: 'Volume',
        dataIndex: 'volume',
    },
    {
        title: 'PNL',
        dataIndex: 'pnl',
    },
    {
        title: 'Market Ask',
        dataIndex: 'marketAsk',
    },
    {
        title: 'Market Bid',
        dataIndex: 'marketBid',
    },
    {
        title: 'Profit Rate',
        dataIndex: 'rateProfit',
    },
    {
        title: 'Margin Rate',
        dataIndex: 'rateMargin',
    },
    {
        title: 'Comment',
        dataIndex: 'comment',
    },
    {
        title: 'Remarks',
        dataIndex: 'print',
    },
];

export default columns;