import { createSlice } from "@reduxjs/toolkit";

const brokerSlice = createSlice({
    name: "broker",
    initialState: {
        broker: {
            id: 1,
            firstName: "Admin",
            lastName: "CRM",
            email: "admincrm@yopmail.com",
            phoneNo: "1234567890",
            countryId: 0,
            logoFilePath: "https://9dottechno.com/wp-content/uploads/2023/04/9dot_logo_b.png",
            domainName: "localhost",
        }
    },
    reducers: {
        setBroker: (state, action) => {
            state.broker = action.payload
        }
    },
})

export const { setBroker } = brokerSlice.actions;

export default brokerSlice.reducer;