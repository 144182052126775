import axios from 'axios';
import { Cookies } from "react-cookie";
import { cleareCookies } from '../services/Cookies.js';

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, // api's base URL should be here
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
})

api.interceptors.request.use(

    config => {
        const cookies = new Cookies();
        const { token, passwordToken } = cookies.getAll();
        if (config.url == 'Authentication/Forgot-Password') {
            config.headers.Authorization = `Bearer ${passwordToken}`
            return config;
        }

        if (cookies?.cookies?.token) {
            config.headers.Authorization = `Bearer ${token}`
            return config;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default api;