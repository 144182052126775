import React, { useState } from 'react';
import LoadableButton from '../../buttons/LoadableButton';
import { Dropdown } from 'antd';
import { exportAsCSV } from '../../../services/exportAsCSV';
import { exportAsPDF } from '../../../services/exportAsPDF';

export default function ExportHandler({ exportHandler }) {

    const [isExportLoading, setIsExportLoading] = useState(false);
    const items = [
        { key: '1', label: <button onClick={() => handleExport('csv')} >Export as CSV</button> },
        // { key: '2', label: <button onClick={() => handleExport('pdf')}>Export as PDF</button> },
    ];

    const handleExport = async (type) => {
        setIsExportLoading(true);
        let { data, removableColumns, fileName } = await exportHandler();
        if (data && type == 'csv') {
            exportAsCSV(data, removableColumns, fileName);
        }
        if (data && type == 'pdf') {
            exportAsPDF(data, removableColumns, fileName);
        }
        setIsExportLoading(false);
    }

    return (
        <div>
            <Dropdown menu={{ items }} placement="bottomLeft" arrow trigger={['click']}>
                <LoadableButton
                    className="bg-primary text-sm text-white font-semibold px-4 py-2 rounded-lg themeHover  duration-500"
                    img={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M11.47 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06l-3.22-3.22V16.5a.75.75 0 0 1-1.5 0V4.81L8.03 8.03a.75.75 0 0 1-1.06-1.06l4.5-4.5ZM3 15.75a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    }
                    disabled={isExportLoading}
                    lable="Export"
                    isLoading={isExportLoading}
                    loadingLable="Exporting..."
                />
            </Dropdown>
        </div>
    )
}