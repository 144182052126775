
import React, { useState, useEffect } from "react";
import Pagination from "../../components/global/pagination/Pagination.jsx";
import columns from "../../columns/MT5Account/InactiveAccounts.js";
import TableWithSkeleton from "../../components/global/table/TableWithSkeleton.jsx";
import { GetAllInactiveAccounts} from "../../api/request/account.js";
import ModalComponent from "../../components/global/modal/ModalComponent.jsx";
import moment from "moment";
import Eye from "../../assets/Eye.jsx";

export default function InactiveAccounts() {

  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
 
  const [sorter, setSorter] = useState({ SortBy: 'actionDate', SortOrder: '' });

  const getAllTerminalOnlineUsers = async () => {
    try {
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        ...sorter
      }
      setIsLoading(true);
      const { data } = await GetAllInactiveAccounts(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              name:` ${item?.firstName} ${item?.lastName}`,
              actionDate: (item?.actionDate ? moment(item?.actionDate).format("DD-MM-YYYY, hh:mm A") : null),

              actions: (
                <div className="flex">
                  <button type="button" onClick={() => { setSelected(item); setIsOpenModal(true) }}><Eye /></button>
                </div>
              ),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }
  const handleTableChange = ( sorter) => {
    if (sorter?.order) {
        setSorter({ SortBy: sorter?.field, SortOrder: sorter?.order });
    } else {
        setSorter({ SortBy: 'actionDate', SortOrder: '' });
    }
};

  useEffect(() => {
    getAllTerminalOnlineUsers();
  }, [page, pageSize, filter, sorter]);


  return (
    <div>

      <div className="border-[2px] border-[var(--theme-light)] rounded-xl pt-4 lg:pt-6 w-full mt-5">
       
        <div className="my-6">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading}  onChange={handleTableChange}/>
          <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div>
      </div>


      {/* ---- Account Information ---- */}
      <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} title='Inactive Accounts' width={1300}>
        <div className='p-5'>
          <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10 '>
            <div>
              <p className='text-base font-normal '>First Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.firstName || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Last Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.lastName || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Account No.</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.login || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Affiliate Code</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.affiliateCode || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>email</p>
              <p className='text-xl primary font-semibold  mt-2'> {selected?.email || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Currency</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.currencyCode || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Group Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.groupName || "--"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Phone No</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.phoneNo || '--'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>groupName</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.groupName || '--'} %</p>
            </div>
            <div>
              <p className='text-base font-normal '>Leverage</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.leverage || '--'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Deposit</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.isDeposit === true ? "yes" : "NO"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>FTP</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.isFTP === true ? "yes" : "NO"}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Kyc</p>
              <p className='text-xl primary font-semibold  mt-2'>{selected?.isKyc === true ? "yes" : "NO"}</p>
            </div>
          </div>
        </div>       
      </ModalComponent>
    </div>
  )
}

