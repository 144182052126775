import api from "../index";

export const GetAllibApi = (params) => api.get(`Admin/GetAllUserIB?${params}`);
export const GetAllIBByIdApi = (IBId) => api.get(`Admin/GetSingleIBUser/${IBId}`);
export const GetCommissionAssignTreeApi = (userId, params) => api.get(`IBMaster/IBCommission-AssignTree/${userId}?${params}`);
export const GetIBCommissionTreeApi = (userId) => api.get(`IBMaster/IB-Commission-Tree/${userId}`);
export const UpdateIBCommissionTreeApi = (params) => api.post('IBMaster/Update-IB-Commission', params);
export const GetAllAccountTypeApi = (params) => api.get(`DropDown/GetAll-AccountType?${params}`);
export const IBRejectApproveApi = (data) => api.post('Admin/IB-Rejected-Approved', data);
export const AssignPlanToIBUserApi = (data) => api.post('IBMaster/Assign-Plan-To-IB-User', data);

