const defaultRender = (value) => (value ? value : '--');
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: defaultRender,
    // sorter: (a, b) => a?.name?.localeCompare(b?.name),

  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: defaultRender,
    // sorter: (a, b) => a?.email?.localeCompare(b?.email),

  },
  {
    title: 'Phone No',
    dataIndex: 'phoneNo',
    render: defaultRender,
    // sorter: (a, b) => a?.phoneNo?.localeCompare(b?.phoneNo),

  },
  
  {
    title: 'Created At',
    dataIndex: 'createdDate',
    render: defaultRender,
    // sorter: (a, b) => ((new Date(a.createdDate)) - (new Date(b.createdDate))),
  },
  {
    title: 'Created By',
    dataIndex: 'createdBy',
    render: defaultRender,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: defaultRender,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: defaultRender,
  }
];

export default columns;