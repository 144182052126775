import React, { useState, useEffect } from "react";
import { Input, Form, Select, Switch, Radio } from "antd";
import columns from "../../../columns/KycRules.js";
import ConfirmationModal from "../../../components/global/modal/ConfirmationModal.jsx";
import TableWithSkeleton from "../../../components/global/table/TableWithSkeleton.jsx";
import ModalComponent from "../../../components/global/modal/ModalComponent.jsx"
import Pagination from "../../../components/global/pagination/Pagination.jsx";
import { GetAllKYCRulesApi, GetUsersApi, UpdateRuleStatusApi, AddRuleApi, DeleteRuleApi } from "../../../api/request/settings/kycRules.js";
import Eye from '../../../assets/Eye.jsx';
import moment from "moment/moment.js";
import toast from "react-hot-toast";
import LoadableButton from "../../../components/buttons/LoadableButton.jsx";
const { TextArea } = Input;

export default function KycRules() {

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
  const [isStatusConfirmLoading, setIsStatusConfirmLoading] = useState(false);
  const [isRuleFormOpen, setIsRuleFormOpen] = useState(false);
  const [isRuleFormLoading, setIsRuleFormLoading] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [isRuleDetailsOpen, setIsRuleDetailsOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [initialValues, setInitialValues] = useState({ ruleName: '', ruleLevel: null, userId: null, isAuto: null, description: '' })
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [ruleNameFilled, setRuleNameFilled] = useState(false);
  const [ruleLevel, setRuleLevel] = useState(null);
  const [deleteRuleModal, setDeleteRuleModal] = useState(false);
  const [deleteRuleModalLoading, setDeleteRuleModalLoading] = useState(false);

  const statusHandler = (rule) => {
    setSelectedRule(rule);
    setIsStatusConfirmOpen(true);
  }

  const statusChangeConfirm = async () => {
    try {
      setIsStatusConfirmLoading(true);

      let params = {
        isAuto: !selectedRule?.isAuto,
        kycRuleId: selectedRule?.kycRuleId,
        ruleLevel: selectedRule?.ruleLevel,
        userId: selectedRule?.userId
      }

      const { data } = await UpdateRuleStatusApi(params);
      if (data?.success) {
        getRules();
        toast.success(data?.message);
        setSelectedRule(null);
      }

      setIsStatusConfirmLoading(false);
      setIsStatusConfirmOpen(false);
    } catch (error) {
      setIsStatusConfirmLoading(false);
    }
  }

  const getRules = async () => {
    try {
      setIsLoading(true);
      let params = {
        PageNumber: page,
        PageSize: pageSize,
        SearchText: searchText,
      }
      const { data } = await GetAllKYCRulesApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              autoManual: <Switch onChange={() => statusHandler(item)} checkedChildren="Auto" unCheckedChildren="Manual" checked={item?.isAuto} />,
              createdDate: item?.createdDate ? moment(item?.createdDate).format('DD-MM-YYYY LT') : null,
              action: (<>
                <button onClick={() => { setSelectedRule(item); setIsRuleDetailsOpen(true) }} ><Eye /></button>
                <button className="ms-2" onClick={() => {setSelectedRule(item); setDeleteRuleModal(true)}} ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D91819" className="w-6 h-6 cursor-pointer hover:stroke-[#aa0001] duration-500">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg></button>
              </>
              ),
            };
          })
        );
        setTotalRecord(data?.totalRecords);
      }
      setIsLoading(false);
    } catch (error) {
      setData([]);
      setIsLoading(false);
    }
  }

  const getUsers = async () => {
    try {
      let params = {
        UserType: 'ib'
      }
      const { data } = await GetUsersApi(new URLSearchParams(params).toString());
      if (data?.success) {
        setUsers(data?.data?.map((item) => ({ value: item?.userId, label: item?.name })));
      }
    } catch (error) { }
  }

  useEffect(() => {
    getRules();
  }, [page, pageSize, searchText]);

  useEffect(() => {
    form.resetFields();
  }, [isRuleFormOpen, isRuleDetailsOpen]);

  const handleRuleNameChange = (e) => {
    setRuleNameFilled(!!e.target.value);
  };

  const handleRuleLevelChange = (value) => {
    setRuleLevel(value);
  };

  const isUserFieldEnabled = ruleNameFilled && ruleLevel === 'ib';

  const handleSubmit = async (values) => {
    try {
      setIsRuleFormLoading(true);
      const { data } = await AddRuleApi(values);
      if (data?.success) {
        toast.success(data?.message);
      }
      getRules()
      setIsRuleFormLoading(false);
      setIsRuleFormOpen(false);
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsRuleFormLoading(false);
    }
  }
  const handleDeleteRule = async () => {
    try {
      setDeleteRuleModalLoading(true)
      const { data } = await DeleteRuleApi(selectedRule.kycRuleId);
      if (data?.success) {
        toast.success(data?.message);
        getRules();
        setDeleteRuleModalLoading(false);
        setDeleteRuleModal(false);
      }
    } catch (err) {
      if (err?.response?.data?.error?.message) {
        toast.error(err?.response?.data?.error?.message);
      }
      setDeleteRuleModalLoading(false);
    }
  }
  return (
    <div>
      <div className="flex flex-col sm:flex-row sm:justify-end mt-7">
        <div className="flex">
          <div className="mr-3">
            <Input
              size="large"
              style={{ width: 220 }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onPressEnter={() => setSearchText(search)}
              prefix={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                  <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clip-rule="evenodd" />
                </svg>
              }
              suffix={search &&
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { setSearchText(''); setSearch('') }} viewBox="0 0 24 24" fill="#bebebe" className="size-5 cursor-pointer duration-300 hover:fill-[#969595]">
                  <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                </svg>
              }
            />
          </div>
          <div>
            <button onClick={() => { getUsers(); setIsRuleFormOpen(true) }} className="flex items-center  bg-primary text-sm text-white font-semibold px-4 py-2 rounded-lg themeHover duration-500">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 mr-2">
                <path fill-rule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
              </svg>
              Add Rule
            </button>
          </div>
        </div>
      </div>

      <div className="border-[2px] border-[var(--theme-light)] rounded-[30px] pt-4 lg:pt-6 w-full mt-7">
        <div className="px-4 lg:px-6">
          <p className="text-[#2F2B3D] text-xl font-semibold">Rules</p>
        </div>
        <div className="mt-6 mb-6 ">
          <TableWithSkeleton columns={columns} data={data} loading={isLoading} />
          <Pagination total={totalRecord} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div>
      </div>

      {/* Rule status change confirmation modal */}
      <ConfirmationModal
        isOpen={isStatusConfirmOpen}
        setIsOpen={setIsStatusConfirmOpen}
        message='Are you sure to change the rule mode?'
        onConfirm={statusChangeConfirm}
        isLoading={isStatusConfirmLoading}
        loadingLabel='Changing status...'
      />

      {/* Delete rule confirmation modal */}
      <ConfirmationModal
        isOpen={deleteRuleModal}
        setIsOpen={setDeleteRuleModal}
        message='Are you sure you want to remove this rule?'
        onConfirm={handleDeleteRule}
        isLoading={deleteRuleModalLoading}
        loadingLabel='Deleting rule...'
      />

      {/* Add new rule modal */}
      <ModalComponent isOpen={isRuleFormOpen} setIsOpen={setIsRuleFormOpen} title='Create New Rule' width={800}>
        <Form className="w-full" autoComplete="off" form={form}
          initialValues={{ ...initialValues, isAuto: false }}
          onFinish={handleSubmit}>
          <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="input-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Rule Name</label>
              <Form.Item name="ruleName" rules={[{ required: true, message: 'Please enter rule name.' }]}>
                <Input placeholder="Enter rule name"
                  onChange={handleRuleNameChange}
                  className="w-full ps-4 py-2" />
              </Form.Item>
            </div>
            <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Level</label>
              <h3 className="py-1 ps-2 text-lg">IB</h3>
              {/* <Form.Item name="ruleLevel" rules={[{ required: true, message: 'Please select a level.' }]}>
                <Select placeholder="Select level" className="w-full mt-1" 
                options={[{ value: "ib", label: "IB" }]}
                onChange={handleRuleLevelChange}
                 />
              </Form.Item> */}
            </div>
            {<div className="ant-multi-select-selector ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">User</label>
              <Form.Item name="userId" rules={[{ required: true, message: 'Please select a user.' }]}>
                <Select mode="multiple" placeholder="Select user" className="w-full mt-1"
                  options={users}
                // disabled={!isUserFieldEnabled}
                />
              </Form.Item>
            </div>}
            {/* <div className="ant-select-selector-white">
              <label className="text-base text-[#2F2B3DCC] font-medium">Mode</label>
              <Form.Item name="isAuto" rules={[{ required: true, message: 'Please select a mode.' }]}>
                <Select placeholder="Select mode" options={[{ value: true, label: "Auto" }, { value: false, label: "Manual" }]} />
              </Form.Item>
            </div> */}
            <div>
              <label className="text-base text-[#2F2B3DCC] font-medium">Rule Type</label>
              <Form.Item name="isAuto" initialValue={false} defaultValue={false}>
                <Radio.Group>
                  <Radio value={false} className="py-2">Manual</Radio>
                  <Radio value={true} className="py-2">Auto</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="input-white lg:col-span-2">
              <label className="text-base text-[#2F2B3DCC] font-medium">Description</label>
              <Form.Item name="description" rules={[{ required: true, message: 'Please enter rule description.' }]}>
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </div>
          <div className=" text-center mt-5">
            <LoadableButton
              className="bg-primary text-sm text-white font-medium uppercase px-10 py-2 mr-4 rounded-lg themeHover duration-500"
              type="Submit"
              lable='Save'
              isLoading={isRuleFormLoading}
              loadingLable='Adding rule...'
            />
            <button type="button" onClick={() => setIsRuleFormOpen(false)} className="bg-white border border-primary text-sm primary font-medium uppercase px-8 py-2 rounded-lg hover:bg-[#ffffff] duration-500">Cancel</button>
          </div>
        </Form>
      </ModalComponent>

      {/* View kyc rule details modal */}
      <ModalComponent isOpen={isRuleDetailsOpen} setIsOpen={setIsRuleDetailsOpen} title='Rule Information' width={900}>
        <div className='p-5'>
          <div className='grid lg:grid-cols-3 md:grid-cols-2  gap-10 '>
            <div>
              <p className='text-base font-normal '>Rule Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedRule?.ruleName || '--'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Rule Description</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedRule?.description}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Rule Level</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedRule?.ruleLevel || '--'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Assign Name</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedRule?.assignName || '--'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Rule Mode</p>
              <p className='text-xl primary font-semibold  mt-2'>{selectedRule?.isAuto ? 'Auto' : 'Manual'}</p>
            </div>
            <div>
              <p className='text-base font-normal '>Created At</p>
              <p className='text-xl primary font-semibold  mt-2'>{moment(selectedRule?.createdDate).format('DD-MM-YYYY LT') || '--'}</p>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}